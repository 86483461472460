import { useState, useEffect } from 'react';

/* 
 * useFieldsetChangesTracker.tsx
 * This function registers changes for fields inside of fieldsets, and
 * checks if any field has a change. This can be used to prevent close
 * clicks when the fieldset has unsaved changes.
 */

export const useFieldsetChangesTracker = <T extends { id?: number | null; uuid?: string }>(
    initialData: T[],
    viewKey: string,
    setUnsavedChanges: (viewKey: string, hasChanges: boolean) => void
) => {
    const [changesTracker, setChangesTracker] = useState<{ [key: string]: { [field: string]: boolean } }>({});

    // Check if any field of has recorded a change
    useEffect(() => {
        const hasAnyUnsavedChanges = Object.values(changesTracker).some(fields => Object.values(fields).some(value => value));
        setUnsavedChanges(viewKey, hasAnyUnsavedChanges);
    }, [changesTracker, viewKey]);

    // Helper function to check and register the field for unsaved changed
    const checkUnsavedChanges = (field: keyof T, newValue: string | number | boolean | null, identifier: string) => {

        // Check if data[name] exists and is an array, otherwise return: the check is not needed
        if (!Array.isArray(initialData)) {
            return;
        }

        const originalItem = initialData.find(item => item.id?.toString() === identifier || item.uuid === identifier);

        let isChanged = false;
        if (!originalItem) {
            isChanged = true;
        } else {
            const originalValue = originalItem[field];
            // If original value is number and new value is string, convert and compare
            if (typeof originalValue === 'number' && typeof newValue === 'string') {
                isChanged = originalValue !== Number(newValue);
            // If original value is string and new value is number, convert and compare
            } else if (typeof originalValue === 'string' && typeof newValue === 'number') {
                isChanged = Number(originalValue) !== newValue;
            // For all other cases, just compare the values directly
            } else {
                isChanged = originalValue !== newValue;
            }
        }

        // Save changes in the changes tracker for the field
        setChangesTracker(prev => ({
            ...prev,
            [identifier]: {
                ...(prev[identifier] || {}),
                [field]: isChanged
            }
        }));
    }

    return { checkUnsavedChanges };
}