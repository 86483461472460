import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useListClickHandlers } from './functions/useListClickHandlers';
import { ListRowType, ListProps } from 'types/ListTypes';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { handleDragRow } from './functions/handleDragRow';
import { saveBatchData } from 'services/api/saveBatchData';
import ListContext from 'components/lists/ListContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import FilterButton from './FilterButton';
import ListRow from './ListRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/list.scss';
import '../../style/scss/tooltip.scss';

const ListComponent = <T extends ListRowType>({
    query, objectName, filters, showSearch, showDeleteOrDeactivate,
    detailPageUrl, isPaginated, isDraggable = false, onRowClick, detailModal, wizardModal, formModal, modalSize
}: ListProps<T>) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { queryParams, data, listMetaData, ordering, loading, searchTerm, selectedColumns, tableWidthClass, 
        handlePageChange, handleSearchChange, handleColumnOrdering, handleDragOrdering, refetchList } = useContext(ListContext);
    const { handlePrimaryButtonClick } = useListClickHandlers(
        query.object, objectName, detailPageUrl, refetchList, onRowClick, detailModal, wizardModal, formModal, modalSize);

    // Don't show the delete button in lists with in-active or deleted query params, otherwise the item may be double 'deleted'
    const showDeleteButton = () => {
        if (showDeleteOrDeactivate === 'fully_delete') {
            return true;
        } else if (showDeleteOrDeactivate === 'flag_deactivated' || showDeleteOrDeactivate === 'flag_deleted') {

            const isActive = queryParams['is_active'] === 'false' || queryParams['is_active'] === false;
            const isDeleted = queryParams['deleted'] === 'true' || queryParams['deleted'] === true;

            if ((!isActive && !isDeleted) || !filters) {
                return true
            }
        }
        return false;
    };

    // Determine the grid template columns based on the columns.width from the list config
    const getGridTemplateColumns = () => {
        // Start with the given selected columns of this list
        let gridColumns = [...selectedColumns];

        // Add a fixed width column if delete or deactivate button should be shown
        if (showDeleteOrDeactivate) {
            gridColumns.push({ width: '20px', field: 'delete', label: '' });
        }

        // Add a fixed width column at the beginning if the list is draggable
        if (isDraggable) {
            gridColumns.unshift({ width: '22px', field: 'drag', label: '' });
        }

        // Return the grid columns as string
        return gridColumns.map(col => col.width).join(' ');
    };

    return (
        <>
            <div className='container list-button-wrapper'>
                <div className='button-container'>
                    <PrimaryButton 
                        onClick={handlePrimaryButtonClick} 
                        onlyViewRestriction={true}
                        label={t('button.add_object_label', { object_name: t(`${objectName}.singular`) })}
                        size="medium" />
                    {loading === 'show-loader' && <div className="loader"></div>}
                </div>
                <div className='button-container right-buttons'>
                    {showSearch && (
                        <div className='search-container' style={{ marginTop: '2px', marginRight: '1rem' }}>
                            <input
                                type='text'
                                placeholder={t('forms.search_placeholder')}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    )}
                    {filters &&
                        <FilterButton 
                            filters={filters} 
                            defaultParams={query?.defaultParams} />
                    }
                    {/* {showImport || (showExport && hasRightCheck('can_export')) ? (
                        <SecondaryButton
                            onClick={handleSecondaryButtonClick} 
                            label={showImport && (showExport && hasRightCheck('can_export')) ? t('button.import_export_button_label') : showImport ? t('button.import_button_label') : t('button.export_button_label')} />
                    ) : null } */}
                    {/* Show custom column selector if its feature is active */}
                    {/* {showColumnSelector && activeFeature('custom_column_selection') &&
                        <IconButton
                            onClick={() => setIsColumnSelectorOpen(true)} 
                            icon={<FontAwesomeIcon icon={faCog} />}
                            tooltipText={t('button.column_selector_button_label')}/> 
                    } */}
                </div>
            </div>
            <div className={`${tableWidthClass}`}>
                <div className={`${isPaginated ? 'paginated-list' : 'non-paginated-list'}`}>
                    <table className='list'>
                        <thead>
                            <tr style={{ gridTemplateColumns: getGridTemplateColumns() }}>
                                {selectedColumns.map((column) => {
                                    // Render the header columns
                                    const isSorted = ordering && (ordering === column.field || ordering === `-${column.field}`);
                                    const isDescending = ordering && ordering.startsWith(`-${column.field}`);
                                    return (
                                        <th key={column.field}
                                            onClick={() => handleColumnOrdering(column.field)}
                                            className={`${loading !== 'loading' ? 'border-bottom' : ''}`}>
                                            {t(column.label)}
                                            {isSorted && (
                                                <FontAwesomeIcon
                                                    icon={isDescending ? faCaretDown : faCaretUp}
                                                    className={'sorting-icon'} />
                                            )}
                                        </th>
                                    );
                                })}
                                {showDeleteButton() && (
                                    // Add an extra element in case there is a delete button in this list
                                    <th className={`${loading !== 'loading' ? 'border-bottom' : ''}`}></th>
                                )}
                            </tr>
                        </thead>
                        {isPaginated && loading === 'loading' ? (
                            // For paginated lists, if the list is loading, show loading rows
                            <tbody>
                                {Array.from({ length: 50 }, (_, rowIndex) => (
                                    <tr key={rowIndex} style={{ gridTemplateColumns: getGridTemplateColumns() }}>
                                        {selectedColumns.map((column) => (
                                            <td key={column.field}>
                                                <div className="loading-rectangle" />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            isDraggable ? (
                                <DragDropContext onDragEnd={(result) => handleDragRow(result, data, handleDragOrdering, query.object, saveBatchData, setFloatingAlert)}>
                                    <StrictModeDroppable droppableId="list">
                                        {(provided) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {data.map((row, index) => {
                                                    // Map over the data and create a row for each item
                                                    return (
                                                        <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ListRow<T> 
                                                                    row={row}
                                                                    gridClass={getGridTemplateColumns()}
                                                                    query={query}
                                                                    objectName={objectName}
                                                                    columns={selectedColumns}
                                                                    isDraggable={true} 
                                                                    showDeleteOrDeactivate={showDeleteOrDeactivate}
                                                                    showDeleteButton={showDeleteButton()}
                                                                    detailPageUrl={detailPageUrl}
                                                                    onRowClick={onRowClick}
                                                                    detailModal={detailModal}
                                                                    formModal={formModal}
                                                                    wizardModal={wizardModal}
                                                                    modalSize={modalSize}
                                                                    provided={provided} 
                                                                    snapshot={snapshot}
                                                                />
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </tbody>
                                        )}
                                    </StrictModeDroppable>
                                </DragDropContext>
                            ) : (
                                <tbody>
                                    {data.map((row) => (
                                        // Map over the data and create a row for each item
                                        <ListRow<T> 
                                            row={row} 
                                            gridClass={getGridTemplateColumns()}
                                            query={query}
                                            objectName={objectName}
                                            columns={selectedColumns}
                                            showDeleteOrDeactivate={showDeleteOrDeactivate}
                                            showDeleteButton={showDeleteButton()}
                                            detailPageUrl={detailPageUrl}
                                            onRowClick={onRowClick}
                                            detailModal={detailModal}
                                            formModal={formModal}
                                            wizardModal={wizardModal}
                                            modalSize={modalSize} />
                                    ))}
                                </tbody>
                            )
                        )}
                    </table>
                    {loading == 'success' && data.length === 0 && (
                        // Shows an empty list box
                        <div className='empty-list'>
                            <p>
                                {t("general.empty_list_text", { object_name_singular: t(`${objectName}.singular`), object_name_plural: t(`${objectName}.plural`)})}
                            </p>
                        </div>
                    )}
                </div>            
                {isPaginated && listMetaData.totalRows > 50 ? (
                    <div className='pagination-buttons'>
                        <button onClick={() => handlePageChange(listMetaData.currentPage - 1)} 
                                disabled={listMetaData.currentPage === 1}
                                className='button button-small button-secondary'>
                            {t('general.previous')}
                        </button>
                        <span className="pagination-pages">
                            {t('general.page_total_string', { page: listMetaData.currentPage, totalPages: Math.ceil(listMetaData.totalRows / 50).toLocaleString() })}
                        </span>
                        <button onClick={() => handlePageChange(listMetaData.currentPage + 1)} 
                                disabled={listMetaData.currentPage === Math.ceil(listMetaData.totalRows / 50)}
                                className='button button-small button-secondary'>
                            {t('general.next')}
                        </button>
                    </div>
                ) : null}
            </div>
            {/* {isColumnSelectorOpen && (
                <ColumnSelector
                    availableColumns={availableColumns}
                    selectedColumns={selectedColumns}
                    columns={columns}
                    onClose={() => setIsColumnSelectorOpen(false)}
                    onSubmit={setSelectedColumns}
                />
            )} */}
        </>
    );
};

export default ListComponent;