/*
 * amountFormatting.ts
 * Formats amounts based on the user locale, and rounds to maximal 2 decimals if they exist.
 */

export const formatAmount = (amount: number, userLocale: string): string | null => {
    // Return an empty string or null for zero amount
    if (amount === 0) {
        return null;
    }

    // Round up the current amount
    const amountToFormat = Math.round(amount * 100) / 100;

    // Determine if the given amount is an integer
    const isInteger = Number.isInteger(amountToFormat);

    // Format the amount based on the user locale
    return new Intl.NumberFormat(userLocale, {
        // If the amount is an integer, show no decimal numbers, otherwise show minimal 1 decimal
        minimumFractionDigits: isInteger ? 0 : 1,

        // Show maximal 2 decimals if they exist
        maximumFractionDigits: 2
    }).format(amountToFormat);
};

// Formats a price string to the user locale with comma or period and always with 2 decimals
export const formatPriceString = (priceString: string | null, userLocale: string): string => {
    // Return an empty string or null if the price string is empty
    if (!priceString) {
        return '';
    }

    // Format the price
    return new Intl.NumberFormat(userLocale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(Number(priceString));
};