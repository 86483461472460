import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { localeOptions } from 'internationalization/i18n';
import { convertUTCToLocalDateObject, extractDateFromLocalDateTime, formatDisplayDate } from 'internationalization/timezoneConversions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'components/controls/DatePicker';
import '../../../style/scss/datepicker.scss';

interface DateInputProps {
    id?: string;
    name?: string;
    value?: Date | null;
    convertTimezone?: boolean;
    utcDatetime?: string;
    localDatetime?: string;
    weekStartsOnSunday?: boolean;
    placeholder?: string;
    disabled?: boolean;
    onChange: (date: string | null) => void;
}

const DateInput: React.FC<DateInputProps> = ({ 
    id, name, value, convertTimezone = false, utcDatetime, localDatetime, placeholder, 
    onChange, weekStartsOnSunday = false, disabled
}) => {
    const { t } = useTranslation();
    const { errorMessages } = useGlobalContext();
    const { userLocale, userTimezone } = useSettings();
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    // Set the current value if it exists, and set the selected date to highlight it in the date picker
    useEffect(() => {
        // If convert timezone is true, convert the given utc datetime into the local timezone datetime and extract the date from it
        if (convertTimezone && utcDatetime) {
            const localDate = convertUTCToLocalDateObject(utcDatetime, userLocale, userTimezone);
            setSelectedValue(formatDisplayDate(localDate, userLocale));
            setSelectedDate(localDate);

        // If the datetime is already converted into local datetime, only extract the date from it
        } else if (localDatetime) {
            const dateString = extractDateFromLocalDateTime(localDatetime, userLocale);
            setSelectedValue(dateString);
            setSelectedDate(new Date(localDatetime));

        // Otherwise just put in the given value
        } else if (!utcDatetime && !localDatetime && value) {
            setSelectedValue(formatDisplayDate(value, userLocale));
            setSelectedDate(value);
        }
    }, [convertTimezone, utcDatetime, localDatetime, value]);

    // Format the backend date to default Django datefield format
    const formatBackendDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }

    // Handle selection of a date
    const handleInputBlur = () => {
        // If the date is removed, set the date to null
        if (!selectedValue.trim()) {
            onChange(null);
            return;
        }
    
        const delimiterRegex = /[-./]/;
        const parts = selectedValue.split(delimiterRegex);
    
        // Determine the position of the year, month and date based on the date format
        const dateFormat = localeOptions[userLocale]?.dateFormat || 'MM/dd/yyyy';
        const formatParts = dateFormat.split(/[-./]/);
        const yearIndex = formatParts.indexOf('yyyy');
        const monthIndex = formatParts.indexOf('MM');
        const dayIndex = formatParts.indexOf('dd');
    
        if (parts.length === 3 && yearIndex !== -1 && monthIndex !== -1 && dayIndex !== -1) {
            // Set the date to the right ordering
            const year = parts[yearIndex].length === 2 ? '20' + parts[yearIndex] : parts[yearIndex];
            const month = parts[monthIndex];
            const day = parts[dayIndex];
            const dateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    
            try {
                const parsedDate = new Date(dateString);
                if (!isNaN(parsedDate.getTime())) {
                    // Set the shown value with the formatted display value
                    setSelectedValue(formatDisplayDate(parsedDate, userLocale));

                    // Set the backend value formatted as backend date yyyy-mm-dd
                    onChange(formatBackendDate(parsedDate));
                } else {
                    onChange(null);
                }
            } catch (error) {
                console.error("Fout bij het parsen van de datum:", error);
                onChange(null);
            }
        } else {
            onChange(null);
        }
    };

    // Handle selection of a date from the date picker
    const handleDateSelection = (date: Date) => {
        // Set the selected value with the display date
        setSelectedValue(formatDisplayDate(date, userLocale));

        // Set the date to show as selected date in de date picker
        setSelectedDate(date);

        // Call the on change with the backend date
        onChange(formatBackendDate(date));

        // Close the date picker
        setIsOpen(false);
    };

    // Closes the date picker if tab is pressed
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Tab') {
            setIsOpen(false);
        }
    };
    
    return (
        <div className="dateinput">
            <input
                type='text'
                id={id}
                name={name}
                value={selectedValue}
                disabled={disabled}
                onChange={e => setSelectedValue(e.target.value)}
                onBlur={handleInputBlur}
                placeholder={t(placeholder || `date_time.date_placeholder.${userLocale}`)}
                onFocus={() => setIsOpen(!isOpen)}
                onKeyDown={handleKeyDown}
                className={`date-input ${name && errorMessages[name] && 'is-invalid'}`}
            />
            {!disabled && (
                <div className="dateinput-arrow" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
                </div>
            )}
            {isOpen && (
                <DatePicker
                    onDayClick={handleDateSelection}
                    selectedDate={selectedDate}
                    closeDatePicker={() => setIsOpen(false)}
                    weekStartsOnSunday={weekStartsOnSunday} />
            )}
        </div>
    );
};

export default DateInput;