import { fetchData } from 'services/api/fetchData';
import { HeaderButtons } from 'types/ButtonTypes';
import { DetailCardType } from 'types/DetailPageTypes';
import { ApiRequestType } from 'types/FieldTypes';
import { QueryType } from 'types/ListTypes';

/*
 * fetchFieldData.tsx
 * General utility function that fetches the data of fields and
 * fetches dropdown data. These function can be imported in detailPages
 * etc.
 */

// Fetches the data of the item id of the detail page to show in the fields
export const fetchFieldData = async (apiObject: string, itemId: string) => {
    return fetchData({ apiObject, itemId });
};

// Loops over the detailcards to get the dropdown fields to request. Then loops over the dropdown fields 
// to get the apiObject per field and get the dropdown options for that field. This creates one array with 
// all the dropdown items for all dropdown fields
export const fetchDropdownData = async (sections: DetailCardType[], dropdownData: any, headerButtons?: HeaderButtons) => {
    const newDropdownData = { ...dropdownData };

    // Helper function to fetch the dropdown data based on a query prop
    const fetchDataOfQuery = async (query: QueryType) => {
        const data = await fetchData({ apiUrl: query.endpoint, params: query.defaultParams });
        newDropdownData[query.object] = data.results;   
    }

    // Helper function to fetch the dropdown data based on apiRequest props
    const fetchDataForApiRequest = async (apiRequest: ApiRequestType[] ) => {
        if (apiRequest && Array.isArray(apiRequest)) {
            for (const request of apiRequest) {
                const data = await fetchData({ apiUrl: request.endpoint, params: request.params });
                newDropdownData[request.object] = data;
            }
        }
    };

    // Helper function to fetch the dropdown data based on apiObject props
    const fetchDataForApiObject = async (apiObject: string ) => {
        if (apiObject) {
            const data = await fetchData({ apiObject });
            newDropdownData[apiObject] = data;
        }
    };

    // Helper function to Loop through the fields to find the apiObject or apiRequest props and call the fetch data functions
    const processFields = async (fields: any[]) => {
        for (const field of fields ?? []) {
            if (field.apiRequest) {
                // First handle the apiRequest props. Fields with this prop can also have a apiObject prop
                await fetchDataForApiRequest(field.apiRequest);
            } else if (field.apiObject) {
                await fetchDataForApiObject(field.apiObject);
            }

            // Loop through field in field columns, call the function recursively
            if (field.fieldColumns) {
                for (const column of field.fieldColumns) {
                    await processFields(column);
                }
            }
        }
    };

    // Loop through the items of the given section. A section may contain detailCards with fields inside them, or may contain the fields directly
    for (const item of sections) {
        // If the item in the section contains a collection of fields (for example sections or detailCards)
        if (item.fields) {
            await processFields(item.fields);
        // If the item in the configSet contains fieldColumns with a collection of fields itself
        } else if (item.fieldColumns) {
            for (const column of item.fieldColumns) {
                await processFields(column);
            }
        // If the section itself is a list of fields
        } else {
            await processFields(sections);
        }
    }

    // If header buttons are given, loop through the header button config to find query props
    if (headerButtons) {
        for (const button of headerButtons) {
            if ('query' in button && button.query) {

                // Fetch the data for the query prop
                await fetchDataOfQuery(button.query)
            }
        }
    }

    return newDropdownData;
};

// Loops over the detailcards to find a field of the multiselect type which has the group by field prop set.
// This means the options in the multiselect dropdown are grouped by a field. This function fetches the data
// of these groups so that we can show a name or a label instead of just a group id.
export const fetchGroupOptions = async (detailCards: any, groupOptions: any) => {
    const newGroupOptions = { ...groupOptions };
    for (const card of detailCards) {
        for (const field of card.fields ?? []) {
            // Fetch the multiselect group data
            if (field.type === 'multiselect' && field.groupByField) {
                const results = await fetchData({ apiObject: field.groupByField?.apiObject, params: { is_active: 'true' } });
                const fieldName = field.groupByField.apiField;
                
                // Only extract the id and the given field name from the data
                const extractedFields = results.results.map((item: any) => ({
                    id: item.id,
                    [fieldName]: item[fieldName],
                }));
                newGroupOptions[field.groupByField?.apiObject] = extractedFields
            }
        }
    }
    return newGroupOptions;
};

// Fetch the field data and the dropdown options for all fields in all detailcards of the page in once. This
// is done to update the loadingstate once.
export const fetchDataAndOptionsOfDetailCards = async (
    apiObject: string, 
    itemId: string, 
    dropdownData: any, 
    groupOptions: any,
    headerButtons: HeaderButtons | undefined, 
    sections: any, 
    setData: any, 
    setDropdownData: any, 
    setGroupOptions: any, 
    setLoadStatus: any,
) => {
    try {
        const [fetchedFieldData, fetchedDropdownData, fetchedGroupOptions] = await Promise.all([
            fetchFieldData(apiObject, itemId),
            fetchDropdownData(sections, dropdownData, headerButtons),
            fetchGroupOptions(sections, groupOptions)
        ]);
        setData(fetchedFieldData);
        setDropdownData(fetchedDropdownData);
        setGroupOptions(fetchedGroupOptions);
        setLoadStatus("loaded");
        return [fetchedFieldData, fetchedDropdownData, fetchedGroupOptions];
    } catch (error) {
        console.error(error);
    }
};

// Fetch the dropdown data only of the given fields
export const fetchDropdownDataFromFields = async (apiObject: string, params: any = {}) => {
    try {
        const data = await fetchData({ apiObject, params });
        return data;
    } catch (error) {
        console.log(`Failed to fetch data for ${apiObject}`, error);
    }
};