import React from 'react';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtons } from 'types/ButtonTypes';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (client: any) => {
    if (client && client['entity_type'] && client['id']) {
        const baseUrl = client['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${client['id']}`;
    }
    return null;
}

// Rename the name of the function:
const JobModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {

    // Specify the parameters of this detail modal:
    const apiObject = 'job';
    const object = 'job';
    const showStatusBar = true;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtons = [
        {   
            type: 'secondary',
            label: 'button.download_pdf_button_label',
            action: 'download_pdf',
            allowedRight: ['can_download'],
            query: {
                endpoint: 'get_pdf_layout_list/job',
                object: 'pdf_layout',
                defaultParams: { 'deleted': false },
            }
        },
        {   
            type: 'dropdown',
            label: 'button.more_button_label',
            buttons: [
                {   // For projects, invoices can always be made
                    label: 'button.create_invoice_button_label',
                    action: 'create_invoice',
                    restrictedRight: ['only_view'],
                    allowedFeature: ['invoices'],
                    condition: [{ field: 'is_project', values: [true] }]
                },
                {
                    label: 'button.duplicate_project_button_label',
                    action: 'duplicate',
                    restrictedRight: ['only_view'],
                    condition: [{ field: 'is_project', values: [true] }]
                },
                {
                    label: 'button.delete_project_button_label',
                    action: 'delete',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                    condition: [{ field: 'is_project', values: [true] }]
                },
                // {
                //     label: 'button.convert_to_project_button_label',
                //     action: 'convert_project',
                //     restrictedRight: ['only_view'],
                //     condition: [{ field: 'is_project', values: [false] }],
                // },
                // {
                //     label: 'button.convert_to_proposal_button_label',
                //     action: 'convert_proposal',
                //     restrictedRight: ['only_view'],
                //     allowedFeature: ['proposals'],
                //     condition: [{ field: 'is_project', values: [false] }],
                // },
                {
                    label: 'button.reopen_verified_job_button_label',
                    action: 'reopen',
                    restrictedRight: ['only_view'],
                    condition: [
                        { field: 'is_project', values: [false] },
                        { field: 'status_condition', values: ['finished'] }
                    ]
                },
                // {
                //     label: 'button.duplicate_job_button_label',
                //     action: 'duplicate',
                //     restrictedRight: ['only_view'],
                //     condition: [{ field: 'is_project', values: [false] }]
                // },
                {
                    label: 'button.delete_job_button_label',
                    action: 'delete',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                    condition: [{ field: 'is_project', values: [false] }]
                }
            ]
        },
        {   
            type: 'primary',
            label: 'button.add_job_button_label',
            action: 'add_job',
            restrictedRight: ['only_view'],
            condition: [
                { field: 'is_project', values: [true] },
                { field: 'status_condition', notValues: ['finished', 'invoiced'] },
            ]
        },
        {   
            type: 'primary',
            label: 'button.schedule_button_label',
            action: 'schedule',
            restrictedRight: ['only_view'],
            allowedFeature: ['scheduling'],
            customClass: 'scheduled_appointment',
            condition: [
                { field: 'is_project', values: [false] },
                { field: 'status_condition', values: ['job_created', 'all_purchases_delivered'] },
            ]
        },
        {   
            type: 'primary',
            label: 'button.reschedule_button_label',
            action: 'schedule',
            restrictedRight: ['only_view'],
            allowedFeature: ['scheduling'],
            customClass: 'scheduled_appointment',
            condition: [
                { field: 'is_project', values: [false] },
                { field: 'status_condition', values: ['incomplete'] },
            ]
        },
        {   
            type: 'primary',
            label: 'button.finish_project_button_label',
            action: 'verify',
            restrictedRight: ['only_view'],
            customClass: 'finished',
            condition: [
                { field: 'is_project', values: [true] },
                { field: 'status_condition', values: ['completed'] }
            ]
        },
        {   // Every job or subjob must be verified on completion
            type: 'primary',
            label: 'button.verify_job_button_label',
            action: 'verify',
            restrictedRight: ['only_view'],
            customClass: 'finished',
            condition: [
                { field: 'is_project', values: [false] },
                { field: 'status_condition', values: ['completed'] }
            ]
        },
        {   // Only single jobs may be invoiced when verified
            type: 'primary',
            label: 'button.create_invoice_button_label',
            action: 'create_invoice',
            restrictedRight: ['only_view'],
            allowedFeature: ['invoices'],
            condition: [
                { field: 'is_project', values: [false] },
                { field: 'is_subjob', values: [false] },
                { field: 'status_condition', values: ['finished'] },
            ]
        }
    ];

    // Define the summary of the modal
    const summary = false;

    // Define the sections of the modal
    const sections: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'job.general.detail_card_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'searchselect', 
                        name: 'client',
                        label: 'job.general.client_label',
                        placeholder: 'job.general.client_placeholder',
                        objectName: 'client.general.object_name.singular',
                        query: {
                            // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                            endpoint: 'get_contact_search',
                            params: {
                                contact_type: ['contact', 'prospect', 'client'] 
                            }
                        },
                        postEndpoint: 'post_contact',
                        viewModeLinkUrl: { function: determineLinkUrl },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                            subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
                        },
                    },
                    { 
                        type: 'searchselect', 
                        name: 'reporting_person',
                        label: 'job.general.reporting_person_label',
                        placeholder: 'job.general.reporting_person_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Only get persons and when a client is selected, get the persons connected to that contact
                            endpoint: 'get_person_search',
                            params: {
                                
                            }
                        },
                        postEndpoint: 'post_contact',
                        dataCondition: [{'client.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        tooltipText: 'De contactpersoon van de opdrachtgever die de opdracht heeft aangevraagd'
                    },
                    { 
                        type: 'searchselect', 
                        name: 'invoice_address',
                        label: 'job.general.invoice_address_label',
                        placeholder: 'job.general.invoice_address_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all locations and when a client is selected, get the primary locations connected to that contact
                            endpoint: 'get_location_search',
                            params: {

                            }
                        },
                        postEndpoint: 'post_location',
                        dataCondition: [{'client.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                    },
                    { 
                        type: 'searchselect', 
                        name: 'work_location',
                        label: 'job.general.work_location_label',
                        placeholder: 'job.general.work_location_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all locations
                            endpoint: 'get_location_search',
                            params: {
                            }
                        },
                        postEndpoint: 'post_location',
                        viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                    },
                    { 
                        type: 'searchselect', 
                        name: 'location_person',
                        label: 'job.general.contact_at_location_label',
                        placeholder: 'job.general.contact_at_location_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Get all persons
                            endpoint: 'get_person_search',
                        },
                        postEndpoint: 'post_contact',
                        dataCondition: [
                            {'client.entity_type': 'organization'}
                        ],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        tooltipText: 'De contactpersoon op locatie waarmee de buitendienst medewerker contact heeft'
                    },
                ],
                [
                    { 
                        type: 'dropdown', 
                        name: '', 
                        label: 'job.general.workflow_label', 
                        activeFeature: 'workflows',
                        disabled_selected: 'job.general.workflow_disabled_selected',
                        dataCondition: [{'is_subjob': false}],
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'dropdown', 
                        name: 'division', 
                        label: 'job.general.division_label', 
                        dataCondition: [{'is_subjob': false}],
                        activeFeature: 'divisions',
                        disabled_selected: 'job.general.division_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'text', 
                        name: 'internal_reference',
                        label: 'job.general.internal_reference_label',
                        placeholder: 'job.general.internal_reference_placeholder', 
                    },
                    { 
                        type: 'multiselect', 
                        name: 'labels', 
                        dataCondition: [{'is_subjob': false}],
                        label: 'job.general.labels_label', 
                        apiObject: 'label',
                        apiRequest: [{
                            endpoint: 'get_label_list',
                            object: 'label',
                            params: { is_active: true, connected_feature: 'jobs' }
                        }],
                        allowNoneOption: false,
                        showSearch: false,
                        disabled_selected: 'job.general.labels_disabled_selected',
                    },
                    {
                        type: 'text', 
                        name: 'budget', 
                        label: 'job.general.budget_label', 
                        placeholder: 'job.general.budget_placeholder', 
                    },
                    // { 
                    //     type: 'budget-widget', 
                    //     name: 'budget', 
                    //     label: 'job.general.budget_label',
                    // },
                    {
                        type: 'date', 
                        name: 'target_date', 
                        label: 'job.general.target_date_label', 
                        placeholder: 'job.general.target_date_placeholder', 
                    },
                    // { 
                    //     type: 'target-widget', 
                    //     name: 'target_date', 
                    //     label: 'job.general.target_date_label', 
                    //     placeholder: '€ 0,00', 
                    // },
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.activities_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'main_description', 
                    emptyLabel: 'job.general.main_description_emptyLabel',
                    placeholder: 'job.general.main_description_placeholder', 
                    enableStyling: 'extended',
                    showToolbarInitially: true,
                    rows: 2,
                },
                // { 
                //     type: 'textarea', 
                //     name: 'internal_description', 
                //     emptyLabel: 'job.general.internal_description_emptyLabe',
                //     placeholder: 'job.general.internal_description_placeholder', 
                //     rows: 1,
                //     isNote: true,
                //     enableStyling: 'extended',
                //     showToolbarInitially: true,
                // },
                {
                    type: 'attachments',
                    name: 'attachments',
                    dropzoneText: 'job.general.attachments_placeholder',
                    apiRequest: [{ 
                        endpoint: 'get_attachmenttype_list', 
                        object: 'attachmenttype', 
                        params: { 'deleted': false }
                    }]
                }
            ]
        },
        {
            component: 'TabsCard',
            dataCondition: [{'is_project': true}],
            // tabs: [
            //     {
            //         label: 'job.general.jobs_label',
            //         type: 'jobs'
            //     },
            //     {
            //         label: 'Communication',
            //         type: 'communication'
            //     },
            //     {
            //         label: 'appointment.general.appointments_label',
            //         type: 'appointments'
            //     },
            //     {
            //         label: 'document.general.documents_label',
            //         type: 'documents'
            //     },
            //     {
            //         label: 'invoice.general.invoices_label',
            //         type: 'invoices'
            //     },
            // ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.subjobs_label',
            dataCondition: [{'is_project': true}],
            fields: [
                { 
                    name: 'subjobs',
                    type: 'subjobs',
                    jobId: itemId
                }
            ]
        },
        {
            component: 'AppointmentsCard',
            dataCondition: [{'is_project': false}]
        },
        {
            component: 'FieldsCard',
            title: 'job.items.items_label',
            dataCondition: [{'is_project': false}],
            fields: [
                { 
                    name: 'items',
                    type: 'job-items',
                    jobId: itemId,
                    showPriceFields: true,
                    apiRequest: [{
                        endpoint: 'get_vatrate_list',
                        object: 'vatrate',
                        params: { is_active: true }
                    }]
                }
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.timerows.timerows_label',
            dataCondition: [{'is_project': false}],
            fields: [
                { 
                    name: 'timerows',
                    type: 'timerows',
                    jobId: itemId,
                    apiRequest: [
                        {
                            endpoint: 'get_timetype_list',
                            object: 'timetype',
                            params: { is_active: true, type: 'productive' }
                        },
                        {
                            endpoint: 'get_hourlyrate_list',
                            object: 'hourlyrate',
                            params: { is_active: true }
                        },
                        {
                            endpoint: 'get_user_list',
                            object: 'user',
                            params: { is_active: true, field_access: true }
                        }   
                    ]                 
                }
            ]
        },
        {
            component: 'PhotosCard',
            dataCondition: [{'is_project': false}],
        },
        // {
        //     component: 'MessagesCard',
        //     dataCondition: [{'is_project': false}],
        // },
    ]
    
    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            sections={sections}
            summary={summary}
        />
    );
};

export default JobModal;