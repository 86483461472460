import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { DeletionType, getDeletionLabel } from 'services/utils/deletionUtils';
import { getCsrfToken } from 'services/authentication/csrfFunctions';
import axios, { AxiosError } from 'axios';
import { apiBaseUrl } from 'App';
import { useGlobalContext } from 'GlobalContext';
import { DetailPageUrlType, ListRowType } from 'types/ListTypes';

/*
 * useListClickHandlers.ts
 * Custom hook to handle the click handlers for a list. For example to open modals or detailpages when 
 * clicking on a row in the list or when clicking on a button to add or delete an item.
 */

export const useListClickHandlers = (
    apiObject: string,
    objectName: string,
    detailPageUrl: DetailPageUrlType | undefined, 
    refetchList: () => void,
    onRowClick?: 'editModal' | 'detailModal' | 'wizardModal' | 'detailPage',
    detailModal?: React.ReactElement,
    wizardModal?: React.ReactElement,
    formModal?: React.ReactElement,
    modalSize?: 'small' | 'medium' | 'large' | 'xtra-large',
) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { setFloatingAlert } = useGlobalContext();
    const history = useHistory();

    // Handle row click to open a detail modal, an edit modal or a detail page
    const handleRowClick = (itemId: number, rowData: ListRowType) => {
        switch (onRowClick) {
            case 'detailModal':
                if (detailModal) {
                    initializeModal(
                        React.cloneElement(detailModal, { itemId }), { 
                            // Set the item id of the item to view
                            itemId, 

                            // Set the modalSize to large for detail modals
                            modalSize: 'large' 
                        }
                    );
                }
                break;
            case 'editModal':
                if (formModal) {
                    initializeModal(
                        React.cloneElement(formModal, { itemId }), { 
                            // Set the title of the modal of the object. For example: 'Edit job'
                            title: t('button.edit_object_label', { object_name: t(`${objectName}.singular`) }), 

                            // Set the item id of the item to edit
                            itemId,

                            // Set the modalSize if given in the props, otherwise use default modal size
                            ...(modalSize ? { modalSize: modalSize } : {})
                        }
                    );
                }
                break;
            case 'wizardModal':
                if (wizardModal) {
                    initializeModal(
                        React.cloneElement(wizardModal, { itemId }), { 
                            // Set the item id of the item to view
                            itemId, 

                            // Set the modalSize to large for wizard modals
                            modalSize: 'medium-large' 
                        }
                    );
                }
            case 'detailPage':
                // Initialize the url variable
                let url: string | undefined;

                // If the detail page url is a fixed string, set the url to this string
                if (typeof detailPageUrl === 'string') {
                    url = detailPageUrl;
                }

                // If it's not a string but an object with a custom url, construct that url
                else if (detailPageUrl) {
                    url = detailPageUrl.baseUrl;

                    if (detailPageUrl.urlSuffixApiField) {
                        // Set the suffix to the value from the given api field, or to the fallback if that value doesn't exists
                        const suffix = rowData[detailPageUrl.urlSuffixApiField] ?? detailPageUrl.urlSuffixFallback;

                        // Construct the custom url
                        url = `${url}/${suffix}`;
                    }
                }

                if (itemId && url) {
                    // Open the detail page for the directory and item
                    history.push(`/${url}/${itemId}`);
                }
                break;
            default:
                console.warn('onRowClick action not defined or recognized');
        }
    };

    // Handle primary button click to open a form modal
    const handlePrimaryButtonClick = () => {
        if (formModal) {
            initializeModal(
                React.cloneElement(formModal, {
                    
                }), { 
                    // Set the title of the modal of the object. For example: 'Add job'
                    title: t('button.add_object_label', { object_name: t(`${objectName}.singular`) }),

                    // Set the modalSize if given in the props, otherwise use default modal size
                    ...(modalSize ? { modalSize: modalSize } : {})
                }
            );
        }
    };

    // Handle secondary button click to open a form modal, such as import/export or choose columns
    const handleSecondaryButtonClick = () => {
        // To be implemented
        // initializeModal(
        //     React.cloneElement(formModal, {}), { 
        //         // Set the title of the modal of the object. For example: 'Add job'
        //         title: t('button.add_object_label', { object_name: t(`${objectName}.singular`) }),

        //         // Set the modalSize if given in the props, otherwise use default modal size
        //         ...(modalSize ? { modalSize: modalSize } : {})
        //     }
        // );
    };

    // Handle delete to remove an existing item
    const handleDelete = async (id: number, deletionType: DeletionType) => {
        if (deletionType !== undefined) {
            const deletionLabel = getDeletionLabel(deletionType);
            const csrfToken = getCsrfToken();
            const confirmMessage = t('general.delete_confirm_message', { deletionLabel: t(deletionLabel).toLowerCase() });
            const confirmAction = window.confirm(confirmMessage);
            if (confirmAction) {
                try {
                    if (deletionType === 'fully_delete') {
                        // Send a DELETE request to delete the item
                        await axios.delete(`${apiBaseUrl}/delete_${apiObject}/${id}/`, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    } else if (deletionType === 'flag_deleted') {
                        // Send a PATCH request to flag the item as deleted
                        await axios.patch(`${apiBaseUrl}/patch_${apiObject}/${id}/`, {
                            deleted: true,
                        }, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    } else if (deletionType === 'flag_deactivated') {
                        // Send a PATCH request to deactivate the item
                        await axios.patch(`${apiBaseUrl}/patch_${apiObject}/${id}/`, {
                            is_active: false,
                        }, {
                            withCredentials: true,
                            headers: {
                                'X-CSRFToken': csrfToken,
                            },
                        });
                    }
                    refetchList();
                } catch (error) {
                    // Show a floating alert with an error
                    const axiosError = error as AxiosError;
                    const message = (axiosError.response?.data as any)?.general || (axiosError.response?.data as any)?.non_field_errors || 'alert.general_error_message';
                    setFloatingAlert({ 'type': 'danger', 'message': message });
                }
                
            }
        }
    };

    return { handleRowClick, handlePrimaryButtonClick, handleSecondaryButtonClick, handleDelete };
};