import React, { ReactElement } from 'react';
import DropdownButton from 'components/buttons/DropdownButton';
import IconButton from 'components/buttons/IconButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { roleSpecificFeatures } from 'services/permissions/roleSpecificFeatures';
import { HeaderButtonAction, DropdownButtonItem, GroupedHeaderButtonItem, HeaderButtons, SingleHeaderButton } from 'types/ButtonTypes';
import ScheduleJobModal from '../../../views/jobs/ScheduleJobModal';
import { ModalProps } from '../ModalContext';
import { handleUpdateValue } from './handleUpdateValue';
import { handleDownloadPdf } from './handleDownloadPdf';
import { FieldOptionFetchResponse } from 'types/FieldTypes';

/*
 * generateHeaderButtons.tsx
 * Generate the header buttons for detail modals based on the modal
 * configuration. It maps the given button types to its button components
 * and maps their action strings to the onclick actions. It checks if the
 * button should be shown for the user based on if the feature is active
 * and allowed in its role and if the user has the proper rights. If a 
 * condition is given with the button, it only renders the button if the
 * data corresponds to the specified values in the condition. In this
 * way, different statuses or properties can contain different buttons.
 */

const generateHeaderButtons = (
    headerButtons: HeaderButtons,
    apiObject: string, 
    data: any,
    dropdownData: Record<string, FieldOptionFetchResponse> | undefined,
    itemId: number,
    initializeModal: (content: ReactElement<any>, props?: ModalProps) => void,
    buttonLoader: Record<HeaderButtonAction, boolean>,
    // setButtonLoader: (updateFn: (prevState: Record<HeaderButtonAction, boolean>) => Record<HeaderButtonAction, boolean>) => void,
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
    setFloatingAlert: Function,
    { activeFeatures, allowedFeatures, allowedRights }: 
    { activeFeatures: string[], allowedFeatures: string[], allowedRights: string[] }
): React.ReactNode => {
    // Map button types to the button components
    const buttonTypeToComponent = {
        primary: PrimaryButton,
        secondary: SecondaryButton,
        icon: IconButton
    };

    // Map button actions to onClick functions
    const buttonActionToOnClick = (action: HeaderButtonAction, button: SingleHeaderButton | GroupedHeaderButtonItem, layoutId?: number): (() => void) => {
        const actionMap: Record<HeaderButtonAction, () => void> = {
            editform: () => {
                if (button.actionComponent && React.isValidElement(button.actionComponent)) {
                    initializeModal(React.cloneElement(button.actionComponent, { itemId } as any), { itemId });
                }
            },
            download_pdf: () => handleDownloadPdf({ 
                itemId, buttonAction: action, setButtonLoader, layoutId
            }),
            duplicate: () => console.log(`${action} clicked`),
            convert_proposal: () => console.log(`${action} clicked`),
            convert_project: () => console.log(`${action} clicked`),
            delete: () => console.log(`${action} clicked`),
            deactivate: () => console.log(`${action} clicked`),
            merge: () => console.log(`${action} clicked`),
            add_job: () => console.log(`${action} clicked`),
            create_invoice: () => console.log(`${action} clicked`),
            send_email_sms: () => console.log(`${action} clicked`),
            schedule: () => initializeModal(<ScheduleJobModal jobId={itemId} setLoading={(isLoading) => setButtonLoader(prev => ({ ...prev, [action]: isLoading }))} />, { modalSize: 'medium' }),
            reopen: () => handleUpdateValue({
                apiObject, itemId, apiField: 'resolution', updateValue: 'completed', buttonAction: action, setButtonLoader, setFloatingAlert
            }),
            verify: () => handleUpdateValue({
                apiObject, itemId, apiField: 'resolution', updateValue: 'finished', buttonAction: action, setButtonLoader, setFloatingAlert
            }),
        };

        return actionMap[action];
    }

    // Check if the given feature of the button is active and allowed for the user
    const isFeatureAllowed = (allowedFeature?: string[]): boolean => {

        // When no allowed feature is given with the button, show it always
        if (!allowedFeature || allowedFeature.length === 0) return true;

        // Check the given allowed features of the button to see if they are allowed
        for (const feature of allowedFeature) {

            // Check if the feature is active in the environment of the user and may belong to a role
            const isActive = activeFeatures.includes(feature);
            const isRoleSpecific = roleSpecificFeatures.includes(feature);

            // If the feature may belong to a role, it must be active and belong to the role of the user
            if (isRoleSpecific && (!isActive || !allowedFeatures.includes(feature))) {
                return false;

            // If the feature may not belong to a role, it must be active
            } else if (!isRoleSpecific && !isActive) {
                return false
            }
        }

        // If every allowed feature given with the button is active, return true
        return true;
    }

    // Check if the user is allowed to see the button based on its rights
    const isRightAllowed = (allowedRight?: string[]): boolean => {

        // If no allowed right is given with the button, show it always
        if (!allowedRight || allowedRight.length === 0) return true;

        // Check if the user has all given allowed rights, otherwise return false
        for (const right of allowedRight) {
            if (!allowedRights.includes(right)) {
                return false;
            }
        }

        // If every right is allowed for the user, the button must be active
        return true;
    }

    // Check if the user is restricted to see the button based on its rights
    const isRightRestricted = (restrictedRight?: string[]): boolean => {

        // If no restricted rights are given with the button, the button is not restricted
        if (!restrictedRight || restrictedRight.length === 0) return false;

        // Check if the user has one of the restricted rights, then the button is restricted
        for (const right of restrictedRight) {
            if (allowedRights.includes(right)) {
                return true;
            }
        }

        // If no restricted rights are found in the user, the button is not restricted
        return false;
    }

    // Check if the button should show
    const shouldShowButton = (button: SingleHeaderButton | GroupedHeaderButtonItem, data: any): boolean => {

        // Check if the conditions of the button are met
        if (button.condition) {
            for (const cond of button.condition) {
                const fieldValue = data[cond.field];

                if (cond.values && !cond.values.includes(fieldValue)) {
                    return false;
                }

                if (cond.notValues && cond.notValues.includes(fieldValue)) {
                    return false;
                }
            }
        }

        // Check feature and right restrictions
        if (!isFeatureAllowed(button.allowedFeature) || isRightRestricted(button.restrictedRight) || !isRightAllowed(button.allowedRight)) {
            return false;
        }

        // Show button if all conditions are met
        return true;
    }

    // Render buttons based on their conditions
    const renderButtons = (buttons: HeaderButtons, data: any): React.ReactNode[] => {
        return buttons.flatMap((button, index): React.ReactNode => {

            // Render download pdf buttons
            if ('action' in button && button.action === 'download_pdf' && dropdownData && button.query?.object && dropdownData[button.query.object]) {

                // Get the fetched pdf layouts from the dropdown data
                const fetchedPdfLayouts = dropdownData[button.query.object];

                // Convert the fetched pdf layouts into an array
                const pdfLayouts = Array.isArray(fetchedPdfLayouts) ? fetchedPdfLayouts : [];

                // Sort the pdf layouts by name in alphabetical order
                pdfLayouts.sort((a, b) => a.name.localeCompare(b.name));

                if (pdfLayouts.length === 1) {
                    const ButtonComponent = buttonTypeToComponent[button.type];
                    return (
                        <ButtonComponent 
                            key={index} 
                            size={button.size || 'small'}
                            label={button.label}
                            onClick={buttonActionToOnClick(button.action, button, pdfLayouts[0].id)}
                            loading={buttonLoader[button.action]}
                            customClass={button.customClass}
                        />
                    );
                } else if (pdfLayouts.length > 1) {
                    const dropdownItems = pdfLayouts.map((layout) => ({
                        label: `Download ${layout.name.toLowerCase()}`,
                        onClick: buttonActionToOnClick(button.action, button, layout.id),
                    }));

                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size || 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }

            // Render dropdown buttons
            } else if (button.type === 'dropdown') {
                const dropdownItems = button.buttons.flatMap((subButton): DropdownButtonItem[] => {
                    // Determine for every grouped button if it should be shown
                    if (shouldShowButton(subButton, data)) {
                        return [{
                            label: subButton.label,
                            onClick: buttonActionToOnClick(subButton.action, subButton)
                        }]
                    }
                    return [];
                }).filter(Boolean);

                // Render the dropdown button
                if (dropdownItems.length > 0) {
                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size ? button.size : 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }
                
            // Render other buttons
            } else {
                // Skip button if it should not be shown
                if (!shouldShowButton(button, data)) return null;

                // Determine the right button component
                const ButtonComponent = buttonTypeToComponent[button.type];

                // Determine the right onclick handler
                const onClickHandler = buttonActionToOnClick(button.action, button);

                // Render the button component
                return (
                    <ButtonComponent 
                        key={index} 
                        size={button.size ? button.size : 'small'} 
                        tooltipText={button.tooltipText}
                        label={button.label} 
                        icon={button.icon}
                        onClick={onClickHandler} 
                        loading={buttonLoader[button.action]}
                        customClass={button.customClass} 
                    />
                );
            }
        }).filter(Boolean)
    }

    // Call the render buttons function
    const generatedButtons = renderButtons(headerButtons, data);

    // Return the generated buttons or null
    return generatedButtons.length > 0 ? generatedButtons : null;
};

export default generateHeaderButtons;