import React, { useState, useContext, useEffect } from 'react';
import { FieldData, FieldOption, JobItemsFieldsetType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { JobItemType, createNewJobItem } from '../../../views/jobs/JobTypes';
import SearchSelect from '../basefields/SearchSelect';
import Dropdown from '../basefields/Dropdown';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { parseQuantityAndUnit } from 'services/utils/parseQuantityAndUnit';
import { v4 as uuidv4 } from 'uuid';
import { VatRateType } from '../../../views/settings/SettingsTypes';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const JobItemsFieldset: React.FC<JobItemsFieldsetType & { data: FieldData, viewKey: string }> = ({
    viewKey, name, jobId, data, dropdownData, helperText, showPriceFields = true, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const { userLocale } = useSettings();
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<JobItemType[]>([]);
    const [vatRates, setVatRates] = useState<VatRateType[]>([]);
    const [changesTracker, setChangesTracker] = useState<{ [key: string]: { [field: string]: boolean } }>({});
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const jobCurrency = 'EUR';

    // If there are items included in the fetched data, show them. Otherwise show an empty row
    useEffect(() => {
        if (data && data[name] && data[name].length > 0) {
            const sortedRows = (data[name] || []).sort((a: any, b: any) => a.position - b.position).map((row: any) => ({
                ...row,
                sale_price: row.sale_price !== null ? new Intl.NumberFormat(userLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.sale_price.toString()) : null,
            }));
            setRows(sortedRows)
        } else {
            const firstPosition = 1
            const newRow = createNewJobItem(jobId!, uuidv4(), firstPosition);
            setRows([newRow]);
        }
    }, [data, jobId]);

    // Set the vat rates from the dropdown data
    useEffect(() => {
        setVatRates(dropdownData?.vatrate?.results || []);
    }, [dropdownData])

    // Check if any field of has recorded a change
    useEffect(() => {
        const hasAnyUnsavedChanges = Object.values(changesTracker).some(fields => Object.values(fields).some(value => value));
        setUnsavedChanges(viewKey, hasAnyUnsavedChanges);
    }, [changesTracker, viewKey]);

    // Map job item name to field option
    const jobItemNameToFieldOption = (row: JobItemType): FieldOption | null => {
        if (row.product !== null) {
            return {
                id: row.product,
                value: row.name,
                name: row.name
            };
        } else if (row.name) {
            return {
                value: row.name,
                name: row.name,
            };
        }
        return null;
    }

    // Determine if the job item name is a textual input
    const isTextualInput = (row: JobItemType): boolean => {
        // Check if the job item has a product id
        if (row.product === null && row.name) {
            return true;
        }
        return false
    }

    // Helper function to check for unsaved changes
    const checkUnsavedChanges = (field: string, newValue: string | number | boolean | null, identifier: string) => {
        // Check if data[name] exists and is an array, otherwise return: the check is not needed
        if (!Array.isArray(data[name])) {
            return;
        }

        const originalItem = data[name].find((item: JobItemType) => item.id?.toString() === identifier || item.uuid === identifier);

        let isChanged = false;
        if (!originalItem) {
            isChanged = true;
        } else {
            const originalValue = originalItem[field];
            // If original value is number and new value is string, convert and compare
            if (typeof originalValue === 'number' && typeof newValue === 'string') {
                isChanged = originalValue !== Number(newValue);
            // If original value is string and new value is number, convert and compare
            } else if (typeof originalValue === 'string' && typeof newValue === 'number') {
                isChanged = Number(originalValue) !== newValue;
            // For all other cases, just compare the values directly
            } else {
                isChanged = originalValue !== newValue;
            }
        }

        // Save changes in the changes tracker for the field
        setChangesTracker(prev => ({
            ...prev,
            [identifier]: {
                ...(prev[identifier] || {}),
                [field]: isChanged
            }
        }));
    }

    // Generic function to update the rows with a changed value
    const updateRows = (identifier: string, updateFunction: (item: JobItemType, ...args: any[]) => JobItemType, ...args: any[]) => {
        // Copy the current rows and find its index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);
      
        if (rowIndex !== -1) {
            // Set the new value in the specific row
            updatedRows[rowIndex] = updateFunction(updatedRows[rowIndex], ...args);
      
            // Update the rows and updated data with sale price as float
            setRows(updatedRows);
            updateUpdatedDataWithSalePriceAsFloat(updatedRows);
        }
    };

    // Helper function to update the updated data with the sale price converted to float
    function updateUpdatedDataWithSalePriceAsFloat(updatedJobItems: JobItemType[]) {
        const updatedJobItemsForUpdatedData = updatedJobItems.map(item => ({
            ...item,
            sale_price: item.sale_price !== null ? parseFloat(item.sale_price.replace(',', '.')) : null,
        }));
    
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedJobItemsForUpdatedData }));
    }

    // Handle the quantity change
    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the job items and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Parse the quantity and unit from the quantity field
            const { quantity, unit } = parseQuantityAndUnit(event.target.value)

            // Check and mark unsaved changes
            checkUnsavedChanges('quantity_registrated', quantity, identifier);
            checkUnsavedChanges('unit', unit, identifier);

            // Set the quantity and unit in the updated job items
            updatedRows[rowIndex].quantity_registrated_string = event.target.value;
            updatedRows[rowIndex].quantity_registrated = quantity;
            updatedRows[rowIndex].unit = unit;

            // Calculate the total price change, only if a sale price is set
            if (updatedRows[rowIndex].sale_price !== null) {
                const salePrice = parseFloat((updatedRows[rowIndex].sale_price || "0").replace(',', '.'));
                updatedRows[rowIndex].total_price = quantity * salePrice;
            }
            
            // Update the job items and updated data with sale price as float
            setRows(updatedRows);
            updateUpdatedDataWithSalePriceAsFloat(updatedRows);
        }        
    };

    // Handle the price change
    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the job items and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Keep the sale price input as string
            const salePriceInput = event.target.value;

            // Set the sale price in the updated job items
            updatedRows[rowIndex].sale_price = salePriceInput;

            // If sale price is set, check and mark unsaved changes and calculate the total price
            if (salePriceInput !== null) {
                const salePrice = parseFloat(salePriceInput.replace(',', '.'));
                checkUnsavedChanges('sale_price', salePrice, identifier);
                const quantity = updatedRows[rowIndex].quantity_registrated || 0;
                updatedRows[rowIndex].total_price = quantity * salePrice;
            }

            // Update the job items
            setRows(updatedRows);

            // Convert the sale price to a float for the backend
            setUpdatedData((currentUpdatedData: any) => {
                const copyOfUpdatedData = { ...currentUpdatedData };
    
                // If no updated data exist, create a new array
                if (!copyOfUpdatedData[name]) {
                    copyOfUpdatedData[name] = [];
                }
                
                // Find the row from the updated data to update
                const updatedIndex = copyOfUpdatedData[name].findIndex((item: any) => item.id?.toString() === identifier || item.uuid === identifier);
    
                // Set the sale price to a float in the updated data for the found row or add the value to the new row
                if (updatedIndex !== -1) {
                    copyOfUpdatedData[name][updatedIndex] = {
                        ...updatedRows[rowIndex],
                        sale_price: salePriceInput ? parseFloat(salePriceInput.replace(',', '.')) : null
                    };
                } else {
                    copyOfUpdatedData[name].push({
                        ...updatedRows[rowIndex],
                        sale_price: salePriceInput ? parseFloat(salePriceInput.replace(',', '.')) : null
                    });
                }
    
                return copyOfUpdatedData;
            });
        }
    }

    // Handle the name change
    const handleNameChange = (selectedItem: any, identifier: string) => {
        // Copy the job items and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (rowIndex !== -1) {
            let salePrice;
            let quantity = updatedRows[rowIndex].quantity_registrated || 0;

            // Check if the selectedItem is a product object or just a name
            if (selectedItem && selectedItem.id) {
                // Set the product id, name and sale price from the selected product
                updatedRows[rowIndex].product = selectedItem.id
                updatedRows[rowIndex].name = selectedItem.name;
                updatedRows[rowIndex].number = (selectedItem.number !== null ? selectedItem.number : null)
                updatedRows[rowIndex].sale_price = (selectedItem.sale_price !== null ? selectedItem.sale_price.toString() : null);
                updatedRows[rowIndex].vat_rate = selectedItem.vat_rate;
                salePrice = selectedItem.sale_price;
            } else if (selectedItem && selectedItem.name && selectedItem.value) {
                // Set the name if just a string is entered
                updatedRows[rowIndex].name = selectedItem.name;
                if (updatedRows[rowIndex].sale_price !== null) {
                    const salePriceStr = updatedRows[rowIndex].sale_price;
                    if (salePriceStr !== null) {
                        salePrice = parseFloat(salePriceStr);
                    }
                } else {
                    salePrice = null;
                }
            }

            // Check or mark unsaved changes
            if (selectedItem && selectedItem.name !== undefined) {
                checkUnsavedChanges('name', selectedItem.name, identifier);
            }

            // Calculate the total price change if a quantity exists and sale price is set
            if (quantity > 0 && salePrice !== null) {
                updatedRows[rowIndex].total_price = (quantity * salePrice);
            }

            // Update the job items and updated data with sale price as float
            setRows(updatedRows);
            updateUpdatedDataWithSalePriceAsFloat(updatedRows);
        }
    }

    // Handle the vat rate change
    const handleVatRateChange = (selectedValue: string, identifier: string) => {
        updateRows(identifier, (item, newVatRate) => {
            // Set the new vat rate
            const updatedItem = {
                ...item,
                vat_rate: parseFloat(newVatRate)
            };
        
            // Check and mark for unsaved changes
            checkUnsavedChanges('vat_rate', parseFloat(newVatRate), identifier);
        
            return updatedItem;
        }, selectedValue);
    };
    
    // Handle the creation of a new row
    const handleAddRow = () => {
        setUnsavedChanges(viewKey, true);

        // Split job items in non deleted and deleted items
        const nonDeletedItems = rows.filter(row => !row.deleted);
        const deletedItems = rows.filter(row => row.deleted);

        // Determine the position of the new item and create it
        const newPosition = nonDeletedItems.length + 1;
        const newJobItem = createNewJobItem(jobId!, uuidv4(), newPosition);
    
        // Add the new row at the end of the list with non deleted items and add the deleted items
        const updatedNonDeletedItems = [...nonDeletedItems, newJobItem];
        const updatedJobItems = [...updatedNonDeletedItems, ...deletedItems];
    
        // Update the job items and updated data with sale price as float
        setRows(updatedJobItems);
        updateUpdatedDataWithSalePriceAsFloat(updatedJobItems);
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        setUnsavedChanges(viewKey, true);

        const updatedJobItems = [...rows];
        const itemIndex = updatedJobItems.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Mark the item as deleted without actually deleting it
            updatedJobItems[itemIndex].deleted = true;
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedJobItems.filter(item => !item.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((item, index) => ({
                ...item,
                position: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedJobItems.filter(item => item.deleted)];
    
            setRows(finalItems);
            updateUpdatedDataWithSalePriceAsFloat(finalItems);
        } 
    }

    // Handle ordering change
    const handleOrderingChange = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;
        
        // Only use the non deleted items to determine the new ordering
        const nonDeletedItems = rows.filter(row => !row.deleted);
        const [reorderedItem] = nonDeletedItems.splice(source.index, 1);
        nonDeletedItems.splice(destination.index, 0, reorderedItem);
    
        // Determine the ordering for the non deleted items
        const updatedNonDeletedItems = nonDeletedItems.map((item, index) => ({
            ...item,
            position: index + 1,
        }));
    
        // Add the deleted items to the list without changing their ordering
        const finalItems = [...updatedNonDeletedItems, ...rows.filter(row => row.deleted)];
    
        setRows(finalItems);
        updateUpdatedDataWithSalePriceAsFloat(finalItems);
    };

    return (
        <div className='widget-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {rows.length > 0 && (
                        <div className='rows-header job-items-row'>
                            <div className='header-item'>{t('job.items.quantity_label')}</div>
                            <div className='header-item'>{t('job.items.description_label')}</div>
                            {showPriceFields && <>
                                <div className='header-item'>{t('job.items.price_label')}</div>
                                <div className='header-item'>{t('job.items.total_label')}</div>
                                <div className='header-item'>{t('job.items.vat_rate_label')}</div>
                            </>}
                            <div className='delete-placeholder'></div>
                            <div className='drag-placeholder'></div>
                        </div>
                    )}
                    <DragDropContext onDragEnd={handleOrderingChange}>
                        <StrictModeDroppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                                        const identifier = row.id?.toString() || row.uuid;
                                        return (
                                            <Draggable key={identifier} draggableId={`item-${identifier}`} index={index} isDragDisabled={rows.length <= 1}>
                                                {(provided) => {
                                                    return (
                                                        <div className='list-item draggable' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className='job-items-row'>
                                                                <input 
                                                                    type="text"
                                                                    id={`quantity_registrated_string_${identifier}`}
                                                                    name={`quantity_registrated_string_${identifier}`}
                                                                    value={row.quantity_registrated_string ?? ''}
                                                                    onChange={event => handleQuantityChange(event, identifier || '')}
                                                                    placeholder={t('job.items.quantity_placeholder')}
                                                                    autoFocus={false}
                                                                    className={errorMessages[index]?.row ? 'is-invalid' : ''}
                                                                />
                                                                <SearchSelect
                                                                    key={identifier}
                                                                    type='searchselect' 
                                                                    name='name'
                                                                    value={jobItemNameToFieldOption(row)}
                                                                    onSelect={(selectedItem) => handleNameChange(selectedItem, identifier || '')}
                                                                    data={data}
                                                                    placeholder='job.items.description_placeholder'
                                                                    objectName='job.items.description_object_name'
                                                                    query={{ endpoint: 'get_product_list', params: { is_active: true }}}
                                                                    postEndpoint='post_product'
                                                                    disableAddNew={true}
                                                                    allowTextualInput={true}
                                                                    isTextualValue={isTextualInput(row)}
                                                                    optionFormat={{ 
                                                                        title: { field: 'name', format: (value) => value },
                                                                        bracket: { field: 'number', format: (value) => value, optional: true }, 
                                                                    }}
                                                                    selectionFormat='name'
                                                                />
                                                                {showPriceFields && (
                                                                    <>
                                                                        <input 
                                                                            type="text"
                                                                            id={`sale_price_${row.id || row.uuid}`}
                                                                            name={`sale_price_${row.id || row.uuid}`}
                                                                            value={row.sale_price ?? undefined}
                                                                            onChange={event => handlePriceChange(event, row.id?.toString() || row.uuid || '')}
                                                                            placeholder={t('job.items.price_placeholder')}
                                                                            className={errorMessages[index]?.row ? 'is-invalid' : ''}
                                                                        />
                                                                        <div>
                                                                            {row.total_price || 0}
                                                                        </div>
                                                                        <Dropdown<VatRateType>
                                                                            options={vatRates}
                                                                            id={`vat_rate_${identifier}`}
                                                                            name={`vat_rate_${identifier}`}
                                                                            disabled_selected={t('job.items.vat_rate_placeholder')}
                                                                            selectedOption={vatRates.find(vatRate => row.vat_rate === vatRate.id)}
                                                                            value={row.vat_rate}
                                                                            onChange={(selectedValue) => handleVatRateChange(selectedValue, row.id?.toString() || row.uuid || '')}
                                                                            selectionFormat={(option) => `${option.name}`}
                                                                            optionFormat={(option) => `${option.name}`}
                                                                            allowNoneOption={false}                           
                                                                        />
                                                                    </>
                                                                )}
                                                                <div className='delete-icon tooltip-icon'>
                                                                    <FontAwesomeIcon 
                                                                        icon={faTrash} 
                                                                        onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')} />
                                                                    <span className="tooltip">{t('general.delete')}</span>
                                                                </div>
                                                                <div className={`order-icon tooltip-icon ${rows.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                    <FontAwesomeIcon icon={faGripLines} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </div>
                                                            </div>
                                                            {errorMessages[index]?.jobItem && 
                                                                <div className='error-message'>
                                                                    {errorMessages[index]?.jobItem}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                        {t('forms.add_new_item')}
                    </div>    
                    {helperText && 
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                </div>
            ) : (
                // View mode
                <div className={`full-width-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>
                    <div className="view-mode">
                        <span className='p'>
                            <div className='item-list'>
                                {rows.filter(row => row.id).map((row) => {
                                    let formattedTotalPrice = '';
                                    if (row.total_price) {
                                        formattedTotalPrice = row.total_price.toLocaleString(userLocale, { style: 'currency', currency: jobCurrency });
                                    }
                                    
                                    return (
                                        <div key={row.id!.toString()} className='item'>
                                            <span>{row.quantity_registrated_string} </span>
                                            <span style={{ whiteSpace: "pre" }}>{row.name} </span>
                                            {row.number && <span>({row.number}) </span>}
                                            {showPriceFields && row.total_price && <span>- {formattedTotalPrice}</span>}
                                        </div>
                                    )
                                })}
                            </div>
                            {isEditable && !userHasOnlyViewRights && (
                                // Only show add item label when the object is editable
                                <div className='add-item'>
                                    {t('forms.add_item')}
                                </div>
                            )}
                        </span>
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                </div>
            )}
        </div>
    );
}

export default JobItemsFieldset;