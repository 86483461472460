import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FormFieldContext from '../FormFieldContext';
import { TextFieldType, FieldData } from 'types/FieldTypes';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const TextField: React.FC<TextFieldType & { data: FieldData, viewKey: string }> = ({ 
    name, label, data, defaultValue, helperText, tooltipText, placeholder, onChange, viewInEditMode, shouldAutoFocus, 
    saveOnBlur, showIfFilled, alignment, isEditable, disabled = false, emptyLabel, viewKey
}) => {
    const { t } = useTranslation();
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const [value, setValue] = useState('');

    // Set the value with the received data or default value
    useEffect(() => {
        if (data && data[name] !== undefined) {
            setValue(data[name]);
        }
        else if (defaultValue !== undefined) {
            setValue(defaultValue);
            setTimeout(() => { // Hacky solution, tried everything but it wont work..
                setUpdatedData((prev: typeof updatedData) => ({ ...prev, [name]: defaultValue }));
            }, 0);
        }
    }, [data, name, defaultValue, setUpdatedData])

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!value || value === '')) {
        return null;
    }

    // Updates the value after changing the input
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) { onChange(e);}
        
        const hasChanges = e.target.value !== data[name];
        setUnsavedChanges(viewKey, hasChanges);
        setUpdatedData({...updatedData, [name]: e.target.value});
    };

    // Submits the value after clicking outside the field
    const handleBlur = saveOnBlur ? (e: React.FocusEvent<HTMLInputElement>) => {
        if (document.activeElement !== e.target) {
            e.preventDefault();
            if (handleSubmit) handleSubmit();
        }
    } : undefined;
    
    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (handleSubmit) handleSubmit();
        }
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <input
                    type="text"
                    id={name}
                    name={name}
                    placeholder={t(placeholder)}
                    value={value}
                    disabled={disabled}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    className={`text ${errorMessages[name] ? 'is-invalid' : ''}`}
                    autoFocus={shouldAutoFocus}
                    onKeyDown={handleKeyDown}
                    data-1p-ignore
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={value} 
                    alignment={alignment}
                    emptyLabel={emptyLabel}
                />
            )}
        </FieldWrapper>
    )
};

export default TextField;