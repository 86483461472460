import React, { useState, useEffect, useRef, useContext } from 'react';
import { GroupFilter, ListFilterType, QueryParamType, SingleFilter } from 'types/ListTypes';
import ListContext from './ListContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';

interface FilterButtonProps {
    filters: ListFilterType[];
    defaultParams?: QueryParamType;
}

// Determine whether the filter option is part of a group
const isGroupFilter = (filter: ListFilterType): filter is GroupFilter => {
    return (filter as GroupFilter).group !== undefined;
};

const FilterButton: React.FC<FilterButtonProps> = ({ filters, defaultParams }) => {
    const { t } = useTranslation();
    const { queryParams, updateQueryParams, listMetaData } = useContext(ListContext);
    const [selectedFilters, setSelectedFilters] = useState<string[]>(() => getInitialFilter(filters, defaultParams || {}));
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // If the default params of the list config matches a filter, set it as initial filter
    function getInitialFilter(filters: ListFilterType[], defaultParams: QueryParamType): string[] {
        let initialFilters: string[] = [];

        filters.forEach(filter => {
            if (isGroupFilter(filter)) {
                initialFilters = [...initialFilters, ...getInitialFilter(filter.filters, defaultParams)];
            } else if (filter.params && Object.entries(filter.params).every(([key, value]) => 
                String(defaultParams[key]) === String(value))) {
                initialFilters.push(filter.id);
            } else if (filter.default) {
                initialFilters.push(filter.id);
            }
        });

        return initialFilters;
    }

    // If the query params changes, check if they match with a filter 
    useEffect(() => {
        const updatedSelectedFilters: string[] = [];

        filters.forEach(filter => {
            if (isGroupFilter(filter)) {
                filter.filters.forEach(subFilter => {
                    if (subFilter.params && Object.entries(subFilter.params).every(([key, value]) => 
                        String(queryParams[key]) === String(value))) {
                        updatedSelectedFilters.push(subFilter.id);
                    }
                });
            } else if (filter.params && Object.entries(filter.params).every(([key, value]) => 
                String(queryParams[key]) === String(value))) {
                updatedSelectedFilters.push(filter.id);
            }
        });

        if (updatedSelectedFilters.length === 0) {
            const defaultFilter = filters.find(f => !isGroupFilter(f) && f.default) as SingleFilter;
            updatedSelectedFilters.push(defaultFilter.id);
        }

        setSelectedFilters(updatedSelectedFilters);
    }, [queryParams, filters]);

    // Open/close the dropdown
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    // Handle the filter change
    const handleFilterChange = (filter: SingleFilter) => {
        let updatedFilters: string[] = [...selectedFilters];
    
        if (filter.default) {
            // If the default filter is selected, reset to only the default filter
            updatedFilters = [filter.id];
        } else if (selectedFilters.includes(filter.id)) {    
            // Remove the filter if it was already selected
            updatedFilters = updatedFilters.filter(f => f !== filter.id);
        } else {
            // Check if the filter is part of a group
            const parentGroup = filters.find(f => isGroupFilter(f) && f.filters.some(subFilter => subFilter.id === filter.id)) as GroupFilter | undefined;
    
            if (parentGroup) {
                // Remove other filters from the same group
                updatedFilters = updatedFilters.filter(f => {
                    const existingFilterGroup = filters.find(g => isGroupFilter(g) && g.filters.some(subFilter => subFilter.id === f)) as GroupFilter | undefined;
                    return !existingFilterGroup || existingFilterGroup.group !== parentGroup.group;
                });
            }
    
            // Add the new filter to the selection
            updatedFilters.push(filter.id);
        }

        // Close the dropdown after every selection
        setIsOpen(!isOpen);
    
        // If no filters are selected, fall back to the default filter
        if (updatedFilters.length === 0) {
            const defaultFilter = filters.find(f => !isGroupFilter(f) && f.default) as SingleFilter;
            updatedFilters = [defaultFilter.id];
        }
    
        // Create a new params object based on the updatedFilters
        const combinedParams = updatedFilters.reduce((params: Record<string, any>, filterId) => {
            const selectedFilter = filters.flatMap(f => isGroupFilter(f) ? f.filters : [f])
                                          .find(f => f.id === filterId) as SingleFilter;
    
            if (selectedFilter) {
                return { ...params, ...selectedFilter.params };
            }
            return params;
        }, {});
    
        // Update the query parameters in the URL
        updateQueryParams(combinedParams);
    };

    // Close the dropdown when clicking next to it
    useEffect(() => {
        const handleClickOutsideDropdown = (event: MouseEvent) => {            
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => document.removeEventListener('click', handleClickOutsideDropdown);
    }, []);

    // Construct the filter button label
    const getButtonLabel = () => {

        // If just one filter is selected, show the label of the filter
        if (selectedFilters.length === 1) {
            const singleFilter = filters.flatMap(f => isGroupFilter(f) ? f.filters : [f])
                                         .find(f => f.id === selectedFilters[0]) as SingleFilter;
    
            // If the current filter is the default filter, just show the label
            if (singleFilter.default) {
                return `${t(singleFilter.label)}`;

            // For other filters, show the filter button label as prefix to make clear a filter is selected
            } else {
                return `${t('button.filter_button_label')} ${t(singleFilter.label)}`;
            }

        // If multiple filters are selected, show a list of short names or labels of the selected filters without the default filter
        } else if (selectedFilters.length > 1) {
            const filterNames = filters.flatMap(f => isGroupFilter(f) ? f.filters : [f])
                                        .filter(f => selectedFilters.includes(f.id) && !f.default)
                                        .map(f => t(f.shortName || f.label))
                                        .join(', ');
            return `${t('button.filter_button_label')} ${filterNames}`;

        // If only the default filter is selected, show the label of the default filter
        } else {
            const defaultFilter = filters.find(f => !isGroupFilter(f) && f.default) as SingleFilter;
            return `${t(defaultFilter.label)}`;
        }
    };

    return (
        <div className='filter-dropdown' ref={dropdownRef}>
            <button type='button'
                    onClick={toggleDropdown}
                    className={`button button-medium button-secondary ${isOpen ? " button-active" : ""}`}
                    style={{ position: 'relative' }}>
                <span className='button-text'>
                    {` ${getButtonLabel()} (${listMetaData.totalRows.toLocaleString()})`}
                </span>
                <FontAwesomeIcon
                    icon={!isOpen ? faCaretDown : faCaretUp}
                    style={{ marginLeft: '0.3rem', paddingBottom: '0.025rem' }}
                />
            </button>
            {isOpen && (
                <div className='dropdown-button-menu'>
                    {filters.map((filter, index) => {
                        if (isGroupFilter(filter)) {
                            return (
                                <div key={filter.group} className='dropdown-button-group'>
                                    <div className='dropdown-button-group-title'>{t(filter.group)}</div>
                                    {filter.filters.map(subFilter => (
                                        <div key={subFilter.id}
                                            className={`dropdown-button-item ${selectedFilters.includes(subFilter.id) ? 'active' : ''}`}
                                            onClick={() => handleFilterChange(subFilter)}>
                                            {t(subFilter.label)}
                                            {selectedFilters.includes(subFilter.id) && (
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className='filter-dropdown-icon' />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            );
                        } else {
                            return (
                                <div key={filter.id} 
                                    className={`dropdown-button-item ${selectedFilters.includes(filter.id) ? 'active' : ''}`}
                                    onClick={() => handleFilterChange(filter)}>
                                    {t(filter.label)}
                                    {selectedFilters.includes(filter.id) && (
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className='filter-dropdown-icon' />
                                    )}
                                </div>
                            );
                        }
                    })}
                </div>
            )}
        </div>
    );
};

export default FilterButton;