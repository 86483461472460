import React, { useState, useEffect, useMemo } from 'react';
import axios, { CancelTokenSource } from 'axios';
import FormFieldContext from 'components/forms/FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from './ModalContext';
import { fetchData } from 'services/api/fetchData';
import { saveData } from 'services/api/saveData';
import { handleSaveErrors } from 'services/api/handleSaveErrors';
import { formValidation } from 'services/utils/formValidation';
import { shakeElement } from 'services/utils/shakeElement';
import { FieldOption, FieldOptionFetchResponse, FieldType } from 'types/FieldTypes';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { fetchDropdownDataFromFields } from 'services/api/fetchFieldData';
import { useTranslation } from 'react-i18next';

interface FormModalProps {
    viewKey?: string, 
    apiObject: string, 
    fields: FieldType[], 
}

const FormModal: React.FC<FormModalProps> = ({ viewKey, apiObject, fields }) => {
    const { t } = useTranslation();
    const { setFloatingAlert, errorMessages, setErrorMessages } = useGlobalContext();
    const { modalStack, revealModal, closeModal } = useModal();
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);    
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([]);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [data, setData] = useState<FieldType[]>([]);
    const [editing, setEditing] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const topModal = modalStack[modalStack.length - 1];
    const itemId = topModal?.props?.itemId;

    // Fetch the current data and dropdown options
    useEffect(() => {
        // Fetch the current data of the form
        const fetchFieldData = async () => {
            if (itemId) {
                const response = await fetchData({ apiObject, itemId });
                setData(response);
            }
        };

        // Fetch options of dropdown and (multi)select fields
        const fetchDropdownData = async () => {
            const dropdownData: Record<string, FieldOptionFetchResponse> = {};
            for (let field of fields) {
                if ((field.type === 'dropdown' || field.type === 'multiselect' || field.type === 'tabs' || field.type === 'price-currency') && field.apiObject) {
                    const results = await fetchDropdownDataFromFields(field.apiObject, field.params);
                    dropdownData[field.apiObject] = results;
                }
                // If multiselect fields are grouped, fetch the group data
                if (field.type === 'multiselect' && field.groupByField) {
                    const results = await fetchData({ apiObject: field.groupByField?.apiObject, params: { is_active: 'true' } });
                    const fieldName = field.groupByField.apiField;

                    // Only extract the id and the given field name from the data
                    const extractedFields = results.results.map((item: any) => ({
                        id: item.id,
                        [fieldName]: item[fieldName],
                    }));
                    setGroupOptions(extractedFields);
                }
            }
            setDropdownData(dropdownData);
        }

        // Execute the fetches
        (async () => {
            try {
                await Promise.all([fetchFieldData(), fetchDropdownData()]);
            } finally {
                // Reveal the modal after loading
                revealModal();
            }
        })();
    }, []);

    // Handle submit
    const handleSubmit = async () => {
        // Configure the data to submit
        const dataToSubmit: Record<string, any> = { ...updatedData, ...(itemId ? { id: itemId } : {}) }; 

        // Perform form validation on submit
        for (const field of fields) {
            if ('name' in field && field.name !== undefined) {
                const fieldName = field.name;
                if (fieldName && fieldName in dataToSubmit) {
                    const validationError = formValidation(field, dataToSubmit);
                    if (validationError) {
                        setErrorMessages(validationError);
                        return;
                    }
                }
            }   
        }

        try {
            setButtonLoader(true);

            // Create the cancel token source
            const source = axios.CancelToken.source();
            setCancelSource(source);

            // Saves the data
            const response = await saveData({ apiObject, itemId, data: dataToSubmit, source });

            // Handle successful response
            if (response?.status === 200 || response?.status === 201) {
                setButtonLoader(false);
                setFloatingAlert({ 'type': 'success' })
                closeModal();
                setErrorMessages({});
            }
        } catch (error) {
            // Extract the names of the fields from the fields to do validation on
            const fieldsWithName = fields.filter(field => 'name' in field);
            const errorFields = (fieldsWithName as Array<{ name: string }>).map(field => field.name);

            // Handle the errors using the handleSaveErrors function
            const errorData = handleSaveErrors(error, errorFields)
            setButtonLoader(false);
            setErrorMessages(errorData);

            // Shake the modal in any case of an error
            if (topModal?.modalRef) shakeElement(topModal.modalRef.current as HTMLElement);
        }
    };

    // Closes the modal when clicking the close or cancel button
    const handleClose = () => {
        // Removes the current data, so the form will be empty at reopening
        setData([]);

        // Removes errors from fields, hides error alert and closes the modal
        setErrorMessages({});
        setShowErrorAlert(false);
        closeModal();

        // If a request is still pending when closing, cancel the request
        if (cancelSource) {
            cancelSource.cancel();
        }

        // Stops the button loader icon
        setButtonLoader(false);
    }

    // Generate the field component for the form
    const fieldComponents = useMemo(() => {
        if (!viewKey) return;
        return generateFieldComponents(viewKey, fields, dropdownData, groupOptions, data, updatedData, itemId);
    }, [dropdownData, updatedData, data]);

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData, 
            buttonLoader, setButtonLoader, showErrorAlert, setShowErrorAlert, handleSubmit }}>
            {(errorMessages.general || showErrorAlert) &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            <form className='formset add-edit-form' 
                  onSubmit={(e) => {e.preventDefault(); handleSubmit();}}>
                {fieldComponents}
                <div className='buttons-right'>
                    <SecondaryButton
                        onClick={handleClose} 
                        label='general.cancel'
                        size="small"/>
                    <PrimaryButton
                        type="submit" 
                        label='general.save'
                        size="small"
                        onlyViewRestriction={true}
                        loading={buttonLoader}/>
                </div>
            </form>
        </FormFieldContext.Provider>
    );
};

export default FormModal;