import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const LocationForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'location'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'street',
            label: 'location.general.street_label',
            type: 'text',
            placeholder: 'location.general.street_placeholder',
            group: 'street-row',
            width: '70%'
        },
        {
            name: 'house_number',
            label: 'location.general.house_number_label',
            type: 'text',
            placeholder: 'location.general.house_number_placeholder',
            group: 'street-row',
            width: '30%'
        },
        {
            name: 'postal_code',
            label: 'location.general.postal_code_label',
            type: 'text',
            placeholder: 'location.general.postal_code_placeholder',
            group: 'city-row',
            width: '35%'
        },
        {
            name: 'city',
            label: 'location.general.city_label',
            type: 'text',
            placeholder: 'location.general.city_placeholder',
            group: 'city-row',
            width: '65%'
        },
        {
            name: 'country',
            label: 'location.general.country_label',
            type: 'text',
            placeholder: 'location.general.country_placeholder',
        },
        {
            name: 'name',
            label: 'location.general.location_name_label',
            type: 'text',
            placeholder: 'location.general.location_name_placeholder',
            tooltipText: 'location.general.location_name_tooltipText',
        }
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default LocationForm;