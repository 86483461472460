export const parseDurationFromAmount = (input: string): number | null => {
    // Replace commas by periods
    const normalizedInput = input.trim().replace(',', '.');

    // Check if the input is empty
    if (normalizedInput === '') {
        return null;
    }

    // Attempt to parse the input as a decimal number
    const decimalHours = parseFloat(normalizedInput);
    if (!isNaN(decimalHours)) {
        return decimalHours;
    }

    // If the input is not valid, return null
    return null;
};