import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { v4 as uuidv4 } from 'uuid';
import { fetchData } from 'services/api/fetchData';
import { AppointmentType, TimeBlockType, createNewAppointment } from './JobTypes';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import CheckboxCheck from 'assets/CheckboxCheck.svg';
import FormFieldContext from 'components/forms/FormFieldContext';
import { saveData } from 'services/api/saveData';
import { useGlobalContext } from 'GlobalContext';
import DateInput from 'components/forms/basefields/DateInput';
import TimeInput from 'components/forms/basefields/TimeInput';
import Dropdown from 'components/forms/basefields/Dropdown';
import MultiSelect from 'components/forms/basefields/MultiSelect';
import { UserType } from '../../views/users/UserTypes';
import IconButton from 'components/buttons/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSettings } from 'services/settings/SettingsContext';
import { convertLocalDateTimeToUTC, extractDateFromLocalDateTime } from 'internationalization/timezoneConversions';
import { convertAppointmentsToLocalDateTimes, sortAppointments } from 'services/utils/sortDates';
import '../../style/scss/forms.scss';
import '../../style/scss/modals.scss';

interface ScheduleJobModalProps {
    viewKey?: string;
    jobId: number;
    setLoading: (loading: boolean) => void;
}

const ScheduleJobModal: React.FC<ScheduleJobModalProps> = ({ viewKey, jobId, setLoading }) => {
    const { t } = useTranslation()
    const { environmentSettings, userLocale, userTimezone } = useSettings();
    const { setFloatingAlert, errorMessages } = useGlobalContext()
    const { revealModal, closeModal } = useModal();
    const { setUnsavedChanges } = useGlobalContext();
    const [rows, setRows] = useState<AppointmentType[]>([]);    
    const [timeBlocks, setTimeBlocks] = useState<TimeBlockType[]>([]);
    const [assignees, setAssignees] = useState<UserType[]>([]);
    const [appointmentStates] = useState<{ value: string, name: string }[]>([ 
        { value: 'reserved', name: 'appointment.status.reserved' }, 
        { value: 'scheduled', name: 'appointment.status.scheduled' }, 
        { value: 'done', name: 'appointment.status.done' }]);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const [scheduleTimeBlocks, setScheduleTimeBlocks] = useState<boolean>(false);
    const [scheduleMultipleAppointments, setScheduleMultipleAppointments] = useState('multiple');
    const [emailCheckboxValue, setEmailCheckboxValue] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState({});


    // To do: Pass handle submit when updated data is empty (gives an error)
    // To do: Test handle submit, environment id error, no start_date

    // To do: Validate start time before end time, choose to set end date to the next day
    // To do: If whole day is not true, start and end time are manditory.
    // To do: Validate start time is later then end time, give validation error --> give choice: loopt afspraak door tot de volgende dag?

    // To do: Show end date field if it differs.
    // To do: SchedulingSettings & JobSettings translation strings

    // To do: set unsaved changes ?
    // To do: scheduling button in detail modal with dropdown. if job appointment method is single, only pick date from calendar

    // Fetch the current appointments and dropdown options
    useEffect(() => {
        // Fetch the current data of the form
        const fetchFieldData = async () => {
            const response = await fetchData({ apiObject: 'appointments_for_job_list', itemId: jobId });
            if (response.results.length === 0) {
                // If the response is empty, create an empty row
                setRows([createNewAppointment(jobId, uuidv4())]);
            } else {
                // Convert appointment dates to local date times
                const { appointmentsWithLocalDatetimes } = convertAppointmentsToLocalDateTimes(response.results, userLocale, userTimezone);

                // Sort the appointments on start datetime
                const { sortedAppointments } = sortAppointments(appointmentsWithLocalDatetimes);

                // Set the appointments as rows
                setRows(sortedAppointments);             
            }
        };

        // Fetch the time blocks options
        const fetchTimeBlocks = async () => {
            const response = await fetchData({ apiUrl: 'get_timeblock_list' });
            setTimeBlocks(response?.results || []);
        };

        // Fetch the assignee options
        const fetchAssignees = async () => {
            const response = await fetchData({ apiUrl: 'get_user_list', params: { 'is_active': true, 'field_worker': true }});
            setAssignees(response?.results || []);
        };

        setLoading(true);

        // Execute the fetches
        (async () => {
            try {
                await Promise.all([fetchFieldData(), fetchTimeBlocks(), fetchAssignees()]);
            } finally {
                // Reveal the modal after loading
                setLoading(false);
                revealModal();
            }
        })();
    }, []);

    // Set the show schedule time blocks setting on render
    useEffect(() => {
        if (environmentSettings.scheduling_time_blocks) setScheduleTimeBlocks(environmentSettings.scheduling_time_blocks);
        if (environmentSettings.job_appointment_method) setScheduleMultipleAppointments(environmentSettings.job_appointment_method);
    }, [environmentSettings]);

    // Function to add a new row
    const handleAddRow = () => {
        setRows([...rows, createNewAppointment(jobId, uuidv4())]);
    };

    // Function to delete an existing row
    const handleDeleteRow = (identifier: string) => {
        if (viewKey) setUnsavedChanges(viewKey, true);

        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            if (updatedRows[rowIndex].id === null && updatedRows[rowIndex].uuid !== null) {
                // Newly added rows have no id and don't delete in the database, so just delete the row
                updatedRows.splice(rowIndex)
            } else {
                // Existing items have an id but no uuid, and have to set to deleted
                updatedRows[rowIndex].deleted = true;
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    // Handle the date change
    const handleDateChange = (selectedDate: string | null, fieldName: 'start_date' | 'end_date', identifier: string) => {
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedDate;

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: 'whole_day' | 'status', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value in the row
            if (fieldName === 'whole_day') {
                updatedRows[rowIndex].whole_day = event.target.checked;
            };

            if (fieldName === 'status') {
                if (event.target.checked === true) {
                    updatedRows[rowIndex].status = 'reserved';
                } else if (event.target.checked === false) {
                    updatedRows[rowIndex].status = 'scheduled';
                }
            };

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    // Handle the time change
    const handleTimeChange = (selectedTime: string | null, fieldName: 'start_time' | 'end_time', identifier: string) => {
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedTime;
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    // Handle the dropdown change
    const handleDropdownChange = (selectedValue: string, fieldName: 'time_block' | 'status', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            if (fieldName === 'time_block') updatedRows[rowIndex][fieldName] = parseFloat(selectedValue);
            if (fieldName === 'status') updatedRows[rowIndex][fieldName] = selectedValue;
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    };

    // Handle the multiselect change
    const handleMultiSelectChange = (selectedValue: string, fieldName: 'assignees', identifier: string) => {
        // Copy the time rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            let updatedAssignees = rows[rowIndex][fieldName] || [];
            
            // Zoek de index van de assignee in de huidige lijst
            const assigneeIndex = updatedAssignees.findIndex(assignee => assignee.id.toString() === selectedValue);
    
            if (assigneeIndex > -1) {
                // Verwijder de assignee als deze al bestaat (deselecteren)
                updatedAssignees.splice(assigneeIndex, 1);
            } else {
                // Voeg de assignee toe als deze nog niet bestaat
                const assigneeToAdd = assignees.find(assignee => assignee.id.toString() === selectedValue);
                if (assigneeToAdd) {
                    updatedAssignees.push(assigneeToAdd);
                }
            }
    
            // Kopieer de bestaande rows en vervang de assignees voor deze specifieke row
            const updatedRows = rows.map((row, index) => 
                index === rowIndex ? { ...row, [fieldName]: updatedAssignees } : row
            );
    
            // Update de state van rows
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    };

    // Handle email checkbox change
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setEmailCheckboxValue(checked);
    }

    const combineDateAndTime = (dateInput: string, timeInput: string): string => {
        // Extract date part from dateInput if it's a datetime string
        let datePart = dateInput;
        if (dateInput.includes('T')) {
            datePart = dateInput.split('T')[0];
        }

        // Extract time part from timeInput if it's a datetime string
        let timePart = timeInput;
        if (timeInput.includes('T')) {
            timePart = timeInput.split('T')[1];
        }

        // If timePart does not contain seconds, add ':00'
        if (!timePart.includes(':')) {
            timePart += ':00';
        } else if (timePart.split(':').length === 2) {
            timePart += ':00';
        }

        // Combine date and time parts into an ISO datetime string
        return `${datePart}T${timePart}`;
    }

    const handleSubmit = async () => {
        const convertedDateTimes = updatedData.map((row: any) => { 
            let convertedStartDateTime, convertedEndDateTime, convertedTimeBlock;

            // Configure datetimes for whole day appointments
            if (row.whole_day) {

                // If the value in de date field is changed, pick the start_date, otherwise pick the date from the original start_datetime
                let dateToConvert = row.start_date || row.start_datetime;

                if (dateToConvert) {
                    // Set the date of the datetime field
                    convertedStartDateTime = dateToConvert;
                    
                    // For whole day appointments, set the time to 0:00
                    convertedStartDateTime.setHours(0, 0, 0, 0);

                    // Make the end datetime empty
                    convertedEndDateTime = null;

                    // Make the time block selection empty
                    convertedTimeBlock = null;
                }

            // Configure datetimes for time block appointments
            } else if (row.time_block) {

                // Get the selected time block
                const selectedTimeBlock = timeBlocks.find(block => block.id === row.time_block);
                if (selectedTimeBlock) {
                    let hours, minutes;

                    // Get the start time of the time block if set, otherwise set it to 0:00
                    if (selectedTimeBlock.start_time) {
                        [hours, minutes] = selectedTimeBlock.start_time.split(':').map(Number);
                    } else {
                        hours = 0;
                        minutes = 0;
                    }

                    // If the value in de date field is changed, pick the start_date, otherwise pick the date from the original start_datetime
                    let dateToConvert = row.start_date || row.start_datetime;

                    if (dateToConvert) {
                        // Set the date of the datetime field
                        convertedStartDateTime = dateToConvert;

                        // Set the time to the time block start time or to 0:00
                        convertedStartDateTime.setHours(hours, minutes, 0, 0);

                        // Make the end datetime empty
                        convertedEndDateTime = null;

                        // Set the time block
                        convertedTimeBlock = row.time_block;
                    }
                }

            // Configure timed appointments
            } else {

                // Handle if a new start date is entered
                if (row.start_date) {
                    let timeToConvert = row.start_time || row.start_datetime;

                    if (timeToConvert) {
                        convertedStartDateTime = combineDateAndTime(row.start_date, timeToConvert);
                    }
                }

                // Handle if a new start time is entered
                if (row.start_time) {
                    let dateToConvert = row.start_date || row.start_datetime;

                    if (dateToConvert) {
                        convertedStartDateTime = combineDateAndTime(dateToConvert, row.start_time);
                    }
                }

                // If both start date and start time are not changed, pick the original value
                if (!row.start_date && !row.start_time) {
                    convertedStartDateTime = row.start_datetime;
                }

                // Handle if a new end date is entered
                if (row.end_date) {
                    let timeToConvert = row.end_time || row.end_datetime;

                    if (timeToConvert) {
                        convertedEndDateTime = combineDateAndTime(row.end_date, timeToConvert);
                    }
                }

                // Handle if end time is entered
                if (row.end_time) {
                    // If no new end date is entered, or an end date already exists, pick the start date as end date. 
                    // This means the end time is on the same day as the start date.
                    let dateToConvert = row.end_date || row.end_datetime || row.start_date || row.start_datetime;

                    if (dateToConvert) {
                        convertedEndDateTime = combineDateAndTime(dateToConvert, row.end_time);
                    }
                }

                // If both end date and end time are not changed, pick the original value
                if (!row.end_date && !row.end_time) {
                    // convertedEndDateTime = new Date(row.end_datetime);
                    convertedEndDateTime = row.end_datetime;
                }            
            };

            // Convert the local datetimes back to utc
            convertedStartDateTime = convertedStartDateTime ? convertLocalDateTimeToUTC(convertedStartDateTime, userLocale, userTimezone) : null;
            convertedEndDateTime = convertedEndDateTime ? convertLocalDateTimeToUTC(convertedEndDateTime, userLocale, userTimezone) : null;

            return {
                ...row,
                start_datetime: convertedStartDateTime,
                end_datetime: convertedEndDateTime,
                time_block: convertedTimeBlock
            };
        });

        // Convert the assigned user objects into an array of id's
        const convertedDateTimesAndAssignees = convertedDateTimes.map((prevData: any) => ({ 
            ...prevData, 
            assignees: prevData.assignees.map((assignee: UserType) => assignee.id)
        }))

        // Data to save is the converted datetimes and assignees
        const dataToSave = convertedDateTimesAndAssignees;

        try {
            console.log("data to save:", dataToSave);

            const response = await saveData({ apiUrl: `patch_appointment_batch`, method: 'patch', data: dataToSave });

            if (response?.status === 200 || response?.status === 201) {
                console.log("success!")
                setFloatingAlert({ type: 'success' })
                closeModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <FormFieldContext.Provider value={{ updatedData, setUpdatedData }}>
            <div className='schedule-job-modal'>
                <h3 className='header text-center'>{t('job.schedule_job.schedule_job_header')}</h3>
                {rows && rows.filter(row => row.deleted === false).map((row, index) => {
                    const identifier = row.id?.toString() || row.uuid;

                    // Determine to show the end date field if the end date differs from the start date
                    let showEndDateField;
                    if (row.start_datetime && row.end_datetime) {
                        const rowStartDateTime = extractDateFromLocalDateTime(row.start_datetime, userLocale);
                        const rowEndDateTime = extractDateFromLocalDateTime(row.end_datetime, userLocale)

                        showEndDateField = rowStartDateTime !== rowEndDateTime;
                    };

                    // Set the row to disabled if the row status is done
                    const rowIsDone = row.status === 'done';

                    return (
                        <div className='appointment-row' key={index}>
                            <div className='fields'>
                                <div className={`date-time-row ${row.whole_day ? 'whole-day' : ''}`}>
                                    <div>
                                        <label>
                                            {t('job.schedule_job.date_label')}
                                        </label>
                                        <DateInput
                                            id={`start_date_${identifier}`}
                                            name={`start_date_${identifier}`}
                                            localDatetime={row.start_datetime ?? undefined}
                                            disabled={rowIsDone}
                                            onChange={(selectedValue) => handleDateChange(selectedValue, 'start_date', identifier || '')} />
                                    </div>
                                    {!row.whole_day && !row.time_block && (
                                        // Only show time fields when its not a whole day or time block appointment
                                        <>
                                            <div>
                                                <label>
                                                    {t('job.schedule_job.start_time_label')}
                                                </label>
                                                <TimeInput
                                                    type='time'
                                                    id={`start_time_${row.id || row.uuid}`}
                                                    name={`start_time_${row.id || row.uuid}`}
                                                    localDatetime={row.start_datetime ?? undefined}
                                                    disabled={rowIsDone}
                                                    onChange={(selectedTime) => handleTimeChange(selectedTime, 'start_time', row.id?.toString() || row.uuid || '')} />
                                            </div>
                                            <div>
                                                <label>
                                                    {t('job.schedule_job.end_time_label')}
                                                </label>
                                                <TimeInput
                                                    type='time'
                                                    id={`end_time_${row.id || row.uuid}`}
                                                    name={`end_time_${row.id || row.uuid}`}
                                                    localDatetime={row.end_datetime ?? undefined}
                                                    disabled={rowIsDone}
                                                    onChange={(selectedTime) => handleTimeChange(selectedTime, 'end_time', row.id?.toString() || row.uuid || '')} />
                                            </div>
                                        </>
                                    )}
                                    {showEndDateField && (
                                        // Only show the end date field when the appointment end-time ends on the next day
                                        <div>
                                            <label>
                                                {t('job.schedule_job.end_date_label')}
                                            </label>
                                            <DateInput
                                                id={`end_date_${identifier}`}
                                                name={`end_date_${identifier}`}
                                                localDatetime={row.end_datetime ?? undefined}
                                                disabled={rowIsDone}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, 'end_date', row.id?.toString() || row.uuid || '')} />
                                        </div>
                                    )}
                                    {!row.time_block && !showEndDateField && (
                                        // Only whole day checkbox if its no time block appointment
                                        <div className='whole-day'>
                                            <div className='checkbox-item left'>
                                                <div className="label-description-container">
                                                    <label htmlFor={`whole_day_${row.id || row.uuid}`} className={`checkbox-label ${rowIsDone ? 'disabled' : ''}`}>
                                                        {t('job.schedule_job.whole_day_label')}
                                                    </label>
                                                </div>
                                                <div className='checkbox-wrapper'>
                                                    <input 
                                                        type='checkbox'
                                                        id={`whole_day_${row.id || row.uuid}`}
                                                        checked={row.whole_day || undefined}
                                                        disabled={row.status === 'done'}
                                                        onChange={(event) => handleRowCheckboxChange(event, 'whole_day', row.id?.toString() || row.uuid || '')}
                                                        className='hidden-checkbox' />
                                                    <label htmlFor={`whole_day_${row.id || row.uuid}`}
                                                        className={`custom-checkbox left ${row.whole_day === true ? 'checked' : ''} ${rowIsDone ? 'disabled' : ''}`}>
                                                        {row.whole_day === true && <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {errorMessages['start_time'] || errorMessages['end_time'] && (
                                    <div className='error-message'>
                                        'Validation'
                                    </div>
                                )}
                                {scheduleTimeBlocks === true && (
                                    // Only schedule on a time block when the setting is on
                                    <div className='timeblock-row'>
                                        <div>
                                            <label>
                                                {t('job.schedule_job.time_block_label')}
                                            </label>
                                            <Dropdown<TimeBlockType>
                                                options={timeBlocks}
                                                id={`time_block_${identifier}`}
                                                name={`time_block_${identifier}`}
                                                disabled_selected={t('job.schedule_job.time_block_disabled_selected')}
                                                selectedOption={timeBlocks.find(timeBlock => timeBlock.id === row.time_block)}
                                                onChange={(selectedValue) => handleDropdownChange(selectedValue, 'time_block', row.id?.toString() || row.uuid || '')}
                                                selectionFormat={(option) => `${option.name}`}
                                                optionFormat={(option) => `${option.name}`}
                                                allowNoneOption={true} 
                                                showSearch={false}
                                                disabled={row.status === 'done'}
                                            />
                                        </div>
                                    </div> 
                                )}         
                                <div className='employee-row'>
                                    <label>
                                        {t('job.schedule_job.assignees_label')}
                                    </label>
                                    <MultiSelect<UserType> 
                                        options={assignees}
                                        id={`assignees_${identifier}`}
                                        name={`assignees_${identifier}`}
                                        disabled_selected={t('job.schedule_job.assignees_disabled_selected')}
                                        selectedOption={row.assignees || []}
                                        allowNoneOption={false}
                                        showSearch={true}
                                        selectionFormat={(option) => `${option.full_name}`}
                                        optionFormat={(option) => `${option.full_name}`}
                                        onChange={(selectedValue) => handleMultiSelectChange(selectedValue, 'assignees', row.id?.toString() || row.uuid || '')}
                                        disabled={row.status === 'done'}
                                    />
                                </div>
                                <div className='status-row'>
                                {row.status === 'done' ? (
                                    // Only show (disabled) status dropdown for done appointments
                                    <>
                                        <label>
                                            {t('job.schedule_job.status_label')}
                                            <span className="tooltip-icon">
                                                <FontAwesomeIcon icon={faCircleQuestion} />
                                                <span className="tooltip">{t('job.schedule_job.status_done_tooltip')}</span>
                                            </span>
                                        </label>
                                        <Dropdown<{ value: string, name: string }>
                                            options={appointmentStates}
                                            id={`time_block_${identifier}`}
                                            name={`time_block_${identifier}`}
                                            disabled_selected={t('job.schedule_job.status_disabled_selected')}
                                            selectedOption={appointmentStates.find(appointmentStatus => appointmentStatus.value === row.status)}
                                            onChange={(selectedValue) => handleDropdownChange(selectedValue, 'status', row.id?.toString() || row.uuid || '')}
                                            selectionFormat={(option) => `${option.name}`}
                                            optionFormat={(option) => `${option.name}`}
                                            allowNoneOption={false} 
                                            showSearch={false}
                                            disabled={row.status === 'done'}
                                        />
                                    </>
                                ) : (
                                    // Show reserved checkbox for appointments which are not done
                                    <div className='checkbox-item left'>
                                        <div className="label-description-container">
                                            <label htmlFor={`status_${row.id || row.uuid}`} className='checkbox-label'>
                                                {t('job.schedule_job.schedule_reserved_label')}
                                                <span className="tooltip-icon">
                                                    <FontAwesomeIcon icon={faCircleQuestion} />
                                                    <span className="tooltip">{t('job.schedule_job.schedule_reserved_tooltip')}</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className='checkbox-wrapper'>
                                            <input 
                                                type='checkbox'
                                                id={`status_${row.id || row.uuid}`}
                                                checked={row.status === 'reserved' || undefined}
                                                onChange={(event) => handleRowCheckboxChange(event, 'status', row.id?.toString() || row.uuid || '')}
                                                className='hidden-checkbox' />
                                            <label htmlFor={`status_${row.id || row.uuid}`}
                                                className={`custom-checkbox left ${row.status === 'reserved' ? 'checked' : ''}`}>
                                                {row.status === 'reserved' && <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                            <div className='separator'></div>
                            <div className='buttons'>
                                {row.status !== 'done' && (
                                    // Don't show buttons on done appointments
                                    <>
                                        <SecondaryButton
                                            label='job.schedule_job.pick_from_calendar_button_label'
                                            onClick={() => {/* Implement logic to pick date from calendar */}}
                                            size='large' />
                                        <IconButton
                                            tooltipText='job.schedule_job.delete_appointment_tooltip'
                                            onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')}
                                            size='small'
                                            icon={<FontAwesomeIcon icon={faTrash} />} />
                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
                { (rows.filter(row => !row.deleted).length === 0 || scheduleMultipleAppointments === 'multiple') && (
                    // Only allow to add new appointments based on the job appointment setting
                    <div className='add-appointment' onClick={handleAddRow}>
                        {t('job.schedule_job.another_appointment_label')}
                    </div>
                )}
                <div className='footer'>
                    <div className='checkbox-item left'>
                        <div className="label-description-container">
                            <label htmlFor='confirmation-email' className='checkbox-label'>
                                {t('job.schedule_job.confirmation_email_label')}
                            </label>
                        </div>
                        <div className='checkbox-wrapper'>
                            <input 
                                type='checkbox'
                                id='confirmation-email'
                                checked={emailCheckboxValue}
                                onChange={(event) => handleCheckboxChange(event)}
                                className='hidden-checkbox'
                            />
                            <label htmlFor='confirmation-email'
                                className={`custom-checkbox left ${emailCheckboxValue === true ? 'checked' : ''}`}>
                                {emailCheckboxValue === true && 
                                    <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                                }
                            </label>
                        </div>
                    </div>
                    <div className='buttons-right'>
                        <SecondaryButton
                            onClick={closeModal} 
                            label="general.cancel"
                            size="small"/>
                        <PrimaryButton
                            onClick={handleSubmit}
                            label="general.save"
                            size="small"
                            loading={buttonLoader}/>
                    </div>
                </div>
            </div>
        </FormFieldContext.Provider>
    );
};

export default ScheduleJobModal;