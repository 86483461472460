import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const JobSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Afspraakmethode',
            explanationText: 'Kies of opdrachten meerdere afspraken kunnen bevatten, bijvoorbeeld als je alle afspraken bij één opdracht wil groeperen, of dat je voor elke afspraak een aparte werkbon aanmaakt.',
            fields: [
                {
                    type: 'tabs',
                    name: 'job_appointment_method',
                    buttonSize: 'large',
                    options: [
                        { value: 'single', name: 'Losse werkbonnen' },
                        { value: 'multiple', name: 'Meerdere afspraken', requiredFeature: 'job_multiple_appointments' },
                    ]
                }
            ]
        },
        {
            title: 'Voorkeurs afrekenmethode',
            explanationText: 'Bepaal de voorkeurs afrekenmethode voor de opdrachten die je aanmaakt.',
            fields: [
                {
                    type: 'dropdown',
                    name: 'job_default_billing_type',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'calculation', name: 'Nacalculatie / Regie' },
                        { value: 'fixed', name: 'Vaste prijs' },
                        { value: 'non_billing', name: 'Interne rekening' }
                    ]
                }
            ]
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default JobSettings;