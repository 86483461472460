import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useFieldsetChangesTracker } from './functions/fieldsetChangesTracker';
import FormFieldContext from '../FormFieldContext';
import { FieldData, AttachmentsFieldsetType } from 'types/FieldTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'components/modals/ModalContext';
import FieldWrapper from '../FieldWrapper';
import Dropdown from '../basefields/Dropdown';
import AttachmentViewer from './AttachmentViewer';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { AlertType } from 'types/AlertTypes';
import { saveData } from 'services/api/saveData';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

export interface AttachmentType {
    id: number;
    file: string;
    filename: string;
    attachment_type: number | null;
    job: number | null;
    visible_field: boolean;
    visible_client: boolean;
    deleted: boolean;
}

export interface AttachmentTypeType {
    id: number;
    name: string;
    deleted: boolean;
}

const AttachmentsFieldset: React.FC<AttachmentsFieldsetType & { data: FieldData, viewKey: string }> = ({ 
    name, label, type, data, jobId, helperText, tooltipText, dropdownData, dropzoneText, 
    viewInEditMode, alignment, disabled = false, viewKey, isEditable
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { setUnsavedChanges, setFloatingAlert } = useGlobalContext();
    const { checkUnsavedChanges } = useFieldsetChangesTracker<AttachmentType>(data[name], viewKey, setUnsavedChanges);
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const hasRightCheck = useAllowedRight;
    const [attachments, setAttachments] = useState<AttachmentType[]>([]);
    const [attachmentTypes, setAttachmentTypes] = useState<AttachmentTypeType[]>([])
    const [dragOver, setDragOver] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showAlert, setShowAlert] = useState<AlertType>();    
    const fileInputRef = useRef<HTMLInputElement>(null);
    const acceptedFileTypes = ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.png', '.jpeg', '.jpg'];

    // TODO: Show file in document viewer in modal
    // TODO: Websocket to receive newly added attachments instantly
    

    // Later als ik energie heb?
    // TODO: File name editable in edit mode (split from extension)
    // TODO: Prevent users to get attachments from outside of their white label client
    // TODO: Fix visibility buttons (hoeft niet in los component. Idee is als je de labels aanklikt, dat het dan vet oranje wordt)

    // Set the current attachments from the fetched data
    useEffect(() => {
        if (data && name && data[name] && data[name].length > 0) {
            const mappedAttachments = data[name].map((attachment: AttachmentType) => ({
                id: attachment.id,
                file: attachment.file,
                filename: attachment.filename,
                attachment_type: attachment.attachment_type,
                visible_field: attachment.visible_field,
                visible_client: attachment.visible_client,
                deleted: attachment.deleted
            }))
            setAttachments(mappedAttachments);
        }
    }, [data]);

    // Set the attachment types from the dropdown data
    useEffect(() => {
        setAttachmentTypes(dropdownData?.attachmenttype?.results || []);
    }, [dropdownData])

    // Notice the dragging of files across the entire screen
    useEffect(() => {
        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(true);
        };

        const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);
        };

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);
            const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
            uploadFile(file);
        };

        // Add and clean event listeners
        document.addEventListener('dragover', handleDragOver as any);
        document.addEventListener('dragleave', handleDragLeave as any);
        document.addEventListener('drop', handleDrop as any);
        return () => {
            document.removeEventListener('dragover', handleDragOver as any);
            document.removeEventListener('dragleave', handleDragLeave as any);
            document.removeEventListener('drop', handleDrop as any);
        };
    }, []);

    // Show the file selector window when clicking on the dropzone
    const handleFileSelectClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle image upload when selecting a file through the dropzone selector window
    const handleAttachmentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        uploadFile(file);
    };

    // Upload the selected or dragged file
    const uploadFile = async(file: File | null) => {
        // Validate if a file is selected
        if (!file) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.no_file_selected')})
            return;
        }
    
        // Validate if the selected file is in the accepted file types
        const fileExtension = '.' + file.name.split('.').pop();
        if (!acceptedFileTypes.includes(fileExtension.toLowerCase())) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.unsupported_file_type')});
            return;
        }

        // Try upload new file
        try {
            setProgressBar(true);

            const newFile = new FormData();
            newFile.append('filename', file.name);
            newFile.append('file', file);
            newFile.append('visible_field', 'true');
            if (jobId) newFile.append('job', jobId.toString());

            await saveData({ apiUrl: 'post_attachment', method: 'post', data: newFile });

            setProgressBar(false);
        } catch (error) {
            console.error('Error during file upload:', error);
            setShowAlert({ type: 'danger', message: t('validation.attachment.file_upload_error')});
        }
    };

    // Handle viewing of an attachment
    const handleAttachmentView = (event: React.MouseEvent, attachment: AttachmentType) => {
        // Prevent edit-view mode toggling
        event.preventDefault();
        event.stopPropagation();

        // Open the attachment view modal
        initializeModal(<AttachmentViewer file={attachment.file} filename={attachment.filename} type='attachment' />, { modalSize: 'medium-large' } )
    }

    // Handle attachment type change
    const handleAttachmentTypeChange = (selectedValue: string, identifier: string) => {
        // Copy the attachments and find the item index
        const updatedAttachments = [...attachments];
        const itemIndex = updatedAttachments.findIndex(item => item.id?.toString() === identifier);

        if (itemIndex !== -1) {
            // Check and mark unsaved changes
            checkUnsavedChanges('attachment_type', parseFloat(selectedValue), identifier);

            // Set the selected value as 
            updatedAttachments[itemIndex].attachment_type = parseFloat(selectedValue)

            // Update the attachments
            setAttachments(updatedAttachments);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedAttachments }));
        }
    };

    // Handle deletion of an attachment
    const handleDeleteAttachment = (identifier: string) => {
        setUnsavedChanges(viewKey, true);

        const updatedAttachments = [...attachments];
        const itemIndex = updatedAttachments.findIndex(item => item.id?.toString() === identifier);

        if (itemIndex !== -1) {
            // Mark the item as deleted without actually deleting it
            updatedAttachments[itemIndex].deleted = true;
    
            // Update the attachments
            setAttachments(updatedAttachments);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedAttachments }));
        } 
    }

    return (
        <FieldWrapper
            name={name}
            type={type}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? ( 
                <div className='attachment-list'>
                    {attachments?.filter(attachment => !attachment.deleted).map((attachment, index) => (
                        <div className='attachment'>
                            <div className='icon'>
                                <FontAwesomeIcon icon={faPaperclip} />
                            </div>
                            <div className='name'
                                  onClick={e => handleAttachmentView(e, attachment)}>
                                <span>{attachment.filename}</span>
                            </div>
                            <div className='visibility'>
                                {/* Binnendienst | Buitendienst | Klant */}
                                <Dropdown<AttachmentTypeType>
                                    options={attachmentTypes}
                                    id={`attachment_type_${index}`}
                                    name={`attachment_type_${index}`}
                                    disabled_selected={t('job.attachments.attachment_type_placeholder')}
                                    selectedOption={attachmentTypes.find(attachmentType => attachmentType.id === attachment.attachment_type)}
                                    value={attachment.attachment_type}
                                    onChange={(selectedValue) => handleAttachmentTypeChange(selectedValue, attachment.id.toString())}
                                    selectionFormat={(option) => `${option.name}`}
                                    optionFormat={(option) => `${option.name}`}
                                    showSearch={false}
                                    allowNoneOption={true}                            
                                />
                            </div>
                            <div>
                                <Dropdown<AttachmentTypeType>
                                    options={attachmentTypes}
                                    id={`attachment_type_${index}`}
                                    name={`attachment_type_${index}`}
                                    disabled_selected={t('job.attachments.attachment_type_placeholder')}
                                    selectedOption={attachmentTypes.find(attachmentType => attachmentType.id === attachment.attachment_type)}
                                    value={attachment.attachment_type}
                                    onChange={(selectedValue) => handleAttachmentTypeChange(selectedValue, attachment.id.toString())}
                                    selectionFormat={(option) => `${option.name}`}
                                    optionFormat={(option) => `${option.name}`}
                                    showSearch={false}
                                    allowNoneOption={true}                            
                                />
                            </div>
                            
                            <div className='attachment-delete'>
                                {hasRightCheck('can_delete') &&
                                    <div className='delete-icon tooltip-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => handleDeleteAttachment(attachment.id.toString())} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                    {showAlert && (
                        <div className={`alert form-alert alert-${showAlert.type}`} role="alert">
                            {t(showAlert.message)}
                        </div>   
                    )}
                    <div className={`dropzone ${dragOver ? 'hover' : ''}`}
                         onClick={handleFileSelectClick}>
                        <input type='file'
                               onChange={handleAttachmentUpload} 
                               accept={acceptedFileTypes.join(',')}
                               ref={fileInputRef} />
                        <span onClick={handleFileSelectClick}>
                            {t(dropzoneText)}
                        </span>
                    </div>
                </div>
            ) : (
                // View mode
                dragOver ? (
                    // Show dropzone on drag over in view mode
                    <div className={`dropzone ${dragOver ? 'hover' : ''}`}
                         onClick={handleFileSelectClick}>
                        <input type='file'
                               onChange={handleAttachmentUpload} 
                               accept={acceptedFileTypes.join(',')}
                               ref={fileInputRef} />
                        <span onClick={handleFileSelectClick}>
                            {t(dropzoneText)}
                        </span>
                    </div>
                ) : (
                    <div className='view-mode-wrapper'>
                        {attachments && attachments.length > 0 ? (
                            <div className='attachment-list'>
                                {attachments.map((attachment, index) => {
                                    return (
                                        // Show attachment list in view mode
                                        <div className='attachment' key={index}>
                                            <span className='attachment-icon'>
                                                <FontAwesomeIcon icon={faPaperclip} />
                                            </span>
                                            <span className='attachment-name'
                                                  onClick={e => handleAttachmentView(e, attachment)}>
                                                {attachment.filename}
                                            </span>
                                            {attachment.attachment_type && 
                                                <span className='attachment-type'>
                                                    ({attachment.attachment_type}) 
                                                </span>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            // If no attachments, show '+ add attachments' label
                            <>
                                <span className="no-value">
                                    <span className="empty-label">{t('job.general.add_attachments_emptyLabel')}</span>
                                </span>
                            </>
                        )}
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                )
            )}
        </FieldWrapper>
    )
};

export default AttachmentsFieldset;