import React, { useContext, useState, useEffect, useRef } from 'react';
import { TextareaFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import ReactQuill from 'react-quill';
import CustomQuillEditor from './CustomQuillEditor';
import { convertTabsToSpaces } from 'services/utils/convertTextareaData';
import FieldViewMode from './elements/FieldViewMode';
import FieldWrapper from '../FieldWrapper';
import 'react-quill/dist/quill.snow.css';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';
import '../../../style/scss/quill-customized.scss';

interface TextareaFieldProps extends TextareaFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const TextareaField: React.FC<TextareaFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, helperText, tooltipText, placeholder, onChange, viewInEditMode, shouldAutoFocus, 
    rows = 2, isNote, enableStyling, alignment, disabled, emptyLabel, showToolbarInitially, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const quillRef = useRef<ReactQuill>(null);
    const textareaLineHeight = 1.2;
    const textareaPadding = 0.4;
    const baseFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    const [isFocused, setIsFocused] = useState(false);

    // Updates the value when the data is later received then the initialization of the useState
    useEffect(() => {
        if (data && data[name] !== undefined && data[name] !== value) {
            setValue(convertTabsToSpaces(data[name]))
        }
    }, [data, name]);

    // Calculates the textarea height based on the given rows
    const setTextAreaHeight = (elem: HTMLElement) => {
        // Calculate the default min height of 2 rows (or more if given) in rem
        const minHeight = (rows * textareaLineHeight) + (2 * textareaPadding);

        // Calculate the default height in pixels
        const minHeightPx = minHeight * baseFontSize;

        // Set the default height to the calculated min height
        elem.style.minHeight = `${minHeight}rem`;

        if (elem.scrollHeight > minHeightPx) {
            elem.style.height = `${elem.scrollHeight / baseFontSize}rem`;
        }
    };

    // Sets and grows the height of the field
    useEffect(() => {
        // For the normal textarea
        if (textareaRef.current) {
            setTextAreaHeight(textareaRef.current);
        }

        // For the Quill styled textarea editor
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const editorElem = editor.root as HTMLElement;
            setTextAreaHeight(editorElem);
        }
    }, [value, rows]);

    // Makes the text area focused after one click when its in view in edit mode 
    useEffect(() => {
        if (editing && viewInEditMode) {
            if (enableStyling && quillRef.current) {
                const editor = quillRef.current.getEditor();
                editor.focus();
            } else if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
    }, [editing, viewInEditMode, enableStyling]);

    // Updates the value after changing the input in the normal textarea
    const handleNormalTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
        setUnsavedChanges(viewKey, e.target.value !== data[name]);
        setUpdatedData({...updatedData, [name]: e.target.value});
    };

    // Updates the value after changing the input in the Quill styled textarea editor
    const handleStyledTextAreaChange = (value: string) => {
        setValue(value);
        setUnsavedChanges(viewKey, value !== data[name]);
        setUpdatedData({...updatedData, [name]: value})
    }

    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    // Format the field value
    const fieldValue = value // To be implemented for html textareas: <p dangerouslySetInnerHTML={{ __html: value }} />

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                enableStyling ? (
                    <CustomQuillEditor 
                        ref={quillRef}
                        value={value}
                        enableStyling={enableStyling}
                        showToolbarInitially={showToolbarInitially}
                        onChange={handleStyledTextAreaChange}
                        placeholder={placeholder}
                        className={`${errorMessage ? 'is-invalid' : ''} ${isNote ? 'note-field' : ''} ${isFocused ? 'focused' : ''}`}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                    />
                ) : (
                    <textarea
                        ref={textareaRef}
                        id={name}
                        name={name}
                        placeholder={t(placeholder)}
                        value={value}
                        onChange={handleNormalTextAreaChange}
                        className={`${errorMessage ? 'is-invalid' : ''} ${isNote ? 'note-field' : ''}`}
                        autoFocus={shouldAutoFocus}
                    />
                )
            ) : (
                // View mode
                <FieldViewMode
                    value={fieldValue} 
                    type='textarea'
                    alignment={alignment}
                    emptyLabel={emptyLabel}
                />
            )}
        </FieldWrapper>
    );
};

export default TextareaField;