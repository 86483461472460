import React, { useState, useContext, useEffect } from 'react';
import { FieldData, TimeRowsFieldsetType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { useFieldsetChangesTracker } from './functions/fieldsetChangesTracker';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TimeRowType, TimeTypeType, createNewTimeRow } from '../../../views/timesheets/TimesheetTypes';
import { HourlyRateType } from '../../../views/products/ProductTypes';
import { UserType } from '../../../views/users/UserTypes';
import { v4 as uuidv4 } from 'uuid';
import DateInput from '../basefields/DateInput';
import Dropdown from '../basefields/Dropdown';
import { parseDurationFromAmount } from 'services/utils/parseDurationFromAmount';
import TimeInput from '../basefields/TimeInput';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const TimeRowsFieldset: React.FC<TimeRowsFieldsetType & { data: FieldData, viewKey: string }> = ({
    viewKey, name, jobId, data, dropdownData, helperText, amountOnly = true, showPriceFields, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const { userLocale, defaultCurrency, defaultCurrencySymbol } = useSettings();
    const { checkUnsavedChanges } = useFieldsetChangesTracker<TimeRowType>(data[name], viewKey, setUnsavedChanges);
    const hasRightCheck = useAllowedRight;
    const [timeRows, setTimeRows] = useState<TimeRowType[]>([]);
    const [timeTypes, setTimeTypes] = useState<TimeTypeType[]>([]);
    const [assignees, setAssignees] = useState<UserType[]>([]);
    const [hourlyRates, setHourlyRates] = useState<HourlyRateType[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // TODO:
    // - Rate field for timerows --> maybe linked to time types ?
    // - Fix date saving --> convert end_time + start_date into datetime
    // - Save timezone with the timerow

    // If there are timerows included in the fetched data, show them. Otherwise show an empty row
    useEffect(() => {
        if (data && data[name] && data[name].length > 0) {
            // Sort the timerows on date chronological
            const sortedItems = (data[name] || []).sort((a: any, b: any) => {
                // Extract date and start_date, prioritize start_date if both exist
                const aDate = a.start_date || a.date;
                const bDate = b.start_date || b.date;
    
                // Convert to date objects for comparison, handling null values by setting them to a default far future date
                const dateA = aDate ? new Date(aDate) : new Date('9999-12-31');
                const dateB = bDate ? new Date(bDate) : new Date('9999-12-31');
    
                return dateA.getTime() - dateB.getTime();
            }).map((row: any) => {
                // If the timerows should display the amount only, convert duration to amount string
                let amount_string = null;
                if (amountOnly && row.duration !== null && row.duration !== undefined) {
                    const duration = parseFloat(row.duration);

                    if (duration % 1 === 0) {
                        // If it's a whole number, show without decimal places
                        amount_string = duration.toString();
                    } else {
                        // Otherwise, format with up to 2 decimal places
                        amount_string = duration
                            .toLocaleString(userLocale, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })
                            .replace('.', ',');
                    }
                }

                return {
                    ...row,
                    amount_string: amount_string,
                    total_price: data[name].total_price || 0,
                };
            });
            setTimeRows(sortedItems)
        } else {
            const newTimeRow = createNewTimeRow({ jobId: jobId, uuid: uuidv4() });
            setTimeRows([newTimeRow]);
        }
    }, [data, jobId]);

    // Set the time types and assignees from the dropdown data
    useEffect(() => {
        setTimeTypes(dropdownData?.timetype?.results || []);
        setAssignees(dropdownData?.user?.results || []);

        // Set the hourly rates but convert the rate
        setHourlyRates(dropdownData?.hourlyrate?.results || [])
            // (dropdownData?.hourlyrate?.results || []).map(rateObj => ({
            //     ...rateObj,
            //     currency_rate_description: Number(rateObj.rate).toLocaleString(userLocale, { 
            //         minimumFractionDigits: 2, 
            //         maximumFractionDigits: 2 
            //     })}
            // ));
    }, [dropdownData]);

    // Handle the amount change
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the time rows and find the item index
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Convert the amount string into a decimal value
            const duration = parseDurationFromAmount(event.target.value)

            // Check and mark unsaved changes
            checkUnsavedChanges('duration', duration, identifier);

            // Set the duration in the updated time rows
            updatedTimeRows[itemIndex].amount_string = event.target.value;
            updatedTimeRows[itemIndex].duration = duration;

            // Calculate and change the total price, only if a hourly rate is set
            const hourlyRate = hourlyRates.find(rate => rate.id === updatedTimeRows[itemIndex].hourly_rate);
            if (duration && hourlyRate && hourlyRate.rate !== null && !isNaN(Number(hourlyRate.rate))) {
                updatedTimeRows[itemIndex].total_price = duration * Number(hourlyRate.rate);
            }
            
            // Update the time rows and updated data with sale price as float
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }        
    };

    const handleDateChange = (selectedDate: string | null, identifier: string) => {
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Check and mark unsaved changes
            checkUnsavedChanges('date', selectedDate, identifier);

            // Set the selected value as 
            updatedTimeRows[itemIndex].date = selectedDate;

            // Update the time rows and updated data with sale price as float
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }
    }

    const handleHourlyRateChange = (selectedValue: string, identifier: string) => {
        // Copy the time rows and find the item index
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Check and mark unsaved changes
            checkUnsavedChanges('hourly_rate', parseFloat(selectedValue), identifier);

            // Set the selected value as 
            updatedTimeRows[itemIndex]['hourly_rate'] = parseFloat(selectedValue)

            // Calculate and change the total price, only if a hourly rate is set
            const currentRow = updatedTimeRows[itemIndex];
            const hourlyRate = hourlyRates.find(rate => rate.id === currentRow.hourly_rate);
            if (hourlyRate && hourlyRate.rate !== null && currentRow.duration !== null) {
                currentRow.total_price = currentRow.duration * Number(hourlyRate.rate);
            }

            // Update the time rows and updated data
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }
    };

    const handleDropdownChange = (selectedValue: string, fieldName: 'time_type' | 'assignee', identifier: string) => {
        // Copy the time rows and find the item index
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Check and mark unsaved changes
            checkUnsavedChanges(fieldName, parseFloat(selectedValue), identifier);

            // Set the selected value as 
            updatedTimeRows[itemIndex][fieldName] = parseFloat(selectedValue)

            // Update the time rows and updated data
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }
    };
    
    // Handle the creation of a new row
    const handleAddRow = () => {
        setUnsavedChanges(viewKey, true);

        // Create a new time row
        const newTimeRow = createNewTimeRow({ jobId: jobId, uuid: uuidv4() });
    
        // Add the row to the list of timerows
        const updatedTimeRows = [...timeRows, newTimeRow];
    
        // Update the time rows and updated data with sale price as float
        setTimeRows(updatedTimeRows);
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        setUnsavedChanges(viewKey, true);

        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Mark the item as deleted without actually deleting it
            updatedTimeRows[itemIndex].deleted = true;
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedTimeRows.filter(item => !item.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((item, index) => ({
                ...item,
                position: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedTimeRows.filter(item => item.deleted)];
    
            setTimeRows(finalItems);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalItems }))
        } 
    }

    return (
        <div className='widget-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {timeRows.length > 0 && (
                        <div className={`rows-header timerows-row ${amountOnly ? 'amount-only' : ''}`}>
                            <div className='header-item'>{t('job.timerows.time_type_label')}</div>
                            {amountOnly ? (
                                <>
                                    <div className='header-item'>{t('job.timerows.date_label')}</div>
                                    <div className='header-item'>
                                        {t('job.timerows.amount_label')}
                                        <span className="tooltip-icon">
                                            <FontAwesomeIcon icon={faCircleQuestion} />
                                            <span className="tooltip tooltip-right">{t('Voer het aantal uren in in honderdtallen. Dus 7 uur en drie kwartier voer je in als 7,75.')}</span>
                                        </span>
                                    </div>
                                </>
                                ) : (
                                <>
                                    <div className='header-item'>{t('job.timerows.date_label')}</div>
                                    <div className='header-item'>{t('job.timerows.start_time_label')}</div>
                                    <div className='header-item'>{t('job.timerows.end_time_label')}</div>
                                    <div className='header-item'>{t('job.timerows.total_time_label')}</div>
                                </>
                            )}
                            <div className='header-item'>{t('job.timerows.assignee_label')}</div>
                            <div className='header-item'>{t('job.timerows.hourly_rate_label')}</div>
                            <div className='header-item'>{t('job.timerows.total_price_label')}</div>
                            <div className='delete-placeholder'></div>
                        </div>
                    )}
                    {timeRows && timeRows.filter(timeRow => timeRow.deleted === false).map((timeRow, index) => {
                        const identifier = timeRow.id?.toString() || timeRow.uuid;
                        
                        return (
                            <div className='list-item'>
                                <div className={`timerows-row ${amountOnly ? 'amount-only' : ''}`}>
                                    <Dropdown<TimeTypeType>
                                        options={timeTypes}
                                        id={`time_type_${identifier}`}
                                        name={`time_type_${identifier}`}
                                        disabled_selected={t('job.timerows.time_type_placeholder')}
                                        selectedOption={timeTypes.find(timeType => timeType.id === timeRow.time_type)}
                                        value={timeRow.time_type}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'time_type', timeRow.id?.toString() || timeRow.uuid || '')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        allowNoneOption={false} 
                                    />
                                    {amountOnly ? (
                                        <>
                                            <DateInput // When registrating amount only, the 'date' field is updated. No datetime is needed.
                                                id={`date_${identifier}`}
                                                name={`date_${identifier}`}
                                                value={timeRow.date ? new Date(timeRow.date) : undefined}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, timeRow.id?.toString() || timeRow.uuid || '')}
                                                placeholder='Date' 
                                            />
                                            <input 
                                                type="text"
                                                id={`amount_string_${identifier}`}
                                                name={`amount_string_${identifier}`}
                                                value={timeRow.amount_string ?? ''}
                                                onChange={event => handleAmountChange(event, identifier || '')}
                                                placeholder={t('job.timerows.amount_placeholder')}
                                                autoFocus={false}
                                                className={errorMessages[index]?.jobItem ? 'is-invalid' : ''} 
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <DateInput // On registrating duration, the 'start_date' field is updated.
                                                id={`start_date_${identifier}`}
                                                name={`start_date_${identifier}`}
                                                value={timeRow.start_date ? new Date(timeRow.start_date) : undefined}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, timeRow.id?.toString() || timeRow.uuid || '')}
                                                placeholder='Date' 
                                            />
                                            <TimeInput
                                                type='time'
                                                id={`start_time_${timeRow.id || timeRow.uuid}`}
                                                name={`start_time_${timeRow.id || timeRow.uuid}`}
                                                value={timeRow.start_time ?? undefined}
                                            //    onChange={(selectedTime) => handleTimeChange(selectedTime, timeRow.id?.toString() || timeRow.uuid || '')}
                                            />
                                            <TimeInput
                                                type='time'
                                                id={`end_time_${timeRow.id || timeRow.uuid}`}
                                                name={`end_time_${timeRow.id || timeRow.uuid}`}
                                                value={timeRow.start_time ?? undefined}
                                            //    onChange={(selectedTime) => handleTimeChange(selectedTime, timeRow.id?.toString() || timeRow.uuid || '')}
                                            />
                                            <div className='duration'>
                                                <p>{timeRow.duration}</p>
                                            </div>
                                        </>
                                    )}
                                    <Dropdown<UserType>
                                        options={assignees}
                                        id={`assignee_${identifier}`}
                                        name={`assignee_${identifier}`}
                                        disabled_selected={t('job.timerows.assignee_placeholder')}
                                        selectedOption={assignees.find(assignee => assignee.id === timeRow.assignee)}
                                        value={timeRow.assignee}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'assignee', timeRow.id?.toString() || timeRow.uuid || '')}
                                        selectionFormat={(option) => `${option.full_name}`}
                                        optionFormat={(option) => `${option.full_name}`}
                                        allowNoneOption={false}                            
                                    />
                                    <Dropdown<HourlyRateType>
                                        options={hourlyRates}
                                        id={`hourly_rate_${identifier}`}
                                        name={`hourly_rate_${identifier}`}
                                        disabled_selected={t('job.timerows.hourly_rate_placeholder')}
                                        selectedOption={hourlyRates.find(rate => rate.id === timeRow.hourly_rate)}
                                        value={timeRow.hourly_rate}
                                        onChange={(selectedValue) => handleHourlyRateChange(selectedValue, timeRow.id?.toString() || timeRow.uuid || '')}
                                        selectionFormat={(option) => `${option.rate}`}
                                        optionFormat={(option) => `${option.rate}`}
                                        allowNoneOption={false}                            
                                    />
                                    <div>
                                        <p>{defaultCurrencySymbol} {timeRow.total_price}</p>
                                    </div>
                                    <div className='delete-icon tooltip-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => handleDeleteRow(timeRow.id?.toString() || timeRow.uuid || '')} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                    </div>
                                </div>
                                {errorMessages[index]?.item && 
                                    <div className='error-message'>
                                        {errorMessages[index]?.item}
                                    </div>
                                }
                            </div>
                        )
                    })}
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                            {t('forms.add_new_item')}
                    </div>    
                    {helperText && 
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                </div>
            ) : (
                // View mode
                <div className={`full-width-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>
                    <div className="view-mode">
                        <span className='p'>
                            <div className='item-list'>
                                {/* 28-03-2024 - 10.00-10.30 (0:30) - Tim Timmer (Work time) - €32,50 */}
                                {timeRows.filter(timeRow => timeRow.id).map((timeRow) => {
                                    const identifier = timeRow.id?.toString() || timeRow.uuid;

                                    // Get the assignee of the time row
                                    const assignee = assignees.find(assignee => assignee.id === timeRow.assignee);

                                    // Get the time type of the time row
                                    const timeType = timeTypes.find(timeType => timeType.id === timeRow.time_type);

                                    // Format the total price field
                                    let formattedTotalPrice = '';
                                    if (timeRow.total_price) {
                                        formattedTotalPrice = timeRow.total_price.toLocaleString(userLocale, { style: 'currency', currency: defaultCurrency?.toUpperCase() });
                                    }
                                    
                                    return (
                                        <div key={identifier} className='item'>
                                            {amountOnly ? (
                                                // If time is tracked by amount only, show the date and amount string
                                                <>
                                                    <span>{timeRow.date} - </span>
                                                    <span>{timeRow.amount_string} uur - </span>
                                                </>
                                            ) : (
                                                // For else, show the start- and endtime and duration
                                                <></>
                                            )}
                                            {assignee && <span>{assignee.full_name} </span>}
                                            {timeType && <span>({timeType.name})</span>}
                                            {showPriceFields && timeRow.total_price && <span> - {formattedTotalPrice}</span>}
                                        </div>
                                    )
                                })}
                            </div>
                            {isEditable && !userHasOnlyViewRights && (
                                // Only show add item label when the object is editable
                                <div className='add-item'>
                                    {t('forms.add_item')}
                                </div>
                            )}
                        </span>
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                </div>
            )}
        </div>
    );
}

export default TimeRowsFieldset;