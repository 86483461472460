import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import JobForm from './JobForm';
import JobModal from './JobModal';
import { useTranslation } from 'react-i18next';

// Rename the name of the function:
const JobList: React.FC = () => {
    const { t } = useTranslation();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_job_list',
        defaultParams: { 'deleted': false },
        object: 'job'
    }
    const objectName = 'job.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const formModal = <JobForm />;
    const detailModal = <JobModal />;
    const onRowClick = 'detailModal'

    // Custom jsx to render the labels as badges
    const renderLabelBadges = (labels: { id: number, name: string, color: string }[]) => {
        return (
            <div className='badge-container'>
                {labels.map(label => (
                    <div key={label.id} className={`label-badge ${label.color}`}>
                        {label.name}
                    </div>
                ))}
            </div>
        );
    }

    // Custom jsx to render the status as badge
    const renderStatusBadge = (name: string, condition: string) => {
        return (
            <div className='badge-container'>
                <div className={`status-badge ${condition}`}>
                    {t(`workflow.default_status_names.${name}`)}
                </div>                        
            </div>
        );
    }

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '1.5fr', field: 'id', label: 'job.general.job_number_label' },
        { width: '6fr', field: 'client_name', label: 'job.general.client_label' },
        { width: '4fr', field: 'internal_reference', label: 'job.general.internal_reference_label' },
        { width: '4fr', field: 'labels', label: 'job.general.labels_label', render: renderLabelBadges },
        { width: '144px', field: 'status', label: 'job.general.status_label', render: (_, row) => renderStatusBadge(row.status_name, row.status_condition) }
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'job.general.all_jobs_filter_label',
            params: { 'deleted': false },
            default: true,
        },
        {
            group: 'job.general.status_condition_filter_group_label',
            filters: [
                {
                    id: 'job_created',
                    label: 'job.status_condition.job_created',
                    params: { 'status_condition': 'job_created' },
                },
                {
                    id: 'one_open_purchase',
                    label: 'job.status_condition.one_open_purchase',
                    params: { 'status_condition': 'one_open_purchase' },
                },
                {
                    id: 'all_purchases_delivered',
                    label: 'job.status_condition.all_purchases_delivered',
                    params: { 'status_condition': 'all_purchases_delivered' },
                },
                {
                    id: 'one_reserved_appointment',
                    label: 'job.status_condition.one_reserved_appointment',
                    params: { 'status_condition': 'one_reserved_appointment' },
                },
                {
                    id: 'one_scheduled_appointment',
                    label: 'job.status_condition.one_scheduled_appointment',
                    params: { 'status_condition': 'one_scheduled_appointment' },
                },
                {
                    id: 'running_travel_timer',
                    label: 'job.status_condition.running_travel_timer',
                    params: { 'status_condition': 'running_travel_timer' },
                },
                {
                    id: 'running_work_timer',
                    label: 'job.status_condition.running_work_timer',
                    params: { 'status_condition': 'running_work_timer' },
                },
                {
                    id: 'incomplete',
                    label: 'job.status_condition.incomplete',
                    params: { 'status_condition': 'incomplete' },
                },
                {
                    id: 'completed',
                    label: 'job.status_condition.completed',
                    params: { 'status_condition': 'completed' },
                },
                {
                    id: 'finished',
                    label: 'job.status_condition.finished',
                    params: { 'status_condition': 'finished' },
                },
            ]
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deleted',
                    label: 'job.general.deleted_jobs_label',
                    shortName: 'general.deleted',
                    params: { 'deleted': true },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            filters={filters}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            formModal={formModal}
            detailModal={detailModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default JobList;