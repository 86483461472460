import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import GenerateHeaderTitle from 'components/modals/functions/GenerateHeaderTitle';
import { useModal } from 'components/modals/ModalContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchDataAndOptionsOfDetailCards } from 'services/api/fetchFieldData';
import { DetailCardType } from 'types/DetailPageTypes';
import { FieldOption, FieldOptionFetchResponse, FieldType } from 'types/FieldTypes';
import WorkflowWizard, { WorkflowType } from './WorkflowWizard';
import { fetchData } from 'services/api/fetchData';
import { jobWorkflowConfig } from './jobWorkflowConfig';
import FormFieldContext from 'components/forms/FormFieldContext'
import '../../../style/scss/wizard.scss';

interface WizardStep {
    id?: number;
    key: number;
    name: string;
}

interface WizardConfig<T> {
    steps: WizardStep[];
    extraProps: T;
}

interface WizardModalProps<T> {
    viewKey?: string;
    itemId?: number | null;
    type?: 'workflow' | 'trial' | 'plan';
}

const WizardModal = <T,>({ viewKey, itemId, type }: WizardModalProps<T>) => {
    const { t } = useTranslation();
    const { modalStack, revealModal, closeModal } = useModal();
    const [data, setData] = useState(null);
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([]);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [finalUpdatedData, setFinalUpdatedData] = useState<Record<number, Record<string, any>>>({});
    const [editing, setEditing] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const topModal = modalStack[modalStack.length - 1];


    const [steps, setSteps] = useState<WizardStep[]>([{ key: 1, name: 'new' }]);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const currentStep = steps[currentStepIndex];

    // Fetch the wizard data on initial render
    useEffect(() => {
        const fetchWizardData = async () => {
            if (itemId) {
                const response = await fetchData({ apiObject: generateApiObject(), itemId });
                setData(response);

                if (response && type === 'workflow') {
                    const config = getConfigForWorkflowType(response.type);
                    if (config) {
                        setSteps(config.statuses.map((status, index) => ({ ...status, key: index + 1})));
                    }
                } 
            }
        }
        
        (async () => {
            try {
                await Promise.all([fetchWizardData()]);
            } finally {
                // Reveal the modal after loading
                revealModal();
            }
        })();
    }, []);

    useEffect(() => {
        if (data) console.log('data', data)
    }, [data])

    const getConfigForWorkflowType = (workflowType: string): WorkflowType | null => {
        switch (workflowType) {
            case 'job':
                return jobWorkflowConfig;
            // Voeg hier andere typen workflows toe
            default:
                return null;
        }
    };

    const generateApiObject = () => {
        if (type === 'workflow') {
            return 'workflow'
        }
    }

    // Generate the wizard title based on the wizard type. In case of the wizard type 'workflow', than the title is the name.
    const generateWizardTitle = () => {
        if (data) {

            if (type === 'workflow') {
                return `${data['name']} workflow`;
            }

            return 'Workflow';
        } else {
            return 'Workflow builder'
        }
    }

    // Determine the wizard content to render, in case of the wizard type 'workflow', render the WorkflowWizard, then map the right config based on the workflow type. If the workflow type is Job, load the jobWorkflowConfig. 
    const renderWizardContent = () => {
        if (data && viewKey) {
            if (type === 'workflow') {

                if (data['connected_feature'] === 'job') {
                    return <WorkflowWizard workflowConfig={jobWorkflowConfig} data={data} viewKey={viewKey} />
                }

                return null;
            }
            return null;
        }
    }

    useEffect(() => {
        if (updatedData) {
            const transformedData = Object.keys(updatedData).reduce((acc: Record<number, Record<string, any>>, key) => {
                const [field, stepKey] = key.split('_');
                const stepIndex = parseInt(stepKey, 10);

                if (!acc[stepIndex]) {
                    acc[stepIndex] = {};
                }

                acc[stepIndex][field] = updatedData[key];

                return acc;
            }, {});
            setFinalUpdatedData(transformedData);
        }
    }, [updatedData]);

    useEffect(() => {
        if (finalUpdatedData) console.log("updatedData per step", finalUpdatedData);
    }, [finalUpdatedData]);

    const handleNext = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    }

    const handlePrevious = () => {
        setCurrentStepIndex(currentStepIndex - 1);
    }

    const handleSubmit = async () => {

    }


    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData, 
            buttonLoader, setButtonLoader, showErrorAlert, setShowErrorAlert, handleSubmit }}>
            <div className='wizard-modal'>
                <div className='header'>
                    <div className='title'>
                        <h2>{generateWizardTitle()}</h2>
                    </div>
                </div>
                <div className='statusbar'>
                    {steps.map((step, index) => (
                        <div key={step.key} className={`step ${index === currentStepIndex ? 'active' : ''}`}>
                            {step.name}
                        </div>
                    ))}
                </div>
                <div className='content'>
                    {renderWizardContent()}
                </div>
                <div className='button-row'>
                    <div className='buttons-left'>
                        <SecondaryButton
                            onClick={() => closeModal()} 
                            label='general.close'
                            size="small" />
                        {currentStepIndex > 1 && currentStepIndex <= steps.length -1 && 
                            <SecondaryButton
                                onClick={handlePrevious} 
                                label='general.previous'
                                size="small" />
                        }
                    </div>
                    <div className='buttons-right'>
                        {currentStepIndex < steps.length - 1 ? 
                            <PrimaryButton
                                type="button" 
                                label='general.next'
                                onClick={handleNext}
                                size="small" /> 
                            : 
                            <PrimaryButton
                                type="submit" 
                                label='general.save'
                                size="small"
                                onlyViewRestriction={true}
                                loading={buttonLoader} />
                        }
                    </div>
                </div>
            </div>
        </FormFieldContext.Provider>
    )
}

export default WizardModal;