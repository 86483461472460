import React from 'react';
import { Button } from 'types/ButtonTypes';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/alerts/Tooltip';
import '../../style/scss/button.scss';
import '../../style/scss/tooltip.scss';

const IconButton: React.FC<Button> = ({ 
    onClick, icon, label, size = 'medium', isActive, tooltipText 
}) => {
    const { t } = useTranslation();

    // Set the size classes
    const buttonSizeClass = `button-${size}`;
    
    return (
        <div>
            <button onClick={onClick}
                    className={`button button-secondary icon-button ${buttonSizeClass} tooltip-hover ${isActive ? 'button-active' : ''}`}>
                {icon}
                {label && 
                    <span className='icon-button-label'>{label}</span>
                }
                {tooltipText &&
                    <Tooltip>{t(tooltipText)}</Tooltip>
                }
            </button>
        </div>
    );
};

export default IconButton;