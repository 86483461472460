import React, { useContext, useState, useEffect } from 'react';
import { AuthenticationContextProviderProps, AuthenticationContext } from 'services/authentication/AuthenticationTypes';
import { useGlobalContext } from 'GlobalContext';
import { setCsrfToken } from './csrfFunctions';
import { login } from './loginFunction';
import { checkSession } from './checkSession';
import { websocketBaseUrl } from 'App';
import { logout } from './logoutFunction';
import { Redirect, useLocation } from 'react-router-dom';
import { refreshStatesFromLocalStorage } from './refreshStates';
import { FeaturesPerPackage } from 'services/permissions/packageFeatures';
import { encryptData } from "./encryptData";
import { fetchData } from 'services/api/fetchData';
import { filterReleasedFeatures } from 'services/permissions/releasedFeatures';

/*
 * AuthenticationContext.tsx
 * This context is created to contain states and information about the 
 * authenticated user and environments, such as login state but also 
 * environment features. The context also handles the initialization
 * of the csrf token, handling of the login, active session check,
 * updates to these states by websocket and logout functions.
 */ 

export const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = ({ children }) => {
    const currentSession = localStorage.getItem('currentSession') ? true : null;
    const [statesLoaded, setStatesLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(currentSession);
    const [userHash, setUserHash] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [environmentHash, setEnvironmentHash] = useState<string>('');
    const [environmentPackage, setEnvironmentPackage] = useState<string>('');
    const [packageFeatures, setPackageFeatures] = useState<string[]>([]);
    const [activeFeatures, setActiveFeatures] = useState<string[]>([]);
    const [accessTo, setAccessTo] = useState<string[]>([]);
    const [allowedFeatures, setAllowedFeatures] = useState<string[]>([]);
    const [allowedRights, setAllowedRights] = useState<string[]>([]);
    const [redirectTo, setRedirectTo] = useState<'home' | 'loginpage' | null>(null); 
    const [pageReloaded, setPageReloaded] = useState(false);
    const { setFormAlert, setErrorMessages } = useGlobalContext();
    const location = useLocation()

    // Set a csrf token in the browser
    useEffect(() => {
        setCsrfToken();
    }, []);

    // Handle the login
    const handleLogin = async (
        email: string, password: string, navigator_language: string, navigator_locale: string, remember_login: boolean
    ): Promise<{status: string, error?: string}> => {
        const loginResult = await login({ email, password, navigator_language, navigator_locale, remember_login, callbacks: {
            setIsLoggedIn, setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage, 
            setPackageFeatures, setActiveFeatures, setAccessTo, setAllowedFeatures, setAllowedRights, setStatesLoaded
        }})

        if (loginResult.status === 'success') {
            setRedirectTo('home');
            setFormAlert(null);
            setErrorMessages({});
            return { status: 'success' };
        } else {
            throw new Error(loginResult.error)
        }
    }

    // Set session expired form alert
    const setSessionExpiredFormAlert = () => {
        setFormAlert({ 
            message: "alert.session_expired_message", 
            type: 'warning' 
        })
    }

    // Checks if the session is active on hard refresh
    useEffect(() => {
        if (currentSession === true) {
            const fetchSession = async () => {
                try {
                    await checkSession({ callbacks: { setIsLoggedIn } }, handleLogout, setSessionExpiredFormAlert);
                } catch (error: any) {
                    handleLogout();
                    setSessionExpiredFormAlert();
                }
            };
            fetchSession();
        }
    }, []);

    // Continiously renew allowed rights on page change when no_user flag is set
    useEffect(() => {
        if (allowedRights.includes('no_user')) {
            const fetchAllowedRights = async () => {
                try {
                    // Fetch the allowed rights
                    const fetchedRights = await fetchData({ apiUrl: 'refresh_allowed_rights' });

                    // Set the refreshed allowed rights in the context
                    setAllowedRights(fetchedRights);

                    // Encrypt the refreshed rights and put them in the localStorage
                    const encryptedRights = encryptData(JSON.stringify(fetchedRights));
                    localStorage.setItem('allowed_rights', encryptedRights)
                } catch (error: any) {
                    console.error(error);
                }
            };
            fetchAllowedRights();
        }
    }, [location])

    // Set page reloaded flag on page reload
    useEffect(() => {
        setPageReloaded(true);
    }, [])

    // Refresh states from local storage on hard page reload
    useEffect(() => {
        if (currentSession === true) {
            // Refresh the following states from the local storage
            const setStateFunctions = {
                setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage,
                setActiveFeatures, setAccessTo, setAllowedFeatures, setAllowedRights 
            };
            refreshStatesFromLocalStorage(setStateFunctions);

            if (pageReloaded) {
                // Refresh the package features from packageFeatures.ts
                const features = FeaturesPerPackage[environmentPackage as keyof typeof FeaturesPerPackage] || [];
                setPackageFeatures(features);
            }

            setStatesLoaded(true);
        }
    }, [setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage, setPackageFeatures, setActiveFeatures, 
        setAccessTo, setAllowedFeatures, setAllowedRights, pageReloaded])

    // Receive inactive changes of the session by websocket
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            if (userHash) {
                webSocket = new WebSocket(`${websocketBaseUrl}/sessionstatus/${userHash}/`);
    
                webSocket.onmessage = (event) => {
                    const message = JSON.parse(event.data);

                    // If session is expired, logout the user
                    if (message.type === 'SESSION_EXPIRED') {
                        console.log("Session expired, logging out");
                        handleLogout();
                        setSessionExpiredFormAlert();
                    }
                };
    
                webSocket.onclose = (event) => {
                    if (shouldReconnect) {
                        // Try to reconnect in 10 seconds
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            }
        };

        // Listen to the websocket when the check session flag is set
        if (currentSession != null) setupWebSocket();

        // Clear websocket by unmount
        return () => {
            shouldReconnect = false;
            if (webSocket) {
                webSocket.close();
            }
        };
    }, [currentSession, userHash, setSessionExpiredFormAlert]);

    // Receive package and features updates by websocket
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            if (environmentHash) {
                webSocket = new WebSocket(`${websocketBaseUrl}/features_update/${environmentHash}/`);
    
                webSocket.onmessage = (event) => {
                    const message = JSON.parse(event.data);

                    if (message.type === 'features_update') {
                        // Update the environment package state and put it encrypted in the local storage
                        setEnvironmentPackage(message.package)
                        const encryptedPackage = encryptData(message.package)
                        localStorage.setItem('package', encryptedPackage);

                        // Update the package included features from the hardcoded packageFeatures.ts list
                        const features = FeaturesPerPackage[message.package as keyof typeof FeaturesPerPackage] || [];
                        setPackageFeatures(features);

                        // Update the active features state and put it encrypted in the local storage
                        const availableFeatures = filterReleasedFeatures(message.active_features)
                        setActiveFeatures(availableFeatures)
                        const encryptedFeatures = encryptData(JSON.stringify(availableFeatures));
                        localStorage.setItem('active_features', encryptedFeatures);
                    }
                };

                webSocket.onclose = (event) => {
                    if (shouldReconnect) {
                        // Try to reconnect in 10 seconds
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            }
        };

        // Listen to the websocket when the check session flag is set
        if (currentSession != null) setupWebSocket();

        // Clear websocket by unmount
        shouldReconnect = false;
        return () => {
            if (webSocket) {
                webSocket.close();
            }
        };
    }, [currentSession, environmentHash, setEnvironmentPackage, setActiveFeatures]);

    // Receive role updates by websocket
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            if (userHash) {
                webSocket = new WebSocket(`${websocketBaseUrl}/role_update/${userHash}/`);

                webSocket.onmessage = (event) => {
                    const message = JSON.parse(event.data);

                    if (message.type === 'role_update') {
                        // Update the access to state and put it encrypted in the local storage
                        setAccessTo(message.access_to)
                        const encryptedAccessTo = encryptData(JSON.stringify(message.access_to));
                        localStorage.setItem('access_to', encryptedAccessTo);

                        // Update the allowed features state and put it encrypted in the local storage
                        const availableFeatures = filterReleasedFeatures(message.allowed_features)
                        setAllowedFeatures(availableFeatures)
                        const encryptedAllowedFeatures = encryptData(JSON.stringify(availableFeatures));
                        localStorage.setItem('allowed_features', encryptedAllowedFeatures);

                        // Update the allowed rights state and put it encrypted in the local storage
                        setAllowedRights(message.allowed_rights)
                        const encryptedAllowedRights = encryptData(JSON.stringify(message.allowed_rights));
                        localStorage.setItem('allowed_rights', encryptedAllowedRights);
                    }
                };
    
                webSocket.onclose = (event) => {
                    if (shouldReconnect) {
                        // Try to reconnect in 10 seconds
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            }
        };

        // Listen to the websocket when the check session flag is set
        if (currentSession != null) setupWebSocket();

        // Clear websocket by unmount
        shouldReconnect = false;
        return () => {
            if (webSocket) webSocket.close();
        };
    }, [currentSession, userHash, setAccessTo, setAllowedFeatures, setAllowedRights]);

    // Handle the logout (optionally serverside), delete the local storage items and redirect to the login page
    const handleLogout = async ({ logoutFromServer = false}: { logoutFromServer?: boolean } = {}) => {
        await logout({ 
            logoutFromServer, 
            callbacks: { setIsLoggedIn, setRedirectTo }
        });
    }

    // Redirect after login or logout
    const handleRedirect = () => {
        if (redirectTo === 'home') {
            return <Redirect to="/" />;
        }
        if (redirectTo === 'loginpage') {
            return <Redirect to="/login" />;
        }
        return null;
    }

    return (
        <AuthenticationContext.Provider 
            value={{ currentSession, statesLoaded, setStatesLoaded, isLoggedIn, setIsLoggedIn, userHash, setUserHash, firstName, 
                setFirstName, lastName, setLastName, environmentHash, setEnvironmentHash, environmentPackage, setEnvironmentPackage, 
                packageFeatures, setPackageFeatures, activeFeatures, setActiveFeatures, accessTo, setAccessTo, allowedFeatures, 
                setAllowedFeatures, allowedRights, setAllowedRights, handleLogin, handleLogout, handleRedirect }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

// Create a custom hook for the authentication context
export const useAuthContext = () => {
    return useContext(AuthenticationContext);
}