import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';

/*
 * saveData.tsx
 * General utility function that handles saving of data to the server. The function generates 
 * the api url based on if an itemId of the object is given. If it is given, it generates a 
 * put request to update the item, otherwise it generates a post request to create a new item. 
 * It also generates a cancel token that cancels requests from canceled submissions, which 
 * prevents unwanted server requests.
 */

interface SaveDataProps {
    apiObject?: string;
    apiUrl?: string;
    method?: 'put' | 'patch' | 'post';
    itemId?: string | number | null;
    data: Record<string, any>;
    source?: CancelTokenSource;
}

export async function saveData({ apiObject, apiUrl, method, itemId, data, source } : SaveDataProps) {

    // Retrieve the csrfToken
    const csrfToken = getCsrfToken();

    // Check if CSRF token is available
    if (!csrfToken) {
        console.error("CSRF token is not available, aborting the request.");
        return;
    }

    // Configure the method
    const saveMethod = method || (itemId ? 'patch' : 'post')

    // Configure the url
    let url: string;
    if (apiUrl) {
        url = `${apiBaseUrl}/${apiUrl}/`;
    } else if (saveMethod === 'post') {
        url = `${apiBaseUrl}/post_${apiObject}/`;
    } else {
        url = `${apiBaseUrl}/${saveMethod}_${apiObject}/${itemId}/`
    }

    // Configure the api request
    const apiRequestConfig = {
        method: saveMethod,
        url,
        data,
        withCredentials: true,
        headers: {
            'X-CSRFToken': csrfToken,
        },
        cancelToken: source?.token,
    };

    // Save the data to the server
    try {
        const response = await axios(apiRequestConfig);
        return response;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error('Saving not possible', error);
            throw error;
        }
    }
}