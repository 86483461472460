import React from 'react';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

/*
 * GenerateHeaderTitle.tsx
 * Generate the header title for detail modals based on the api 
 * object of the modal.
 */

interface HeaderTitleProps {
    apiObject: string;
    data: any;
}

const GenerateHeaderTitle: React.FC<HeaderTitleProps> = ({ apiObject, data }) => {
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    let headerTitle = null;

    // Generate the header for jobs
    if (apiObject === 'job') {
        // Header for a single job
		if (data['is_project'] === false) {
			headerTitle = <>{t('job.general.job_header', { number: t(data['id']) })}</>;
        }

        // Header for a sub job inside a project        
        if (data['is_subjob'] === true) {
			headerTitle = (
				<>
					{t('job.general.project_header', { number: t(data['parent_job'].number) })} <FontAwesomeIcon icon={faGreaterThan} /> {t('job.general.job_header', { number: t(data['id']) })}
				</>
			);

        // Header for a project
		} else if (data['is_project'] === true) {
			headerTitle = <>{t('job.general.project_header', { number: t(data['id']) })}</>;
		}

    // Generate the header for a proposal
	} else if (apiObject === 'proposal') {
		headerTitle = <>{t('proposal.general.proposal_header', { number: t(data['id']) })}</>;

    // Generate the header for an invoice
	} else if (apiObject === 'invoice') {
		headerTitle = <>{t('invoice.general.invoice_header', { number: t(data['id']) })}</>;
    }

    return (
        <>
            {headerTitle}
        </>
    );
};

export default GenerateHeaderTitle;