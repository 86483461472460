import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from 'components/modals/ModalContext';
import { useFetchData } from 'services/api/useFetchData';
import { useWebSocket } from 'services/api/useWebSocket';
import { saveData } from 'services/api/saveData';
import useJobInboxSpacerHeight from './functions/useJobInboxSpacerHeight';
import { generateWeekNumber } from 'services/utils/dateTimeUtils';
import { generateJobInboxWeeks } from './functions/generateJobInboxWeeks';
import { generateWeekKey, getFirstDateOfWeek, getLastDateOfWeek, initializeInboxJobsFetchParams, newTargetDateInCurrentOrFutureWeek } from './functions/jobInboxUtils';
import { JobType } from '../jobs/JobTypes';
import JobModal from '../jobs/JobModal';
import { formatDisplayDate } from 'internationalization/timezoneConversions';
import { formatAmount } from 'services/utils/amountFormatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faCircleQuestion, faGripLines } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/scheduling.scss'

interface JobInboxProps {
    inboxHeight: number;
    onLoaded: (loaded: boolean) => void;
    onDrag: (itemId: number, draggableKey: string) => void;
    onClose: () => void
}

interface DraggingItemType {
    itemId: number;
    draggableKey: string;
    dragOverKey?: string;
    placeholderHeight: number;
}

const JobInbox: React.FC<JobInboxProps> = ({ inboxHeight, onLoaded, onDrag, onClose }) => {
    const { t } = useTranslation();
    const { environmentHash } = useAuthContext();
    const { setFloatingAlert } = useGlobalContext();
    const { userLocale } = useSettings();
    const { initializeModal } = useModal();
    const [fetchParams, setFetchParams] = useState<{ from?: string, to?: string }>((initializeInboxJobsFetchParams));
    const { response: fetchedInboxJobs, loading: fetchInboxJobsLoading } = useFetchData({ apiUrl: 'get_job_inbox_list', params: fetchParams });
    const [isInitialFetch, setIsInitialFetch] = useState(true);
    const { message: inboxJobsWebsocket } = useWebSocket({ url: `inbox_jobs/${environmentHash}/` });
    const [visibleWeeks, setVisibleWeeks] = useState<string[]>([]);
    const [pastJobs, setPastJobs] = useState<JobType[]>([]);
    const [weekJobs, setWeekJobs] = useState<{ [week: string]: JobType[] }>({});
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [draggingItem, setDraggingItem] = useState<DraggingItemType | null>(null);
    const jobInboxRef = useRef<HTMLDivElement>(null);
    const spacerHeight = useJobInboxSpacerHeight(inboxHeight, visibleWeeks, weekJobs);
    const weekStartsOnSunday = false;
    const placeholderIndexRef = useRef<number | null>(null);

    // Show the first weeks from now on initial render
    useEffect(() => {
        setVisibleWeeks(generateJobInboxWeeks(20));
    }, [])

    console.log("pastJobs", pastJobs)
    console.log("weekJobs", weekJobs)

    // Set the fetched inbox jobs on page load
    useEffect(() => {
        if (fetchedInboxJobs && fetchedInboxJobs.results) {
            const pastJobsList: JobType[] = [...pastJobs];
            const futureJobs: { [week: string]: JobType[] } = {};

            fetchedInboxJobs.results.forEach((job: JobType) => {
                if (job.target_date) {
                    const targetDate = new Date(job.target_date);
                    const now = new Date();

                    // Add job to the past jobs list, if the target date is before today
                    if (targetDate < now) {
                        pastJobsList.push(job);

                    // Add job to the week lists, if the target date is in the future
                    } else {
                        // Generate the week key
                        const week = generateWeekNumber(targetDate, weekStartsOnSunday);
                        const year = targetDate.getFullYear();
                        const weekKey = `${year}-${week}`;

                        // Put the jobs in the week of the week key
                        if (!futureJobs[weekKey]) futureJobs[weekKey] = [];
                        futureJobs[weekKey].push(job);
                    }
                }
            });

            console.log("futurejobs", futureJobs)
    
            setPastJobs(pastJobsList);
            setWeekJobs(prev => ({ ...prev, ...futureJobs }));
            setVisibleWeeks(prev => Array.from(new Set([...prev, ...Object.keys(futureJobs)])));
            setIsInitialFetch(false);
        };
    }, [fetchedInboxJobs]);

    // If the data is successfully loaded on the initial fetch/render, set onLoaded to true
    useEffect(() => {
        if (isInitialFetch && fetchInboxJobsLoading === 'success') {
            onLoaded(true);
        } 
    }, [isInitialFetch, fetchInboxJobsLoading, onLoaded]);

    // Process received updates of inbox jobs by websocket
    useEffect(() => {
        const { type, message } = inboxJobsWebsocket || {};

        if (type === 'inbox_jobs_updated' ) {
            // Get the values from the message to use in this function
            const { id, target_date, status_condition, deleted, ...otherProps } = message;

            // Determine if the new target date is in the current or future weeks
            const targetDateInCurrentOrFutureWeek = newTargetDateInCurrentOrFutureWeek(target_date, weekStartsOnSunday)
            const newWeekKey = generateWeekKey(target_date, weekStartsOnSunday);

            // Update week jobs
            setWeekJobs(existingWeekJobs => {
                const updatedWeekJobs = { ...existingWeekJobs };

                // Remove the job from all existing weeks
                Object.keys(updatedWeekJobs).forEach(weekKey => {
                    updatedWeekJobs[weekKey] = updatedWeekJobs[weekKey].filter(job => job.id !== id);
                });

                // Add the job to the week that correspondents to the new target date
                if (!deleted && (status_condition === 'job_created' || status_condition === 'all_purchases_delivered') && targetDateInCurrentOrFutureWeek) {
                    if (!updatedWeekJobs[newWeekKey]) {
                        updatedWeekJobs[newWeekKey] = [];
                    }
                    updatedWeekJobs[newWeekKey].push({ id, target_date, status_condition, ...otherProps });
                }

                return updatedWeekJobs;
            });

            // Add the job to the past jobs if the target date is in a past week
            setPastJobs(currentPastJobs => {
                let updatedPastJobs = currentPastJobs.filter(job => job.id !== id);

                if (!deleted && (status_condition === 'job_created' || status_condition === 'all_purchases_delivered') && !targetDateInCurrentOrFutureWeek) {
                    updatedPastJobs.push({ id, target_date, status_condition, ...otherProps });
                }

                return updatedPastJobs;
            });
        }
    }, [inboxJobsWebsocket]);

    // Load more week rows when scrolling down
    useEffect(() => {
        const jobInboxElement = jobInboxRef.current;
        if (jobInboxElement == null) return;

        // Execute the loading when scrolling
        const handleScroll = () => {
            // Skip when dragging an inbox job, otherwise users may drag into new weeks which doesn't exist as destination yet
            if (isDragging) return;

            if (jobInboxElement && jobInboxElement.scrollTop + jobInboxElement.clientHeight >= jobInboxElement.scrollHeight - 10) {
                loadAdditionalInboxRows();
            };
        };
    
        // Add and remove event listener for scroll event
        jobInboxElement.addEventListener('scroll', handleScroll);
        return () => jobInboxElement.removeEventListener('scroll', handleScroll);
    }, [visibleWeeks, isDragging]);

    // Create and load additional weeks into the job inbox
    const loadAdditionalInboxRows = () => {
        // Determine the current last visible week
        const currentLastWeek = visibleWeeks[visibleWeeks.length - 1];

        // Generate the desired weeks from the current last week
        const additionalWeeksToCreate = 5
        const generatedAdditionalWeeks = generateJobInboxWeeks(additionalWeeksToCreate, currentLastWeek);

        // Add the generated additional weeks to the visible weeks 
        setVisibleWeeks(prev => Array.from(new Set([...prev, ...generatedAdditionalWeeks])));

        // Get the first and last date of the generated weeks
        const firstDate = getFirstDateOfWeek(generatedAdditionalWeeks);
        const lastDate = getLastDateOfWeek(generatedAdditionalWeeks);

        // Update the dates into the fetch params to trigger a new fetch
        setFetchParams({ from: firstDate, to: lastDate });
    };

    // Calculates the total estimated duration of all jobs within an inbox row
    const calculateRowDuration = (jobs: JobType[]): string | null => {
        if (!jobs || jobs.length === 0) {
            return null; 
        }

        const totalDuration = jobs.reduce((total, job) => total + (Number(job.estimated_duration) || 0), 0);
        return formatAmount(totalDuration, userLocale);
    };

    // Generate the week label to show in the week row
    const generateWeekLabel = (weekKey: string, previousWeekKey?: string, isCurrentWeek: boolean = false): string => {
        const [year, week] = weekKey.split('-').map(Number);

        // Determine if the week is the first week of the year
        const firstWeekOfYear = week === 1 || ((previousWeekKey && year !== parseInt(previousWeekKey.split('-')[0])));

        // In case of the first week of the year, show the year
        let weekLabel;
        if (isCurrentWeek) {
            weekLabel = `${t('date_time.general.current_week')}`;
        } else if (firstWeekOfYear) {
            weekLabel = `${t('date_time.general.week_short')} ${week} (${year})`;
        } else {
            weekLabel = `${t('date_time.general.week_short')} ${week}`;
        }

        return weekLabel;
    };

    // Handle start dragging of a job inbox item
    const handleDragStart = (e: React.DragEvent, draggableKey: string) => {
        // Set is dragging to true, to prevent loading extra weeks on dragging/scrolling down
        setIsDragging(true);

        // Set the draggable key (`inboxJob/jobId/weekJobsRow/weekKey`) into the data transfer
        e.dataTransfer.setData("text/plain", draggableKey);
        e.dataTransfer.effectAllowed = "move";

        // Get the dragging item id from the draggableKey (`inboxJob/jobId/weekJobsRow/weekKey`)
        const itemId = Number(draggableKey.split('/')[1]);

        // Get the target client height as placeholder height
        const target = e.currentTarget as HTMLDivElement;
        const placeholderHeight = target.clientHeight;

        // Set the dragging item
        setDraggingItem({ itemId, draggableKey, placeholderHeight });

        // Call onDrag to set the dragging item also in the scheduler component
        onDrag(itemId, draggableKey);

        // Change the opacity of the current item
        target.style.opacity = '0.4';
    }

    // Handle drag over, show placeholder
    const handleDragOver = (e: React.DragEvent, dragOverKey: string) => {
        // Allow dropping
        e.preventDefault();

        // Visually show that the item is moving
        e.dataTransfer.dropEffect = "move";

        // Update the drag over position in the dragging item state
        if (draggingItem && draggingItem.dragOverKey !== dragOverKey) {
            setDraggingItem({ ...draggingItem, dragOverKey });
        }
    }

    // Handle the dragging of a inbox job
    const handleInboxJobDrag = async (e: React.DragEvent, droppableKey: string) => {
        // Prevent default browser handling and stop propagation
        e.preventDefault();
        e.stopPropagation();

        // Get the variables from the draggable source (e.g. `inboxJob/jobId/weekJobsRow/weekKey` or `inboxJob/jobId/pastJobsRow`)
        const draggableKey = e.dataTransfer.getData("text/plain");       
        const [ draggingItemType, jobId, sourceRowType, sourceWeek ] = draggableKey.split('/')

        // Get the variables from the droppable destination (e.g. `weekJobsRow/weekKey` or `pastJobsRow`)
        const [ destinationRowType, destinationWeek ] = droppableKey.split('/')

        // Prevent users from moving jobs from a week row to a past jobs row
        if (destinationRowType === 'pastJobsRow' && sourceRowType !== 'pastJobsRow') {
            setFloatingAlert({ 
                type: 'danger',
                message: t("scheduling.job_inbox.invalid_move_to_past_alert")
            });

            // Set the dragging item to null and return
            setDraggingItem(null);
            return;
        }

        // Handle dragging of inbox jobs to other week rows inside the job inbox
        if (draggingItemType === 'inboxJob' && destinationRowType === 'weekJobsRow') {

            // Skip if a job is dropped back into the same week it was taken from
            if (sourceWeek === destinationWeek) {
                // Set the dragging item to null and return
                setDraggingItem(null);
                return;
            }

            // Set the dragging item to null to prevent flickering when updating the past- or weekjobs states
            setDraggingItem(null);

            // Get the right list of jobs wheter the item is dragged from the past- or weekjobs
            const initialJobs = sourceRowType === 'pastJobsRow' ? [...pastJobs] : [...weekJobs[sourceWeek]];
            const jobIndex = initialJobs.findIndex(job => job.id === Number(jobId));
            const [movedJob] = initialJobs.splice(jobIndex, 1);

            // Add the moved jobs to the destination week
            const destinationJobs = [...(weekJobs[destinationWeek] || [])];
            destinationJobs.push(movedJob);

            // Update the past- and weekjobs states to remove the job from there
            if (sourceRowType === 'pastJobsRow') {
                setPastJobs(initialJobs);
            } else {
                setWeekJobs(prev => ({ ...prev, [sourceWeek]: initialJobs }));
            }

            // Update the weekjobs state to add the job to the new destination week
            setWeekJobs(prev => ({ ...prev, [destinationWeek]: destinationJobs }));

            // Adjust the new target date to the same weekday
            const originalTargetDate = new Date(movedJob.target_date!);
            const originalTargetDateDay = originalTargetDate.getDay();

            // If the original target date day is on sunday (day 0), replace it with 7, to put it on the end of the week instead of the beginning
            const originalTargetDateDayOffset = (originalTargetDateDay === 0 ? 7 : originalTargetDateDay);
            
            // Calculate the new target date
            const firstDayOfWeek = getFirstDateOfWeek([destinationWeek]);
            const newTargetDate = new Date(firstDayOfWeek);

            // Add the original target date day offset
            newTargetDate.setDate(newTargetDate.getDate() + originalTargetDateDayOffset - (weekStartsOnSunday ? -1 : 0))

            // Convert the new target date to an iso string
            const formattedNewTargetDate = newTargetDate.toISOString().split('T')[0]

            // Save the updated job details
            const response = await saveData({
                apiObject: 'job',
                itemId: movedJob.id,
                data: { target_date: formattedNewTargetDate }
            });

            // Get the week number from the destination week
            const weekNumber = parseInt(destinationWeek.split('-')[1], 10);

            // Show floating alert on success
            if (response?.status === 200) {
                setFloatingAlert({ 
                    type: 'success',
                    message: t("scheduling.job_inbox.target_date_changed_alert", {
                        target_date: formatDisplayDate(new Date(formattedNewTargetDate), userLocale), 
                        week_number: weekNumber
                    }) 
                });
            }
        }

        // TODO: Handle dragging of a inbox job to the calendar to create an appointment
        // if (source.droppableId === 'pastJobsRow' || source.droppableId.startsWith('weekJobsRow-')) && destination.droppableId.startsWith('day-')) {
        //     const [job] = storageJobs.splice(source.index, 1);
        //     const [_, dayIndex, resourceId] = destination.droppableId.split('-');
        //     const dayKey = schedulingDates[parseInt(dayIndex)].toISOString().split('T')[0];

        //     const updatedResources = resources.map(resource => {
        //         if (resource.id === parseInt(resourceId)) {
        //             if (!resource.appointments[dayKey]) {
        //                 resource.appointments[dayKey] = [];
        //             }
        //             resource.appointments[dayKey].push({
        //                 id: job.id,
        //                 job: job.id,
        //                 start_date: `${dayKey}T00:00:00`,
        //                 start_time: '00:00',
        //                 assignees: [{ id: resource.id, name: resource.name }],
        //             });
        //         }
        //         return resource;
        //     });

        //     setResources(updatedResources);
        //     setStorageJobs([...storageJobs]);

        //     Stuur de nieuwe afspraak naar de server
        //     sendAppointmentToServer(newAppointment);

        //     Set is dragging to false, so new weeks can be added on scroll
        //     setIsDragging(false);
        // }
    }

    // Handle ending of the dragging
    const handleDragEnd = (e: React.DragEvent) => {
        // Revert the opacity of the original item
        const target = e.target as HTMLDivElement;
        target.style.opacity = '1';

        // Set is dragging to false, so new weeks can be added on scroll
        setIsDragging(false);

        // Empty the dragging item state
        setDraggingItem(null);
    }

    // Renders a dragging placeholder
    const draggingPlaceholder = (droppableKey: string) => {
        if (draggingItem) {
            // Extract the inbox row from the draggable key
            const draggableKeyParts = draggingItem.draggableKey.trim().split('/');
            const draggableInboxRow = draggableKeyParts.slice(2).join('/');

            // Determine if the current dragging row is the initial row
            const isInitialRow = draggingItem.dragOverKey === draggableInboxRow;
            
            // Render the placeholder on the currently dragged inbox row and not on the initial row
            if (draggingItem.dragOverKey === droppableKey && !isInitialRow) {
                return (
                    <div style={{ 
                        height: `${draggingItem.placeholderHeight}px`, 
                        width: '100%',
                        backgroundColor: '#D8D8D8BF',
                        boxSizing: 'border-box',
                        pointerEvents: 'none'
                    }} />
                );               
            }
        }
        return null;
    }

    // Renders a inbox row
    const renderInboxRow = ({ jobs, droppableKey, label, calculateDuration }: {
        jobs: JobType[], droppableKey: string, label: string, calculateDuration: (jobs: JobType[]) => string | null 
    }) => (
        <div className='inbox-row'
             onDrop={(e) => handleInboxJobDrag(e, droppableKey)}
             onDragOver={(e) => {e.preventDefault(); handleDragOver(e, droppableKey)}}>
            <div className='header'>
                <span className='label'>
                    {t(label)}
                </span>
                <span className='budget'>
                    {calculateDuration(jobs) ? `${calculateDuration(jobs)} ${t('date_time.general.hour.singular')}` : ''}
                </span>
            </div>
            <div>
                {jobs.filter((job => !job.deleted && (
                    job.status_condition === 'job_created' || job.status_condition === 'all_purchases_delivered'
                ))).map((job) => {
                    const draggableKey = `inboxJob/${job.id}/${droppableKey}`
                    return renderJobInboxItem(job, draggableKey)
                })}
                {draggingPlaceholder(droppableKey)}
                <div className='row-space'></div>
            </div>
        </div>
    );

    // Renders a job inbox item
    const renderJobInboxItem = (job: JobType, draggableKey: string) => (
        <div className='job-inbox-item'
             draggable={true}
             onDragStart={(e) => handleDragStart(e, draggableKey)}
             onDragEnd={(e) => handleDragEnd(e)}
             onClick={() => initializeModal(React.cloneElement(<JobModal />, { itemId: job.id }), { itemId: job.id, modalSize: 'large' }) }>
            <div className='info'>
                <div className='title'>
                    {job.number} {job.client_name}
                </div>
                <div className='remark'>
                    {job.target_date && `${formatDisplayDate(new Date(job.target_date), userLocale)}`}
                    {job.estimated_duration && ` • ${formatAmount(Number(job.estimated_duration), userLocale)} ${t('date_time.general.hour.singular')}`}
                </div>
            </div>
            <div className='order-icon'>
                <FontAwesomeIcon icon={faGripLines} />
            </div>
        </div>
    );

    return (
        <div className='job-inbox'>
            <div className='header'>
                <div className='title'>
                    {t('scheduling.job_inbox.job_inbox_title')}
                </div>
                <div className='help tooltip-icon'>
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    <span className="tooltip tooltip-bottom">{t('scheduling.job_inbox.job_inbox_tooltip')}</span>
                </div>
                <div className='icons'>
                    <div className='icon tooltip-icon'>
                        <FontAwesomeIcon icon={faAngleDoubleRight} onClick={() => onClose()}/>
                        <span className="tooltip tooltip-left">{t('scheduling.job_inbox.hide_icon_tooltip')}</span>
                    </div>
                </div>
            </div>
            <div className='inbox' 
                 style={{ height: '100%', maxHeight: inboxHeight }} 
                 ref={jobInboxRef}>
                {isInitialFetch ? (
                    fetchInboxJobsLoading === 'show-loader' && (
                        <div className='inbox-loader'>
                            <div className="loader"></div>
                        </div>
                    )
                ) : (
                    <>
                        <div className='content'>
                            <div className='inbox-rows'>
                                {pastJobs.length > 0 && (
                                    renderInboxRow({
                                        jobs: pastJobs,
                                        droppableKey: 'pastJobsRow',
                                        label: 'scheduling.job_inbox.past_week_label',
                                        calculateDuration: calculateRowDuration
                                    })
                                )}
                                {visibleWeeks.map((weekKey, index) => (
                                    renderInboxRow({
                                        jobs: weekJobs[weekKey] || [],
                                        droppableKey: `weekJobsRow/${weekKey}`,
                                        label: generateWeekLabel(weekKey, index > 0 ? visibleWeeks[index - 1] : undefined, index === 0),
                                        calculateDuration: calculateRowDuration
                                    })
                                ))}
                            </div>
                            <div style={{ height: spacerHeight }}></div>
                        </div>   
                        <span className='scroll-text'>
                            <Trans i18nKey="scheduling.job_inbox.load_more_weeks_helpertext" 
                                components={[<span onClick={loadAdditionalInboxRows} className="scroll-text-button"/>]}>
                                {t('scheduling.job_inbox.load_more_weeks_helpertext')}
                            </Trans>
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default JobInbox;

























// import React, { useEffect, useRef, useState } from 'react';
// import { useTranslation, Trans } from 'react-i18next';
// import { useAuthContext } from 'services/authentication/AuthenticationContext';
// import { useGlobalContext } from 'GlobalContext';
// import { useSettings } from 'services/settings/SettingsContext';
// import { useModal } from 'components/modals/ModalContext';
// import { useFetchData } from 'services/api/useFetchData';
// import { useWebSocket } from 'services/api/useWebSocket';
// import { saveData } from 'services/api/saveData';
// import useJobInboxSpacerHeight from './functions/useJobInboxSpacerHeight';
// import { generateWeekNumber } from 'services/utils/dateTimeUtils';
// import { generateJobInboxWeeks } from './functions/generateJobInboxWeeks';
// import { generateWeekKey, getFirstDateOfWeek, getLastDateOfWeek, initializeInboxJobsFetchParams, newTargetDateInCurrentOrFutureWeek } from './functions/jobInboxUtils';
// import { JobType } from '../jobs/JobTypes';
// import JobModal from '../jobs/JobModal';
// import { formatDisplayDate } from 'internationalization/timezoneConversions';
// import { formatAmount } from 'services/utils/amountFormatting';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDoubleRight, faCircleQuestion, faGripLines } from '@fortawesome/free-solid-svg-icons';
// import '../../style/scheduling.css'
// import { DragDropContext, Draggable } from 'react-beautiful-dnd';
// import { StrictModeDroppable } from 'services/utils/dragDropUtils';

// interface JobInboxProps {
//     inboxHeight: number;
//     onLoaded: (loaded: boolean) => void;
//     onClose: () => void
// }

// const JobInbox: React.FC<JobInboxProps> = ({ inboxHeight, onLoaded, onClose }) => {
//     const { t } = useTranslation();
//     const { environmentHash } = useAuthContext();
//     const { setFloatingAlert } = useGlobalContext();
//     const { userLocale } = useSettings();
//     const { initializeModal } = useModal();
//     const [fetchParams, setFetchParams] = useState<{ from?: string, to?: string }>((initializeInboxJobsFetchParams));
//     const { response: fetchedInboxJobs, loading: fetchInboxJobsLoading } = useFetchData({ apiUrl: 'get_job_inbox_list', params: fetchParams });
//     const [isInitialFetch, setIsInitialFetch] = useState(true);
//     const { message: inboxJobsWebsocket } = useWebSocket({ url: `inbox_jobs/${environmentHash}/` });
//     const [visibleWeeks, setVisibleWeeks] = useState<string[]>([]);
//     const [pastJobs, setPastJobs] = useState<JobType[]>([]);
//     const [weekJobs, setWeekJobs] = useState<{ [week: string]: JobType[] }>({});
//     const [isDragging, setIsDragging] = useState<boolean>(false);
//     const jobInboxRef = useRef<HTMLDivElement>(null);
//     const spacerHeight = useJobInboxSpacerHeight(inboxHeight, visibleWeeks, weekJobs);
//     const weekStartsOnSunday = false;

//     // Show the first weeks from now on initial render
//     useEffect(() => {
//         setVisibleWeeks(generateJobInboxWeeks(20));
//     }, [])

//     // Set the fetched inbox jobs on page load
//     useEffect(() => {
//         if (fetchedInboxJobs && fetchedInboxJobs.results) {
//             const pastJobsList: JobType[] = [...pastJobs];
//             const futureJobs: { [week: string]: JobType[] } = {};

//             fetchedInboxJobs.results.forEach((job: JobType) => {
//                 if (job.target_date) {
//                     const targetDate = new Date(job.target_date);
//                     const now = new Date();

//                     // Add job to the past jobs list, if the target date is before today
//                     if (targetDate < now) {
//                         pastJobsList.push(job);

//                     // Add job to the week lists, if the target date is in the future
//                     } else {
//                         // Generate the week key
//                         const week = generateWeekNumber(targetDate, weekStartsOnSunday);
//                         const year = targetDate.getFullYear();
//                         const weekKey = `${year}-${week}`;

//                         // Put the jobs in the week of the week key
//                         if (!futureJobs[weekKey]) futureJobs[weekKey] = [];
//                         futureJobs[weekKey].push(job);
//                     }
//                 }
//             });
    
//             setPastJobs(pastJobsList);
//             setWeekJobs(prev => ({ ...prev, ...futureJobs }));
//             setVisibleWeeks(prev => Array.from(new Set([...prev, ...Object.keys(futureJobs)])));
//             setIsInitialFetch(false);
//         };
//     }, [fetchedInboxJobs]);

//     // If the data is successfully loaded on the initial fetch/render, set onLoaded to true
//     useEffect(() => {
//         if (isInitialFetch && fetchInboxJobsLoading === 'success') {
//             onLoaded(true);
//         } 
//     }, [isInitialFetch, fetchInboxJobsLoading, onLoaded]);

//     // Process received updates of inbox jobs by websocket
//     useEffect(() => {
//         const { type, message } = inboxJobsWebsocket || {};

//         if (type === 'inbox_jobs_updated' ) {
//             // Get the values from the message to use in this function
//             const { id, target_date, status, deleted, ...otherProps } = message;

//             // Determine if the new target date is in the current or future weeks
//             const targetDateInCurrentOrFutureWeek = newTargetDateInCurrentOrFutureWeek(target_date, weekStartsOnSunday)
//             const newWeekKey = generateWeekKey(target_date, weekStartsOnSunday);

//             // Update week jobs
//             setWeekJobs(existingWeekJobs => {
//                 const updatedWeekJobs = { ...existingWeekJobs };

//                 // Remove the job from all existing weeks
//                 Object.keys(updatedWeekJobs).forEach(weekKey => {
//                     updatedWeekJobs[weekKey] = updatedWeekJobs[weekKey].filter(job => job.id !== id);
//                 });

//                 // Add the job to the week that correspondents to the new target date
//                 if (!deleted && status === 'to_schedule' && targetDateInCurrentOrFutureWeek) {
//                     if (!updatedWeekJobs[newWeekKey]) {
//                         updatedWeekJobs[newWeekKey] = [];
//                     }
//                     updatedWeekJobs[newWeekKey].push({ id, target_date, status, ...otherProps });
//                 }

//                 return updatedWeekJobs;
//             });

//             // Add the job to the past jobs if the target date is in a past week
//             setPastJobs(currentPastJobs => {
//                 let updatedPastJobs = currentPastJobs.filter(job => job.id !== id);

//                 if (!deleted && status === 'to_schedule' && !targetDateInCurrentOrFutureWeek) {
//                     updatedPastJobs.push({ id, target_date, status, ...otherProps });
//                 }

//                 return updatedPastJobs;
//             });
//         }
//     }, [inboxJobsWebsocket]);

//     // Load more week rows when scrolling down
//     useEffect(() => {
//         const jobInboxElement = jobInboxRef.current;
//         if (jobInboxElement == null) return;

//         // Execute the loading when scrolling
//         const handleScroll = () => {
//             // Skip when dragging an inbox job, otherwise users may drag into new weeks which doesn't exist as destination yet
//             if (isDragging) return;

//             if (jobInboxElement && jobInboxElement.scrollTop + jobInboxElement.clientHeight >= jobInboxElement.scrollHeight - 10) {
//                 loadAdditionalInboxRows();
//             };
//         };
    
//         // Add and remove event listener for scroll event
//         jobInboxElement.addEventListener('scroll', handleScroll);
//         return () => jobInboxElement.removeEventListener('scroll', handleScroll);
//     }, [visibleWeeks, isDragging]);

//     // Create and load additional weeks into the job inbox
//     const loadAdditionalInboxRows = () => {
//         // Determine the current last visible week
//         const currentLastWeek = visibleWeeks[visibleWeeks.length - 1];

//         // Generate the desired weeks from the current last week
//         const additionalWeeksToCreate = 5
//         const generatedAdditionalWeeks = generateJobInboxWeeks(additionalWeeksToCreate, currentLastWeek);

//         // Add the generated additional weeks to the visible weeks 
//         setVisibleWeeks(prev => Array.from(new Set([...prev, ...generatedAdditionalWeeks])));

//         // Get the first and last date of the generated weeks
//         const firstDate = getFirstDateOfWeek(generatedAdditionalWeeks);
//         const lastDate = getLastDateOfWeek(generatedAdditionalWeeks);

//         // Update the dates into the fetch params to trigger a new fetch
//         setFetchParams({ from: firstDate, to: lastDate });
//     };

//     // Calculates the total estimated duration of all jobs within an inbox row
//     const calculateRowDuration = (jobs: JobType[]): string | null => {
//         if (!jobs || jobs.length === 0) {
//             return null; 
//         }

//         const totalDuration = jobs.reduce((total, job) => total + (Number(job.estimated_duration) || 0), 0);
//         return formatAmount(totalDuration, userLocale);
//     };

//     // Generate the week label to show in the week row
//     const generateWeekLabel = (weekKey: string, previousWeekKey?: string, isCurrentWeek: boolean = false): string => {
//         const [year, week] = weekKey.split('-').map(Number);

//         // Determine if the week is the first week of the year
//         const firstWeekOfYear = week === 1 || ((previousWeekKey && year !== parseInt(previousWeekKey.split('-')[0])));

//         // In case of the first week of the year, show the year
//         let weekLabel;
//         if (isCurrentWeek) {
//             weekLabel = `${t('date_time.general.current_week')}`;
//         } else if (firstWeekOfYear) {
//             weekLabel = `${t('date_time.general.week_short')} ${week} (${year})`;
//         } else {
//             weekLabel = `${t('date_time.general.week_short')} ${week}`;
//         }

//         return weekLabel;
//     };

//     // Handle the dragging of a inbox job
//     const handleInboxJobDrag = async (result: any) => {
//         const { source, destination } = result;

//         console.log("InboxDrag", source, destination);

//         if (!destination) {
//             // Set is dragging to false, so new weeks can be added on scroll
//             setIsDragging(false);

//             return;
//         }

//         // Prevent users from moving jobs from a week row to the past jobs row
//         if (destination.droppableId === 'pastJobsRow') {
//             if (source.droppableId !== 'pastJobsRow') {
//                 setFloatingAlert({ 
//                     type: 'danger',
//                     message: t("scheduling.job_inbox.invalid_move_to_past_alert")
//                 });
//             }

//             // Set is dragging to false, so new weeks can be added on scroll
//             setIsDragging(false);

//             return;
//         } 

//         // Handle dragging of jobs inside the job inbox
//         if ((source.droppableId === 'pastJobsRow' || source.droppableId.startsWith('weekJobsRow-')) && destination.droppableId.startsWith('weekJobsRow-')) {

//             //Determine the source and destination week of the dragged job
//             const sourceWeek = source.droppableId.replace('weekJobsRow-', '');
//             const destinationWeek = destination.droppableId.replace('weekJobsRow-', '');

//             // Skip if the job is dropped back into the same week it was taken from
//             // if (sourceWeek === destinationWeek && source.index === destination.index) {
//             if (sourceWeek === destinationWeek) {
//                 // Set is dragging to false, so new weeks can be added on scroll
//                 setIsDragging(false);

//                 return;
//             }

//             // Get the right list of jobs whether the source is from the past jobs or week jobs
//             const sourceJobs = source.droppableId === 'pastJobsRow' ? [...pastJobs] : [...weekJobs[sourceWeek]];
//             const [movedJob] = sourceJobs.splice(source.index, 1);
    
//             // Add the moved job to the destination week
//             const destinationJobs = [...(weekJobs[destinationWeek] || [])];
//             destinationJobs.splice(destination.index, 0, movedJob);
    
//             // Remove the moved job from either the past jobs or the week
//             if (source.droppableId === 'pastJobsRow') {
//                 setPastJobs(sourceJobs);
//             } else {
//                 setWeekJobs(prev => ({ ...prev, [sourceWeek]: sourceJobs }));
//             }

//             // Add the moved job to the destination week
//             setWeekJobs(prev => ({ ...prev, [destinationWeek]: destinationJobs }));

//             // Adjust the new target date to the same weekday
//             const originalTargetDate = new Date(movedJob.target_date!);
//             const originalTargetDateDay = originalTargetDate.getDay();

//             // If the original target date day is on sunday (day 0), replace it with 7, to put it on the end of the week instead of the beginning
//             const originalTargetDateDayOffset = (originalTargetDateDay === 0 ? 7 : originalTargetDateDay);
            
//             // Calculate the new target date
//             const firstDayOfWeek = getFirstDateOfWeek([destinationWeek]);
//             const newTargetDate = new Date(firstDayOfWeek);

//             // Add the original target date day offset
//             newTargetDate.setDate(newTargetDate.getDate() + originalTargetDateDayOffset - (weekStartsOnSunday ? -1 : 0))

//             // Convert the new target date to an iso string
//             const formattedNewTargetDate = newTargetDate.toISOString().split('T')[0]

//             // Save the updated job details
//             const response = await saveData({
//                 apiObject: 'job',
//                 itemId: movedJob.id,
//                 data: { target_date: formattedNewTargetDate }
//             });

//             // Get the week number from the destination week
//             const weekNumber = parseInt(destinationWeek.split('-')[1], 10);

//             // Show floating alert on success
//             if (response?.status === 200) {
//                 setFloatingAlert({ 
//                     type: 'success',
//                     message: t("scheduling.job_inbox.target_date_changed_alert", {
//                         target_date: formatDisplayDate(new Date(formattedNewTargetDate), userLocale), 
//                         week_number: weekNumber
//                     }) 
//                 });
//             }

//             // Set is dragging to false, so new weeks can be added on scroll
//             setIsDragging(false);
    
//             return;
//         }

//         // TODO: Handle dragging of a inbox job to the calendar to create an appointment
//         // if (source.droppableId === 'pastJobsRow' || source.droppableId.startsWith('weekJobsRow-')) && destination.droppableId.startsWith('day-')) {
//         //     const [job] = storageJobs.splice(source.index, 1);
//         //     const [_, dayIndex, resourceId] = destination.droppableId.split('-');
//         //     const dayKey = schedulingDates[parseInt(dayIndex)].toISOString().split('T')[0];

//         //     const updatedResources = resources.map(resource => {
//         //         if (resource.id === parseInt(resourceId)) {
//         //             if (!resource.appointments[dayKey]) {
//         //                 resource.appointments[dayKey] = [];
//         //             }
//         //             resource.appointments[dayKey].push({
//         //                 id: job.id,
//         //                 job: job.id,
//         //                 start_date: `${dayKey}T00:00:00`,
//         //                 start_time: '00:00',
//         //                 assignees: [{ id: resource.id, name: resource.name }],
//         //             });
//         //         }
//         //         return resource;
//         //     });

//         //     setResources(updatedResources);
//         //     setStorageJobs([...storageJobs]);

//         //     Stuur de nieuwe afspraak naar de server
//         //     sendAppointmentToServer(newAppointment);

//         //     Set is dragging to false, so new weeks can be added on scroll
//         //     setIsDragging(false);
//         // }
//     };

//     // Renders a inbox row
//     const renderInboxRow = ({ jobs, droppableId, label, calculateDuration }: {
//         jobs: JobType[], droppableId: string, label: string, calculateDuration: (jobs: JobType[]) => string | null 
//     }) => (
//         <StrictModeDroppable droppableId={droppableId}>
//             {(provided, snapshot) => (
//                 <div className='inbox-row'>
//                     <div className='header'>
//                         <span className='label'>
//                             {t(label)}
//                         </span>
//                         <span className='budget'>
//                             {calculateDuration(jobs) ? `${calculateDuration(jobs)} ${t('date_time.general.hour.singular')}` : ''}
//                         </span>
//                     </div>
//                     <div 
//                     ref={provided.innerRef} {...provided.droppableProps}
//                     >
//                         <div 
//                         style={{ backgroundColor: snapshot.isDraggingOver ? '#D8D8D8BF' : 'inherit' }}
//                         >
//                             {jobs.filter((job => !job.deleted && job.status === 'to_schedule')).map((job, index) => (
//                                 <Draggable key={job.id} draggableId={`job-${job.id}`} index={index}>
//                                     {(dragProvided) => 
//                                         renderJobInboxItem(job, dragProvided)
//                                     // renderJobInboxItem(job)
//                                     }
//                                 </Draggable>
//                             ))}
//                             {provided.placeholder}
//                         </div>
//                         <div className='row-space'></div>
//                     </div>
//                 </div>
//            )}
//         </StrictModeDroppable>
//     );

//     // Renders a job inbox item
//     const renderJobInboxItem = (job: JobType, provided?: any) => (
//         <div className='job-inbox-item'
//             draggable={true}
//             onClick={() => initializeModal(React.cloneElement(<JobModal />, { itemId: job.id }), { itemId: job.id, modalSize: 'large' }) }
//              ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
//             >
//             <div className='info'>
//                 <div className='title'>
//                     {job.number} {job.client_name}
//                 </div>
//                 <div className='remark'>
//                     {job.target_date && `${formatDisplayDate(new Date(job.target_date), userLocale)}`}
//                     {job.estimated_duration && ` • ${formatAmount(Number(job.estimated_duration), userLocale)} ${t('date_time.general.hour.singular')}`}
//                 </div>
//             </div>
//             <div className='order-icon'>
//                 <FontAwesomeIcon icon={faGripLines} />
//             </div>
//         </div>
//     );

//     return (
//         <div className='job-inbox'>
//             <div className='header'>
//                 <div className='title'>
//                     {t('scheduling.job_inbox.job_inbox_title')}
//                 </div>
//                 <div className='help tooltip-icon'>
//                     <FontAwesomeIcon icon={faCircleQuestion} />
//                     <span className="tooltip tooltip-bottom">{t('scheduling.job_inbox.job_inbox_tooltip')}</span>
//                 </div>
//                 <div className='icons'>
//                     <div className='icon tooltip-icon'>
//                         <FontAwesomeIcon icon={faAngleDoubleRight} onClick={() => onClose()}/>
//                         <span className="tooltip tooltip-left">{t('scheduling.job_inbox.hide_icon_tooltip')}</span>
//                     </div>
//                 </div>
//             </div>
//             <div className='inbox' 
//                  style={{ height: '100%', maxHeight: inboxHeight }} 
//                  ref={jobInboxRef}>
//                 {isInitialFetch ? (
//                     fetchInboxJobsLoading === 'show-loader' && (
//                         <div className='inbox-loader'>
//                             <div className="loader"></div>
//                         </div>
//                     )
//                 ) : (
//                     <>
//                         <div className='content'>
//                             <div className='inbox-rows'>
//                                 <DragDropContext onDragStart={() => setIsDragging(true)} onDragEnd={handleInboxJobDrag}>
//                                     {pastJobs.length > 0 && (
//                                         renderInboxRow({
//                                             jobs: pastJobs,
//                                             droppableId: 'pastJobsRow',
//                                             label: 'scheduling.job_inbox.past_week_label',
//                                             calculateDuration: calculateRowDuration
//                                         })
//                                     )}
//                                     {visibleWeeks.map((weekKey, index) => (
//                                         renderInboxRow({
//                                             jobs: weekJobs[weekKey] || [],
//                                             droppableId: `weekJobsRow-${weekKey}`,
//                                             label: generateWeekLabel(weekKey, index > 0 ? visibleWeeks[index - 1] : undefined, index === 0),
//                                             calculateDuration: calculateRowDuration
//                                         })
//                                     ))}
//                                 </DragDropContext>
//                             </div>
//                             <div style={{ height: spacerHeight }}></div>
//                         </div>   
//                         <span className='scroll-text'>
//                             <Trans i18nKey="scheduling.job_inbox.load_more_weeks_helpertext" 
//                                 components={[<span onClick={loadAdditionalInboxRows} className="scroll-text-button"/>]}>
//                                 {t('scheduling.job_inbox.load_more_weeks_helpertext')}
//                             </Trans>
//                         </span>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default JobInbox;