import React, { useEffect, useState } from 'react';
import { AppointmentsCardProps } from 'types/DetailPageTypes';
import { useTranslation } from 'react-i18next';
import { AppointmentType, TimeBlockType } from '../../views/jobs/JobTypes';
import { useModal } from 'components/modals/ModalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import ScheduleJobModal from '../../views/jobs/ScheduleJobModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useSettings } from 'services/settings/SettingsContext';
import { extractDateFromLocalDateTime, extractTimeFromLocalDateTime } from 'internationalization/timezoneConversions';
import { convertAppointmentsToLocalDateTimes, sortAppointments } from 'services/utils/sortDates';
import '../../style/scss/card.scss';
import '../../style/scss/live-edit.scss';
import '../../style/scss/forms.scss';

const AppointmentsCard: React.FC<AppointmentsCardProps> = ({ 
    viewKey, isEditable, itemId, data, loadStatus, refetchData
}) => {
    const { t } = useTranslation();
    const { userLocale, userTimezone, getTimeFormat, environmentSettings } = useSettings();
    const { initializeModal } = useModal();
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<AppointmentType[]>([]); 
    const is12HourFormat = getTimeFormat() === '12h';
    const [timeBlocks, setTimeBlocks] = useState<TimeBlockType[]>([]);
    const [scheduleMultipleAppointments, setScheduleMultipleAppointments] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState(false);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // To do:
    // - Show name of timeblock if there is a time block
    // - If no user is selected, show appointment type as 'nog in te plannen'
    // - Make badges for appointment states?

    // Set the current appointments if they exist
    useEffect(() => {
        if (data && data['appointments']) {
            // Convert appointment dates to local date times
            const { appointmentsWithLocalDatetimes } = convertAppointmentsToLocalDateTimes(data['appointments'], userLocale, userTimezone);

            // Sort the appointments on start datetime
            const { sortedAppointments } = sortAppointments(appointmentsWithLocalDatetimes);

            // Set the appointments as rows
            setRows(sortedAppointments);
        }
    }, [data]);

    // Set the schedule multiple appointments setting to show/hide the add appointments button
    useEffect(() => {
        if (environmentSettings.job_appointment_method === 'multiple') {
            setScheduleMultipleAppointments(true);
        } else {
            setScheduleMultipleAppointments(false);
        }
    }, [environmentSettings]);

    // Handle the opening of the schedule job modal
    const handleOpenModal = (event: React.MouseEvent) => {
        if (isEditable && !userHasOnlyViewRights) {
            // Only open modal when the object is editable
            event.preventDefault()
            initializeModal(
                <ScheduleJobModal jobId={parseInt(itemId)} setLoading={isLoading => setShowLoader(isLoading)} />, { modalSize: 'medium' }
            );
        }
    };

    return (
        <div className='card appointmentscard'>
            <h6>{t('appointment.general.appointments_label')}</h6>
            {loadStatus === "loading" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && rows && (
                <div className='live-edit-field'>
                    <div className={`full-width-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>
                        <div className='view-mode'
                             onClick={(event) => handleOpenModal(event)}>
                            <span className='p'>
                                <div className='item-list'>
                                {rows.map((appointment: AppointmentType) => { 

                                    // Extract dates and times from the local datetime strings
                                    let startDate, startTime, endDate, endTime;
                                    if (appointment.start_datetime) {
                                        startDate = extractDateFromLocalDateTime(appointment.start_datetime, userLocale);
                                        startTime = extractTimeFromLocalDateTime(appointment.start_datetime, userLocale, is12HourFormat);
                                    }
                                    if (appointment.end_datetime) {
                                        endDate = extractDateFromLocalDateTime(appointment.end_datetime, userLocale);
                                        endTime = extractTimeFromLocalDateTime(appointment.end_datetime, userLocale, is12HourFormat); 
                                    }

                                    // Map and combine the assignees
                                    const mappedAssignees = appointment?.assignees?.map(assignee => assignee.full_name).join(', ');
                                    
                                    return (
                                        <div key={appointment.id}>
                                            {appointment.whole_day ? (
                                                // For whole day appointments, show start date
                                                // For time block appointments, show start date and time block
                                                <>{startDate} {appointment.time_block && `- ${appointment.time_block}`}</>
                                            ) : (
                                                // For timed appointments, show start date and times. Show end date if it differs from the start date
                                                <>{startDate} - {startTime}-{endTime} {endDate && endDate !== startDate && ` (${endDate})`}</>
                                            )}
                                            {mappedAssignees && `- ${mappedAssignees}`} 
                                            {appointment.status && ` ${appointment.status}`} 
                                        </div>
                                    )
                                })}
                                </div>
                                {isEditable && !userHasOnlyViewRights && (
                                    // Only show add item label when the object is editable
                                    (rows.length === 0 || scheduleMultipleAppointments) && (
                                        // Only allow to add new appointments based on the job appointment setting
                                        <div className='add-item' 
                                            onClick={(event) => handleOpenModal(event)}>
                                            {t('appointment.general.add_appointment_label')}
                                        </div>
                                    )
                                )}
                            </span>
                            <span className='edit-icon'>
                                <FontAwesomeIcon icon={faPen} />
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AppointmentsCard;