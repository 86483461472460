import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguageOptions, LocaleOptions, Translation } from './LanguageTypes';

// Define the language/locale code keys here:
export type Locale = 
    | 'en-US'
    | 'en-GB'
    | 'nl-NL'
    | 'de-DE'
;

// Define the language options here:
export const languageOptions: LanguageOptions = {
    'en-US': { 
        label: 'English (US)', 
        translation: require('../internationalization/language/en-US.json') as Translation,
    },
    'nl-NL': { 
        label: 'Nederlands', 
        translation: require('../internationalization/language/nl-NL.json') as Translation,
    },
};

// Define the date/number locale options here:
export const localeOptions: LocaleOptions = {
    'en-US': { 
        label: 'date_time.locale.en-US',
        timeFormat: '12h',
        dateFormat: 'MM/dd/yyyy',
        numberFormat: '1,000.00'
    },
    'en-GB': { 
        label: 'date_time.locale.en-GB', 
        timeFormat: '24h',
        dateFormat: 'dd/MM/yyyy',
        numberFormat: '1,000.00'
    },
    'nl-NL': { 
        label: 'date_time.locale.nl-NL', 
        timeFormat: '24h',
        dateFormat: 'dd-MM-yyyy',
        numberFormat: '1.000,00'
    },
    'de-DE': { 
        label: 'date_time.locale.de-DE',
        timeFormat: '24h',
        dateFormat: 'dd.MM.yyyy',
        numberFormat: '1.000,00' 
    },
}

// Sometimes the navigator.language gives a 2-letter language code. This function converts it and optionally sets the fallback language code
export const getNavigatorLanguageOrFallback = (languageCode: string, optionsType: 'language' | 'locale'): string => {

    // Generate full language code if only a two letter code is provided
    const fullLanguageCode = languageCode.length === 2
        ? `${languageCode}-${languageCode.toUpperCase()}`
        : languageCode;

    // Determine the options to check agains
    const options = optionsType === 'language' ? languageOptions : localeOptions;

    // Check if the full language code exists in the language- or locale options, otherwise use fallback langauge code
    return options[fullLanguageCode] ? fullLanguageCode : 'en-US';
}

// Converts the language options into i18n resources
const resources = Object.keys(languageOptions).reduce<{ [key: string]: { translation: Translation } }>((acc, lang) => {
    acc[lang] = { translation: languageOptions[lang].translation };
    return acc;
}, {});

// Generates the current timezone string. For example: "(+02:00) Europe/Amsterdam"
export const generateCurrentTimezoneString = (userTimezone: string, userLocale: string): string => {
    const formatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        timeZoneName: 'shortOffset',
        hour: '2-digit',
        minute: '2-digit'
    });
    const parts = formatter.formatToParts(new Date());
    const offsetPart = parts.find(part => part.type === 'timeZoneName');
    let offsetString = offsetPart?.value ?? '';
    offsetString = offsetString.replace('GMT', 'UTC');
    return `(${offsetString}) ${userTimezone}`;
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getNavigatorLanguageOrFallback(navigator.language, 'language'),
        fallbackLng: getNavigatorLanguageOrFallback(navigator.language, 'language'),
        debug: false,
        interpolation: {
            escapeValue: false,
            format: (value: string, format: string) => {
                // If the format value is 'capitalize', capitalize the first letter of the value
                if (format === 'capitalize') {
                    return value.charAt(0).toUpperCase() + value.slice(1);
                }
                return value;
            }
        },
    });;
    
export default i18n;