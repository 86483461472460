import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { roleSpecificFeatures } from 'services/permissions/roleSpecificFeatures';

/*
 * PrivateRoute.tsx
 * This extends the Routes component to make links private and can
 * only be opened if the user is logged in or has the required
 * permissions and features.
 */

interface PrivateRouteProps extends RouteProps {
    activeFeature?: string;
    permissionCheck?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ activeFeature, permissionCheck, ...props }) => {
    const { isLoggedIn, activeFeatures, allowedFeatures, allowedRights, statesLoaded } = useAuthContext();

    // Check if the feature is active in the environment and the user is allowed to see it based on its role
    const checkActiveFeature = (featureName: string) => {
        const isActive = activeFeatures.includes(featureName);
        const isRoleSpecific = roleSpecificFeatures.includes(featureName);

        // If the feature is role specific, check if the feature is in activeFeatures and allowedFeatures
        if (isRoleSpecific) {
            return isActive && allowedFeatures.includes(featureName);
        }

        // If the feature is not role specific, check if the feature is in activeFeatures
        return isActive;
    };

    const hasActiveFeature = activeFeature ? checkActiveFeature(activeFeature) : true;
    const hasPermission = permissionCheck ? allowedRights.includes(permissionCheck) : true;

    // Redirect to the login page if the user is not logged in
    if (!isLoggedIn) {
        return <Redirect to="/login" />
    }

    // Do not redirect in case the activeFeatures or allowedRights states are loaded in the authentication context
    if (!statesLoaded) {
        return null;
    }

    // Redirect to access denied page if the user has no access to the required feature or permission 
    const isFeatureOrPermissionProvided = activeFeature || permissionCheck;
    if (isFeatureOrPermissionProvided && (!hasActiveFeature || !hasPermission)) {
        return <Redirect to="/access-denied" />
    }

    // Render the route if the user is logged in and has the required permissions/features
    return <Route {...props} />
};

export default PrivateRoute;