import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { FieldType } from 'types/FieldTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { HeaderButtons } from 'types/ButtonTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Rename the name of the function:
const ProductDetail: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'product'
    const pageObject = 'product'
    const statusBar = false

    // Define the header title fields:
    const headerTitleFields: FieldType[] = [
        {
            name: 'name',
            type: 'text',
            placeholder: 'product.general.name_placeholder',
            saveOnBlur: true,
        },
    ]

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            tooltipText: 'general.deactivate',
            action: 'deactivate',
            size: 'medium'
        },
    ]

    // Define the detail page tabs:
    const tabs: TabType[] = [ 
        { label: 'timeline.general.timeline_label', type: 'timeline', showOnlyTimelineUpdates: true },
    ]

    // Define detail components and add fields to FieldsCards:
    const detailCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'product.general.detail_card_label',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'text', 
                    name: 'number', 
                    label: 'product.general.number_label', 
                    placeholder: 'product.general.number_placeholder',
                },
                { 
                    type: 'dropdown', 
                    name: 'category', 
                    label: 'product.general.category_label', 
                    apiObject: 'productcategory',
                    apiRequest: [{
                        endpoint: 'get_productcategory_list',
                        object: 'productcategory',
                        params: { is_active: true, is_product: true }
                    }],
                    disabled_selected: 'product.general.category_disabled_selected',
                },
                { 
                    type: 'text', 
                    name: 'unit', 
                    label: 'product.general.unit_label', 
                    placeholder: 'product.general.unit_placeholder', 
                },
                { 
                    type: 'price-currency', 
                    name: 'sale_price', 
                    label: 'product.general.sale_price_label', 
                    placeholder: 'product.general.sale_price_placeholder', 
                    apiObject: 'currency',
                }, 
                { 
                    type: 'textarea', 
                    name: 'description', 
                    label: 'product.general.description_label', 
                    placeholder: 'product.general.description_placeholder', 
                },
                { 
                    type: 'image', 
                    name: 'image', 
                    label: 'product.general.image_label', 
                    imageType: 'landscape',
                    dropzoneText: 'product.general.image_placeholder', 
                    apiField: { 
                        fileUpload: 'image_file', 
                        fileName: 'image_filename'
                    },
                    cropConfig: {
                        aspectRatio: 4/3,
                        cropShape: 'rect',
                        resize: { width: 400, height: 300 }
                    }
                },
                { 
                    type: 'tabs', 
                    name: 'is_inventory', 
                    label: 'product.general.inventory_item_label', 
                    tooltipText: 'product.general.inventory_item_tooltipText',
                },
                // { 
                //     type: 'date', 
                //     name: 'active_until', 
                //     label: 'product.general.active_until_label', 
                // },
            ],
        },
        // Add more detail components if needed:
    ]

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitleFields={headerTitleFields}
            headerButtons={headerButtons}
            statusBar={statusBar}
            detailCards={detailCards}
            tabs={tabs}
        />
    );
};

export default ProductDetail;