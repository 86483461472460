export interface TimeTypeType {
    id: number;
    uuid?: string;
    name: string | null;
    type: string | null;
    is_calculated_leave: boolean;
    is_active: boolean;
    division: number | null;
}

export interface TimeRowType {
    id: number | null;
    uuid?: string;
    job: number | null;
    appointment: number | null;
    time_type: number | null;
    date: string | null;
    start_date: Date | null;
    end_date: Date | null;
    start_time: string | null;
    end_time: string | null;
    amount_string: string | null;
    duration: number | null;
    assignee: number | null;
    hourly_rate: number | null;
    total_price: number | null;
    note: string | null;
    deleted: boolean;
}

export const createNewTimeRow = ({ jobId, appointmentId, uuid }: { jobId?: number, appointmentId?: number, uuid: string }): TimeRowType => ({
    id: null,
    uuid: uuid,
    job: jobId ?? null,
    appointment: appointmentId ?? null,
    time_type: null,
    date: null,
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    amount_string: null,
    duration: null,
    assignee: null,
    hourly_rate: null,
    total_price: 0,
    note: null,
    deleted: false,
})