import React from 'react';
import CreateAccountForm from './CreateAccountForm';
import logoGrey from 'assets/crafter-logo-grey.png';
import '../../style/scss/account.scss';

const CreateAccountPage = () => {
    return (
        <div className='container-center'>
            <div className='account-container'>
                <img src={logoGrey} alt="Logo" className='account-logo'/>
                <div className='account-form'>
                    <CreateAccountForm />
                </div>
            </div>
        </div>
    );
};

export default CreateAccountPage;