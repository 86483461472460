import { AppointmentType } from "../../views/jobs/JobTypes";
import { convertUTCToLocalDateTimeString } from "internationalization/timezoneConversions";

// Sort the appointments on start datetime
export const sortAppointments = (appointments: AppointmentType[]):
{ sortedAppointments: AppointmentType[] } => {

    // Sort the appointments on start datetime
    const sortedAppointments = appointments
        .slice()
        .sort((a: AppointmentType, b: AppointmentType) => {
            const dateA = a.start_datetime ? new Date(a.start_datetime) : null;
            const dateB = b.start_datetime ? new Date(b.start_datetime) : null;

            if (dateA && dateB) return dateA.getTime() - dateB.getTime();
            if (dateA && !dateB) return -1;
            if (!dateA && dateB) return 1;
            return 0;
        });

    return { sortedAppointments };
}

// Convert the appointment dates to local datetimes
export const convertAppointmentsToLocalDateTimes = (
    appointments: AppointmentType[],
    userLocale: string, 
    userTimezone: string):
{ appointmentsWithLocalDatetimes: AppointmentType[] } => {

    // Convert the start- and end datetimes to the local timezone of the user
    const appointmentsWithLocalDatetimes = appointments.map((appointment: AppointmentType) => ({
        ...appointment,
        start_datetime: appointment.start_datetime 
            ? convertUTCToLocalDateTimeString(appointment.start_datetime, userLocale, userTimezone)
            : null,
        end_datetime: appointment.end_datetime
            ? convertUTCToLocalDateTimeString(appointment.end_datetime, userLocale, userTimezone)
            : null
    }));

    return { appointmentsWithLocalDatetimes };
}