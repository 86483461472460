import i18n, { getNavigatorLanguageOrFallback } from 'internationalization/i18n';
import React, { useContext, useEffect, useState } from 'react';
import { fetchData } from 'services/api/fetchData';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { EnvironmentSettingsType, SettingsContext, SettingsContextProviderProps, UserPreferenceType, createEnvironmentSettingsObject, createUserPreferenceObject } from '../settings/SettingsTypes';
import { encryptData } from 'services/authentication/encryptData';
import { useWebSocket } from 'services/api/useWebSocket';
import { localeOptions } from 'internationalization/i18n';
import { currencySymbols } from 'internationalization/currencySymbols';

/*
 * SettingsContext.tsx
 * This context is created to contain states and information about user
 * and environment settings, such as language, job or scheduling settings.
 * This context also initialises the language based on the browser language
 * or sets it automatically to the users set up language.
 */ 

export const SettingsContextProvider: React.FC<SettingsContextProviderProps> = ({ children }) => {
    const { isLoggedIn, userHash, environmentHash, setFirstName, setLastName } = useAuthContext();
    const { message: userPreferencesWebsocket } = useWebSocket({ url: `user_preferences/${userHash}/` });
    const { message: environmentSettingsWebsocket } = useWebSocket({ url: `environment_settings/${environmentHash}/` });
    const [userPreferences, setUserPreferences] = useState<UserPreferenceType>(createUserPreferenceObject);
    const [environmentSettings, setEnvironmentSettings] = useState<EnvironmentSettingsType>(createEnvironmentSettingsObject);
    const [_, _setLanguage] = useState<string>(getNavigatorLanguageOrFallback(navigator.language, 'language'));
    const [defaultCurrency, setDefaultCurrency] = useState<string>('eur');
    const [defaultCurrencySymbol, setDefaultCurrencySymbol] = useState<string>('€');

    // Set the timezone based on the browser timezone on first render
    useEffect(() => {
        const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setUserPreferences(prev => ({ ...prev, ['timezone']: detectedTimezone }));
    }, []);

    // Reset to browser's language if not logged in
    useEffect(() => {
        if (!isLoggedIn) {
            setLanguage(getNavigatorLanguageOrFallback(navigator.language, 'language'));
        }
    }, [isLoggedIn]);

    // Fetch user preferences when login is true
    useEffect(() => {
        if (isLoggedIn && userHash) {
            const fetchUserPreferences = async () => {
                try {
                    // Fetch the user preferences
                    const fetchedPreferences = await fetchData({ apiUrl: `get_user_preference/${userHash}` })

                    if (!fetchedPreferences.user_locale) {
                        fetchedPreferences.user_locale = getNavigatorLanguageOrFallback(navigator.language, 'locale');
                    }

                    if (!fetchedPreferences.language) {
                        fetchedPreferences.language = getNavigatorLanguageOrFallback(navigator.language, 'language');
                    }

                    // Update the user preferences and language in the context
                    setUserPreferences(prev => ({ ...prev, ...fetchedPreferences }));
                    setLanguage(fetchedPreferences.language);
                } catch (error: any) {
                    console.error(error);
                }
            }
            fetchUserPreferences();
        }
    }, [isLoggedIn, userHash]);

    // Fetch user preferences when login is true
    useEffect(() => {
        if (isLoggedIn && environmentHash) {
            const fetchEnvironmentSettings = async () => {
                try {
                    // Fetch the environment settings
                    const fetchedSettings = await fetchData({ apiUrl: `get_environment_settings/${environmentHash}` })

                    // Set the user preferences and language in the context
                    setEnvironmentSettings(fetchedSettings);

                    // Convert the environment settings to a string and store it encrypted in the local storage
                    const settingsString = JSON.stringify(fetchedSettings)
                    localStorage.setItem('env_settings', encryptData(settingsString))                  
                } catch (error: any) {
                    console.error(error);
                }
            }
            fetchEnvironmentSettings();
        }
    }, [isLoggedIn, environmentHash]);

    // Receive updates to user preferences by websocket
    useEffect(() => {
        const { type, message } = userPreferencesWebsocket || {};

        if (type === 'user_preferences_updated' ) {
            // Update the user preferences
            setUserPreferences(prev => ({ ...prev, ...message }));
            
            // Update the language
            if (message.language) {
                setLanguage(message.language)
            }

            // Update the first name
            if (message.first_name) {
                setFirstName(message.first_name)
                localStorage.setItem('first_name', encryptData(message.first_name))
            }

            // Update the last name
            if (message.last_name) {
                setLastName(message.last_name)
                localStorage.setItem('last_name', encryptData(message.last_name))
            }
        }
    }, [userPreferencesWebsocket]);

    // Receive updates to environment settings by websocket
    useEffect(() => {
        const { type, message } = environmentSettingsWebsocket || {};

        if (type === 'environment_settings_updated' ) {
            // Update the environment settings
            setEnvironmentSettings(prev => ({ ...prev, ...message }));

            // Convert the environment settings to a string and store it encrypted in the local storage
            const messageString = JSON.stringify(message)
            localStorage.setItem('env_settings', encryptData(messageString))
        }
    }, [environmentSettingsWebsocket]);

    // Update the default currency and symbol when user preferences are updated
    useEffect(() => {
        const currency = userPreferences.default_currency || 'eur';
        setDefaultCurrency(currency);

        const symbol = currency ? currencySymbols[currency.toLowerCase()] || '€' : '€';
        setDefaultCurrencySymbol(symbol);
    }, [userPreferences.default_currency]);

    // Set the language
    const setLanguage = (language: string) => {
        _setLanguage(language);
        i18n.changeLanguage(language)
    }

    // Get the time format of the user locale
    const getTimeFormat = (): '12h' | '24h' => {
        return localeOptions[userPreferences.user_locale].timeFormat;
    }

    return (
        <SettingsContext.Provider value={({ 
            userPreferences, 
            environmentSettings, 
            setLanguage, 
            userLocale: userPreferences.user_locale, 
            userTimezone: userPreferences.timezone, 
            getTimeFormat,
            defaultCurrency,
            defaultCurrencySymbol
        })}>
                {children}
        </SettingsContext.Provider>
    );
};

// Create a custom hook for the settings context
export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within the SettingsContextProvider');
    }
    return context;
};