/*
 * formValidation.tsx
 * General utility function that validates form fields. This function performs 
 * the associated validations based on the type of the field and returns the error 
 * messages associated with the field.
 * 
 * In case of SingleFields it is possible to perform the validation per field. 
 * In that case, the variables you use to call the function are (fieldItem, updatedData, 
 * and optionally the is12hourFormat prop in case of a time field).
 * 
 * In case of WidgetFields it is possible to perform the validation for all fields inside
 * the widget. In that case, the variable you use to call the function are a string of the
 * field type (for example 'phonenumber-widget' and the updatedData of that field).
 */

import i18n from "internationalization/i18n";
import { convertTo24HourFormat } from "./dateTimeUtils";

// Validation functions
const email = (value: string): string | null => {
    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegexp.test(value) ? null : "validation.email.valid_email_message";
};

const number = (value: string, min?: string, max?: string): string | null => {
    const onlyNumbersAndSeparators = /^[\d.,]+$/;
    const onlyNumbersAndOneOrNoSeparator = /^[0-9]*(.[0-9]*)?$/;
    const numberValue = value.replace(/\./g, '.');

    if (value === '') {
        return null;
    } else if (isNaN(Number(numberValue))) {
        return 'validation.number.invalid_input_message';
    } else if (!onlyNumbersAndOneOrNoSeparator.test(value)) {
        return 'validation.number.one_comma_message';
    } else if (!onlyNumbersAndSeparators.test(value)) {
        return 'validation.number.use_numbers_comma_message';
    } else {
        const floatValue = parseFloat(numberValue);
        if (min !== undefined && floatValue < parseFloat(min)) {
            return i18n.t('validation.number.value_greater_than_message', { value: min });
        } else if (max !== undefined && floatValue >= parseFloat(max)) {
            return i18n.t('validation.number.value_less_than_message', { value: max });
        } else {
            return null;
        }
    }
};

const date = (value: string, min?: string, max?: string): string | null => {
    if (!value) {
        return null;
    }

    // Check if the date input has three numeric parts
    const dateParts = value.split('-');
    if (dateParts.length !== 3 || dateParts.some(part => !/^\d+$/.test(part))) {
        return 'validation.date.valid_format_message';
    }

    // Validate the entered date in the field
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[0], 10);
    const dateValue = new Date(year, month, day);
    if (isNaN(dateValue.getTime())) {
        return 'validation.date.valid_date_message';
    }

    // Year length validation
    if (![2, 4].includes(year.toString().length)) {
        return 'validation.date.valid_date_message';
    }

    // Check if the date is before the minimum date
    if (min) {
        const minDate = new Date(min);
        if (dateValue < minDate) {
            return i18n.t('validation.date.date_after_message', { date: minDate.toLocaleDateString() });
        }
    }

    // Check if the date is after the maximum date
    if (max) {
        const maxDate = new Date(max);
        if (dateValue > maxDate) {
            return i18n.t('validation.date.date_before_message', { date: maxDate.toLocaleDateString() });
        }
    }

    return null;
};

const time = (value: string, min?: string, max?: string, is12HourFormat?: boolean): string | null => {
    if (!value) {
        return null;
    }

    const ampmFormat = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
    const twentyFourHourFormat = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    // Validate the entered time in the 12-hour timeformat field
    if (is12HourFormat && !ampmFormat.test(value)) {
        return "validation.time.valid_time_12h_message";

    // Validate the entered time in the 24-hour timeformat field
    } else if (!is12HourFormat && !twentyFourHourFormat.test(value)) {
        return "validation.time.valid_time_24h_message";
    }

    const convertedValue = convertTo24HourFormat(value, is12HourFormat);

    // Check if the time is before the minimum time
    if (min) {
        const convertedMin = convertTo24HourFormat(min, is12HourFormat);
        if (convertedValue < convertedMin) {
            return i18n.t('validation.time.time_after_message', { time: min });
        }
    }

    // Check if the time is after the maximum time
    if (max) {
        const convertedMax = convertTo24HourFormat(max, is12HourFormat);
        if (convertedValue > convertedMax) {
            return i18n.t('validation.time.time_before_message', { time: max });
        }
    }
    
    return null;
};

const phonenumber = (value: string): string | null => {
    // Accepts the following digits: + ( ) -, only + at the beginning
    const generalStructureRegexp = /^\+?(\d|[-()\s])+$/;
    const digitCount = (value.match(/\d/g) || []).length;

    // If the value is empty, just return
    if (value === '') {
        return null;

    // Check if the phone number only contains the accepted digits
    } else if (!generalStructureRegexp.test(value)) {
        return "validation.phone.valid_phone_message";

    // Check if the phone number has the accepted length
    } else if (digitCount < 6 || digitCount > 15) {
        return "validation.phone.contain_message";
    }

    return null;
};

// Maps the validation functions
const validationFunctions: { [key: string]: (value: any, min?: string, max?: string, is12HourFormat?: boolean) => string | null } = {
    email,
    number,
    date,
    time,
    phonenumber
}

// In case of different field types, map it to the validation function 
function mapDifferentFieldTypes(fieldType: string): string {
    const fieldTypeToValidationType: { [key: string]: string } = {
        'phonenumber-widget': 'phonenumber',
        'email-widget': 'email',
    };

    return fieldTypeToValidationType[fieldType] || fieldType;
}

// Executes the form validation for the field type
export const formValidation = (
    fieldItemOrType: any | string, 
    updatedData: any,
    is12HourFormat?: boolean
): Record<string, string> | null => {
    let name, type, min, max;

    // If the given fieldItemOrType is a string with the type of the field (for example: 'phonenumber-widget')
    if (typeof fieldItemOrType === 'string') {
        type = fieldItemOrType;
        name = Object.keys(updatedData)[0];
    // If the given fieldItemOrType is a fieldItem
    } else {
        ({ name, type, min, max } = fieldItemOrType);
    }

    const value = updatedData[name];
    const mappedType = mapDifferentFieldTypes(type);

    const validationFunction = validationFunctions[mappedType];
    if (validationFunction) {
        const validationError = validationFunction(value, min, max, is12HourFormat);
        if (validationError) {
            return { [name]: validationError };
        }
    }
    return null;
};