import axios from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from './csrfFunctions';

interface CheckSessionCallbacks {
    setIsLoggedIn: (loggedIn: boolean | null) => void;
}

// Checks if the current session is active in the backend
export const checkSession = async ({ callbacks }: {callbacks: CheckSessionCallbacks }, handleLogout: () => void, setSessionExpiredFormAlert: () => void): Promise<void> => {
    const csrfToken = getCsrfToken();

    try {
        const response = await axios.get(`${apiBaseUrl}/check_session/`, {
            withCredentials: true,
            headers: { 
                'X-CSRFToken': csrfToken
            } 
        })

        if (response.status === 200) {
            // If current session is active
            callbacks.setIsLoggedIn(true);
        } else {
            // Show session expired alert on top of login form to inform the user
            setSessionExpiredFormAlert();

            // Log out if current session is not active (user or environment is not active, or user role hasn't access to the office)
            handleLogout();
        }
    } catch (error: any) {
        // In case of an error, handle the logout
        handleLogout();
    }
}